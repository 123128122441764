import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { IconNotFound } from "atoms/IconNotFound/IconNotFound";
import { Typography } from "@mui/material";
import { Button } from "components/Button";
import { IconContainer } from "atoms/IconContainer";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      component="main"
      container
      justifyContent="center"
      fontFamily="Geologica"
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Grid
        item
        md={8}
        xs={4}
        maxWidth={{ sm: 404 }}
        boxSizing="content-box"
        position="relative"
        pt={{ md: 250, xs: 220 }}
        pb={120}
      >
        <IconContainer
          position="absolute"
          top={{ md: 115, xs: 85 }}
          width="100%"
          zIndex={-1}
          left="50%"
          sx={{
            transform: "translate(-50%)",
          }}
          className={undefined}
        >
          <IconNotFound />
        </IconContainer>
        <Box zIndex={2}>
          <Box mb={12}>
            <Typography variant="text_404" color="black" fontWeight={700}>
              404
            </Typography>
          </Box>
          <Box mb={44}>
            <Typography variant="text_5" color="black">
              Похоже вы нашли страницу которой не существует, вы большой
              молодец!
            </Typography>
          </Box>
          <Button
            variant="primary"
            title="Перейти на главную"
            onClick={() => navigate("/")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
