import { TabVariants, systemActions } from "store/slices/system";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  HISTORY_TABS,
  getHistoryChartData,
  getHistoryListLight,
  setActiveTab,
  setSelectedDay,
} from "store/slices/historySlice";
import { useIsMobile } from "hooks/useIsMobile";
import { EmptyStyled } from "../History/History";
import { Box, Stack } from "@mui/material";
import { OperationItem } from "../History/components/OperationItem";
import { createLimitedList } from "utils/createLimitedList";
import {
  DateStyled,
  Title,
  TransferHistoryBlock,
  TransferHistoryBtn,
} from "./components";
import { parseTransactionsDate } from "utils/parseTransactionsDate";
import { HistoryModal } from "../History/HistoryModal";
import theme from "theme";
import { HistoryChartHeader } from "molecules/HistoryChartHeader/HistoryChartHeader";
import { ReactComponent as PieChartICon } from "molecules/HistoryChartTabs/assets/pie-chart.svg";
import moment from "moment";
import { HistoryChart } from "molecules/HistoryChart/HistoryChart";
import {
  generatePlaceholderData,
  getChartBarHeight,
  getChartBarWidth,
} from "molecules/HistoryChart/HistoryChart.utils";
import { useDispatch, useSelector } from "store";
import { IconEmptyBlock } from "atoms/IconEmptyBlock";
import { SkeletonContainer } from "components/SkeletonContainer/SkeletonContainer";
import { Button } from "components/Button";

export const LatestTransactions = () => {
  const dispatch = useDispatch();
  const {
    history: {
      historyList,
      isLoading,
      historyChartData,
      isHistoryChartDataLoading,
      selectedDay,
    },
    cards,
  } = useSelector((state) => state);

  const { isMobile, size } = useIsMobile();

  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });

  const chartContainerRef = useRef(null);

  const notFoundOperations = !isLoading && !historyList.length;

  const from = useMemo(() => {
    const baseDate = isMobile
      ? moment().add(1, "day").subtract(2, "week")
      : moment().add(1, "day").subtract(28, "days");

    return baseDate.startOf("day").local().format();
  }, [isMobile]);
  const to = moment(new Date()).endOf("day").local().format();

  const goToHandler = (tab: TabVariants) => {
    if (tab === TabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  const List = createLimitedList(historyList);

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const onSelect = useCallback(
    (date: string | null) => {
      dispatch(setSelectedDay(date));
    },
    [dispatch]
  );

  useEffect(() => {
    if (cards.account.accountNumber) {
      dispatch(
        getHistoryListLight({
          size: 5,
          from: selectedDay
            ? moment(selectedDay).startOf("day").local().format()
            : from,

          to: selectedDay
            ? moment(selectedDay).endOf("day").local().format()
            : to,

          page: 0,
          acctId: cards.account.accountNumber,
        })
      );
    }
  }, [cards.account.accountNumber, dispatch, from, to, selectedDay]);

  useEffect(() => {
    if (cards.account.accountNumber) {
      dispatch(
        getHistoryChartData({
          from,
          to,
          acctId: cards.account.accountNumber,
        })
      );
    }
  }, [cards.account.accountNumber, dispatch, from, to]);

  const historyChartStatements =
    !isHistoryChartDataLoading && historyChartData.statements?.length
      ? historyChartData.statements
      : generatePlaceholderData(from, to);

  return (
    <>
      <Title>Последние операции</Title>

      <Box
        padding={{
          xs: "12px 16px 8px 16px",
          md: "16px 20px 10px 20px",
        }}
        mb={{
          md: 16,
          xs: 8,
        }}
        borderRadius={{
          md: 16,
          xs: 19,
        }}
        sx={{
          backgroundColor: theme.primary.gray?.[200],

          "& .detail-icon": {
            path: {
              fill: "#739B67",
            },
          },
        }}
        ref={chartContainerRef}
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mb={{
            xs: 12,
            md: 16,
          }}
        >
          <HistoryChartHeader
            currencySign={historyChartData.currency?.sign}
            incomeSum={historyChartData.statistic?.incomeSum || 0}
            expenseSum={historyChartData.statistic?.expenseSum || 0}
            isLoading={isHistoryChartDataLoading}
          />

          {size !== "xs" && (
            <Button
              style={{
                paddingLeft: 0,
                paddingRight: 0,
              }}
              startIcon={
                isMobile ? <PieChartICon className="detail-icon" /> : null
              }
              variant="text"
              title={isMobile ? "" : "подробнее"}
              onClick={() => goToHandler(TabVariants.history)}
            />
          )}
        </Stack>

        <HistoryChart
          selectedDate={selectedDay || ""}
          onSelect={onSelect}
          currencySign={historyChartData.currency?.sign}
          isMobile={isMobile}
          isLoading={isHistoryChartDataLoading}
          data={historyChartStatements}
          barWidth={getChartBarWidth(isMobile)}
          barHeight={getChartBarHeight(isMobile, true)}
          containerRef={chartContainerRef}
          incomeSum={historyChartData.statistic?.incomeSum || 0}
          expenseSum={historyChartData.statistic?.expenseSum || 0}
        />
      </Box>

      <SkeletonContainer isLoading={isLoading} height={345} width="100%">
        <TransferHistoryBlock>
          {List.map((item) => {
            return (
              <Box key={item.date} mb={8}>
                <DateStyled>
                  {parseTransactionsDate(item.date || "")}
                </DateStyled>
                <Box display="flex" flexDirection="column">
                  {/*@ts-ignore*/}
                  {item.statements.map((el) => (
                    <OperationItem
                      key={el.statementId}
                      item={el}
                      onOpenModal={openModal}
                    />
                  ))}
                </Box>
              </Box>
            );
          })}

          {notFoundOperations && (
            <EmptyStyled>
              <IconEmptyBlock />
              <h2>
                У вас пока не было операций <br /> по карте
              </h2>
            </EmptyStyled>
          )}

          {!notFoundOperations && (
            <TransferHistoryBtn
              onClick={() => goToHandler(TabVariants.history)}
            >
              Смотреть все
            </TransferHistoryBtn>
          )}
        </TransferHistoryBlock>
      </SkeletonContainer>

      {modalStatus.isOpen && (
        <HistoryModal
          isOpen={modalStatus.isOpen}
          onClose={closeModal}
          historyId={modalStatus.historyId}
        />
      )}
    </>
  );
};
