import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CardDto } from "api/account";

import {
  IconCreditBlocked,
  IconCreditCardClosed,
  IconCreditCardFrozen,
} from "atoms/IconCards/IconCards";
import { SkyIcon } from "atoms/Sky/Sky";
import { CARD_STATUS } from "components/CardsInfo/model";

interface ICardProps {
  card: CardDto;
}

export const Card = ({ card }: ICardProps) => {
  const cardStatus = card.status;
  const shortCardPan = card.cardPan?.slice(-4);
  const isVirtualCard = card?.cardType === "VIRTUAL";

  const cardBg = () => {
    switch (cardStatus) {
      case CARD_STATUS.Temporary:
      case CARD_STATUS.Referral:
        return "#90C7F1";
      case CARD_STATUS.Lost:
      case CARD_STATUS.Stolen:
      case CARD_STATUS.Compromised:
        return "#E39090";
      case CARD_STATUS.Closed:
        return "#B8BBBF";
      default:
        if (isVirtualCard) {
          return "#89B8A2";
        } else {
          return "#739B67";
        }
    }
  };

  const renderIcon = () => {
    switch (cardStatus) {
      case CARD_STATUS.Temporary:
      case CARD_STATUS.Referral:
        return <IconCreditCardFrozen color="none" size={14} />;
      case CARD_STATUS.Lost:
      case CARD_STATUS.Stolen:
      case CARD_STATUS.Compromised:
        return <IconCreditBlocked color="white" width="8.5px" height="10px" />;
      case CARD_STATUS.Closed:
        return <IconCreditCardClosed size={12} />;
      default:
        if (isVirtualCard) {
          return <SkyIcon color=" #E8F1FF" height={11} width={15} />;
        } else {
          return null;
        }
    }
  };

  return (
    <Box
      sx={{
        width: "72px",
        height: "44px",
        borderRadius: "8px",
        padding: "4px 5px 6px",
        backgroundColor: cardBg(),
      }}
    >
      <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
        <Typography
          variant="text_3"
          sx={{
            color: "#FFFFFF",
            position: "absolute",
            bottom: 0,
            left: 0,

            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          ··{shortCardPan}
        </Typography>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
          }}
        >
          {renderIcon()}
        </Box>
      </Box>
    </Box>
  );
};
