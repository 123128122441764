export enum ProfileModalType {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  PASSWORD = "PASSWORD",
  PINCODE = "PINCODE",
  BIOMETRY = "BIOMETRY",
  NOTIFICATION = "NOTIFICATION",
  SHOWCARDS = "SHOWCARDS",
  THIS_DEVICE_NOTIFICATIONS = "THIS_DEVICE_NOTIFICATIONS",
  ALL_NOTIFICATIONS = "ALL_NOTIFICATIONS",
}

export enum NOTIFICATION_TABS {
  NOTIFICATIONS = "Уведомления",
  NEWS = "Новости",
}
