import Box from "@mui/material/Box";
import theme from "theme";
import NumberFormat from "react-number-format";
import DisabledCard from "./DisabledCard.png";
import ActiveCard from "./ActiveCard.png";
import { Label } from "molecules/Label/Label";
import { forwardRef, useState, useRef, useEffect } from "react";
import { CustomKeyboard } from "components/CustomKeyboard/CustomKeyboard";
import { BlinkingCursor } from "components/BlinkingCursor/BlinkingCursor";
import { InputAdornment, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIsMobile } from "hooks/useIsMobile";
import { useTextWidth } from "hooks/useTextWidth";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .MuiInputBase-root input": {
      fontSize: "17px",
      fontWeight: 500,
      "&::placeholder": {
        opacity: 1,
        color: "var(--main-color-notification-description)",
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
      boxSizing: "border-box",
      height: (isMobile) => (isMobile ? "56px" : "64px"),

      "& fieldset": {
        border: "none",
      },

      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.palette.indigo.b300}`,
      },

      "@media (max-width: 600px)": {
        height: "56px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "22px 16px",
      fontSize: "17px",
      lineHeight: "20px",
      fontWeight: 500,
      background: "var(--main-color-bg-widgets)",
      color: "var(--main-color-text-title)",
      borderRadius: "16px",

      boxSizing: "border-box",
      height: "64px",

      "@media (max-width: 600px)": {
        height: "56px",
        padding: "18px 16px",
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      background: "var(--brand-color-disabled)",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "var(--error-color-primary)",
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid var(--error-color-illustration) !Important",
    },
  },
  labelRoot: {
    "& .makeStyles-labelRoot-21.MuiInputLabel-root ": {
      marginBottom: 0,
    },
  },
}));

interface CardFormatInputProps {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  [key: string]: any;
}

const CardFormatInput = forwardRef<HTMLInputElement, CardFormatInputProps>(
  function CardFormatInput(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        format="#### #### #### #### ###"
        getInputRef={ref}
        allowEmptyFormatting={false}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
      />
    );
  }
);

const formatText = (text) => {
  // Разбиваем строку на группы по 4 символа и добавляем пробелы
  return text.replace(/(.{4})(?=.)/g, "$1 ");
};

const OFFSET = 15;

const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

export const CardInput = ({
  name,
  label,
  sublabel = "",
  labelColor = theme.palette.gray.b600,
  subLabelColor = "var(--main-color-text-secondary)",
  value = "",
  onChange,
  placeholder = "Введите номер карты",
  icon = false,
  error = false,
  errorMessage = "",
  mb = 8,
  transfer = false,
  showCustomKeyboard = false,
  disabled = false,
  cursorFont = "16px Geologica",
  isFirstScreen = false,
  toggleCustomKeyboard = (show) => {},
}) => {
  const { isMobile } = useIsMobile();
  const classes = useStyles(isMobile);
  const [inputValue, setInputValue] = useState(value);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const textWidth = useTextWidth(formatText(inputValue), cursorFont);

  const inputRef = useRef(null);
  const cursorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cursorRef.current && inputRef.current) {
      cursorRef.current.style.left = `${textWidth + OFFSET}px`;
    }
  }, [textWidth]);

  const handleKeyPress = (key) => {
    if (key === "Backspace") {
      setInputValue((prev) => prev.slice(0, -1));
    } else {
      setInputValue((prev) => {
        if (inputValue.length > 18) return prev;
        return prev + key;
      });
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleCloseKeyboard = () => {
    setIsFocus(false);
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  const enableCustomKeyboard = showCustomKeyboard && isMobile && !isIOS;
  const keyboardCanDisplay = showKeyboard && enableCustomKeyboard;

  useEffect(() => {
    toggleCustomKeyboard?.(keyboardCanDisplay);
  }, [keyboardCanDisplay, toggleCustomKeyboard]);

  useEffect(() => {
    if (!enableCustomKeyboard) return;
    setShowKeyboard(isFocus);
  }, [isFocus, enableCustomKeyboard]);

  return (
    <Box mb={mb}>
      <Label
        label={label}
        htmlFor={name}
        style={{
          marginBottom: 0,
          color: labelColor,
          fontWeight: transfer ? 400 : 500,
          fontSize: "16px",
        }}
      />
      <Label
        label={sublabel}
        htmlFor={name}
        style={{
          marginBottom: 8,
          color: subLabelColor,
          fontSize: "14px",
          fontWeight: transfer ? 400 : 500,
        }}
      />
      <Box sx={{ position: "relative" }}>
        <TextField
          classes={{ root: classes.inputRoot }}
          name={name}
          onChange={keyboardCanDisplay ? handleInputChange : onChange}
          value={keyboardCanDisplay ? inputValue : value}
          onClick={() => {
            setShowKeyboard(true);
            setIsFocus(true);
          }}
          onBlur={
            enableCustomKeyboard
              ? undefined
              : () => {
                  setIsFocus(false);
                }
          }
          onFocus={handleFocus}
          placeholder={placeholder}
          error={!!error}
          autoComplete="off"
          sx={{
            "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > p": {
              color: theme.palette.gray.b400,
            },
          }}
          //TODO: инпут обновляет состояние, изменяя isFocus. Есть варианты это обойти?
          focused={isFocus}
          disabled={disabled}
          InputProps={{
            type: isIOS ? "text" : "tel",
            autoComplete: "off",
            inputMode: "numeric",
            readOnly: keyboardCanDisplay,
            inputComponent: CardFormatInput,
            startAdornment: icon && (
              <InputAdornment position="start">
                {value ? (
                  <Box component="img" src={ActiveCard} />
                ) : (
                  <Box component="img" src={DisabledCard} />
                )}
              </InputAdornment>
            ),
          }}
          id={name}
          inputRef={inputRef}
          fullWidth
          helperText={errorMessage}
        />
        <Box
          ref={cursorRef}
          sx={{
            position: "absolute",
            display: "inline-block",
            top: "50%",
            transform:
              error && !isFirstScreen ? "translateY(-95%)" : "translateY(-40%)",
            visibility: inputValue && keyboardCanDisplay ? undefined : "hidden",
          }}
        >
          <BlinkingCursor />
        </Box>
      </Box>

      {keyboardCanDisplay && (
        <CustomKeyboard
          onKeyPress={handleKeyPress}
          onClose={handleCloseKeyboard}
        />
      )}
    </Box>
  );
};
