import { ReactElement, useState } from "react";

import { useOutsideClick } from "hooks/useOutsideClick";
import {
  ActionContainer,
  ActionTitle,
  ItemCardActionsBlock,
  ItemCardBody,
  ItemCardContainer,
  ItemCardIconWrapper,
  ItemCardNumber,
  ItemCardTitle,
} from "./components";

import { ReactComponent as RemoveIcon } from "./assets/remove.svg";

import { RadioButton } from "components/RadioButton/RadioButton";
import { IconDots } from "atoms/IconDots/IconDots";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";

interface ItemCardProps {
  title?: string;
  cardNumber?: string;
  checked: boolean;
  onChange: () => void;
  onDelete?: () => void;
  background?: string;
  icon: ReactElement;
  hideMenu?: boolean;
  fullWidth?: boolean;
}

export const ItemCard = ({
  title,
  checked,
  cardNumber,
  onChange,
  onDelete,
  background,
  icon,
  hideMenu,
  fullWidth = true,
}: ItemCardProps) => {
  const [showActions, setShowActions] = useState(false);

  const actions = [
    {
      icon: <RemoveIcon />,
      title: "Удалить карту",
      color: "#E64545",
      action: () => {
        if (onDelete) onDelete();
        setShowActions(false);
      },
    },
  ];

  const handleClickOutside = () => {
    setShowActions(false);
  };

  const rootElement = useOutsideClick(handleClickOutside, { useCapture: true });

  return (
    <ItemCardContainer fullWidth={fullWidth}>
      <Box
        sx={{ display: "flex", alignItems: "center", width: "100%", gap: 8 }}
      >
        {!hideMenu && (
          <Box
            onClick={() => setShowActions((prevState) => !prevState)}
            sx={{
              display: "flex",
              cursor: "pointer",
            }}
          >
            <IconDots />
          </Box>
        )}

        <ItemCardBody
          background={showActions ? "#E8ECE3" : "var(--main-color-bg-widgets)"}
        >
          <Stack direction="row" alignItems="center" spacing={16}>
            <ItemCardIconWrapper background={background || ""}>
              {icon}
            </ItemCardIconWrapper>

            <ItemCardTitle>
              {title}
              {cardNumber && <ItemCardNumber> • {cardNumber}</ItemCardNumber>}
            </ItemCardTitle>
          </Stack>

          <RadioButton changed={onChange} isSelected={checked} />
        </ItemCardBody>
      </Box>

      {showActions && (
        <ItemCardActionsBlock>
          {actions.map((action) => {
            return (
              <ActionContainer
                onClick={action.action}
                key={action.title}
                ref={rootElement}
              >
                {action.icon}
                <ActionTitle style={{ color: action.color }}>
                  {action.title}
                </ActionTitle>
              </ActionContainer>
            );
          })}
        </ItemCardActionsBlock>
      )}
    </ItemCardContainer>
  );
};
