import { Middleware } from "@reduxjs/toolkit";
import { RootState, ThunkAppDispatch } from "../../index";
import { showErrorMessage, hideErrorMessage } from "../alerts";
import { IFourHundredErr } from "api/types";
import { TRANSFER_ERROR_CODE } from "../transferSlice";
import { authActions } from "./../auth";
import { redirectAction } from "./../redirect";
import { LOGIN_ERROR_CODE, REGISTER_ERROR_CODE } from "../auth/slice";

const defaultErrorTitle = "Произошла ошибка при выполнении запроса";
const defaultErrorMessage = "Попробуйте позднее";

const shouldProcessAction = (action) => {
  const rejectedActionsToIgnore = [
    "checkCvv/rejected",
    "unblockedCard/rejected",
    "blockedCard/rejected",
    "verifyPinCode/rejected",
    "updatePinCode/rejected",
    "sendChatMessageV2/rejected",
    "checkCurrentPassword/rejected",
    "getChatMessagesV2/rejected",
    "updatePassword/rejected",
    "updateLogin/rejected",
    "confirmResetPassword/rejected",
    "confirmTransferToCard/rejected",
    "getRefillStatus/rejected",
    "createTransferToCard/rejected",
    "createLogin/rejected",
    "registerConfirm/rejected",
    "login/rejected",
    "registerCVV/rejected",
    "updateCardLimitConfirm/rejected",
    "registerCheck/rejected",
    "checkAvailableVirtualCard/rejected",
    "createVirtualCard/rejected",
    "updateLimits/rejected",
    "getLimits/rejected",
    "createRefill/rejected",
    "retryOneTimePassword/rejected",
    "transferCheck/rejected",
  ];

  const isTransferError =
    action.type === "confirmTransferToCard/rejected" &&
    [
      TRANSFER_ERROR_CODE.PAYMENT_ERROR,
      TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR,
    ].includes(action.payload.response.data.code);

  const isCardDisabledError =
    ["register/rejected", "resetPassword/rejected"].includes(action.type) &&
    (() => {
      const code = action?.payload?.response?.data?.code;
      return [
        REGISTER_ERROR_CODE.CARD_DISABLED,
        REGISTER_ERROR_CODE.WRONG_REQUEST_BIRTHDAY,
        REGISTER_ERROR_CODE.WRONG_REQUEST_CARD,
        REGISTER_ERROR_CODE.WRONG_REQUEST_PHONE,
        REGISTER_ERROR_CODE.REGISTRATION_LOCKED_BY_ATTEMPTS,
        REGISTER_ERROR_CODE.OTP_BLOCKED,
      ].includes(code);
    })();

  const isLoginError =
    action.type === "login/rejected" &&
    [
      LOGIN_ERROR_CODE.OTP_BLOCKED,
      LOGIN_ERROR_CODE.ACCOUNT_LOCKED_BY_AUTH_ATTEMPTS,
    ].includes(action.payload.response.data.code);

  const isBlockError =
    action?.payload?.response?.data?.code === LOGIN_ERROR_CODE.CLIENT_BLOCKED;

  return (
    action.type.includes("rejected") &&
    !rejectedActionsToIgnore.includes(action.type) &&
    action.payload.response.data.code !== "WRONG_DEVICE" &&
    !isTransferError &&
    !isCardDisabledError &&
    !isLoginError &&
    !isBlockError
  );
};

export const handleErrorMiddleWare: Middleware<
  RootState,
  unknown,
  ThunkAppDispatch
> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (shouldProcessAction(action)) {
      const { response } = action.payload as IFourHundredErr;

      if (
        response.status >= 400 &&
        response.status < 500 &&
        response.status !== 406
      ) {
        if (
          response.status === 401 &&
          !response?.request?.responseURL.includes("web/user/pin") &&
          !response?.request?.responseURL.includes("auth/v3/user")
        ) {
          dispatch(hideErrorMessage());
          dispatch(
            showErrorMessage({
              errorTitle: "Срок действия сессии истек",
            })
          );
        } else {
          dispatch(
            showErrorMessage({
              errorTitle: response?.data?.title,
              errorMessage: response?.data?.subtitle,
            })
          );
        }
      } else {
        dispatch(
          showErrorMessage({
            errorTitle: defaultErrorTitle,
            errorMessage: defaultErrorMessage,
          })
        );
      }
    } else if (
      action.type.includes("fulfilled") &&
      !action.type.includes("hideErrorMessage") &&
      !action.type.includes("showError")
    ) {
      dispatch(hideErrorMessage());
    }

    if (
      action.payload?.response?.data?.code === LOGIN_ERROR_CODE.CLIENT_BLOCKED
    ) {
      sessionStorage.removeItem("isAuthenticated");
      sessionStorage.removeItem("accessToken");
      dispatch(authActions.setLogout());
      dispatch(authActions.setAccountIsBlocked(true));
      dispatch(redirectAction.setRedirectUrl({ redirectUrl: "sign-in" }));
    }

    return next(action);
  };
