import theme from "theme";

export const IconArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} fill="none">
    <rect width={40} height={40} fill={theme.palette.blue.b400} rx={14} />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m13.5 16.5 6.5 7 6.5-7"
    />
  </svg>
);
