import { News } from "organisms/Notifications/News";
import { Notifications } from "organisms/Notifications/Notifications";
import { useCallback, useState } from "react";
import { Box } from "@mui/material";
import {
  Container,
  Tab,
  TabText,
  TabWrapper,
} from "pages/home/Cards/History/components/HistoryTabs/components";
import { useDispatch, useSelector } from "store";
import { NOTIFICATION_TABS } from "pages/profile/models";
import { profileActions } from "store/slices/profile";

const TABS = Object.values(NOTIFICATION_TABS);

export const NotificationNewsModal = () => {
  const dispatch = useDispatch();
  const { newNews } = useSelector((state) => state.notifications);
  const { notificationActiveTab: activeTab } = useSelector(
    (state) => state.profile
  );

  const renderContent = useCallback(() => {
    switch (activeTab) {
      case NOTIFICATION_TABS.NOTIFICATIONS:
        return <Notifications />;
      case NOTIFICATION_TABS.NEWS:
        return <News />;
      default:
        return null;
    }
  }, [activeTab]);

  return (
    <Box display="flex" flexDirection="column" my={12}>
      <Container marginTop={false}>
        <Box px={{ xs: "16px", lg: "32px" }} width="100%">
          <TabWrapper
            style={{
              justifyContent: "space-between",
              gap: "4px",
              flex: "1 auto",
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab}
                active={activeTab === tab}
                onClick={() => {
                  if (activeTab === tab) return;
                  dispatch(profileActions.setNotificationActiveTab(tab));
                }}
                width="100%"
              >
                <TabText active={activeTab === tab}>{tab}</TabText>
                {newNews && tab === NOTIFICATION_TABS.NEWS && (
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    bgcolor="#DEB16E"
                    ml="5px"
                    mt="-11px"
                  />
                )}
              </Tab>
            ))}
          </TabWrapper>
        </Box>
      </Container>
      {renderContent()}
    </Box>
  );
};
