import { StatementCategoryFull } from "api/account";

export const DISABLED_COLOR = "#D3D3D3";

export const HEX_TO_FILTER_CHART_COLORS = {
  "#DEB16E":
    "invert(79%) sepia(15%) saturate(1167%) hue-rotate(351deg) brightness(93%) contrast(86%)",
  "#739B67":
    "invert(55%) sepia(23%) saturate(528%) hue-rotate(61deg) brightness(98%) contrast(92%)",
  "#ACA1C3":
    "invert(70%) sepia(5%) saturate(1250%) hue-rotate(218deg) brightness(96%) contrast(86%)",
  "#E6E279":
    "invert(97%) sepia(90%) saturate(500%) hue-rotate(353deg) brightness(85%) contrast(90%)",
  "#9DB6DB":
    "invert(81%) sepia(3%) saturate(4528%) hue-rotate(186deg) brightness(93%) contrast(83%)",
  "#D6A4A4":
    "invert(99%) sepia(25%) saturate(2000%) hue-rotate(292deg) brightness(85%) contrast(96%)",
  "#A6C315":
    "invert(64%) sepia(63%) saturate(586%) hue-rotate(28deg) brightness(101%) contrast(86%)",
  "#8BB6A9":
    "invert(50%) sepia(16%) saturate(873%) hue-rotate(111deg) brightness(97%) contrast(91%)",
  "#508F7C":
    "invert(80%) sepia(5%) saturate(1240%) hue-rotate(111deg) brightness(86%) contrast(90%)",
};

export const WHITE_FILTER_COLOR =
  "brightness(0) saturate(100%) invert(100%) sepia(49%) saturate(0%) hue-rotate(214deg) brightness(112%) contrast(100%)";

export const INNER_RADIUS_RATIO = 1.42;
export const MIN_VALUE_TO_SHOW_ICON = 8;

export const PLACEHOLDER_DATA: StatementCategoryFull[] = [
  {
    color: DISABLED_COLOR,
    percent: 100,
  },
];
