import styled from "@emotion/styled/macro";

export const TransferBtnContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  column-gap: 24px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 32px;

  @media (max-width: 1440px) {
    column-gap: 16px;
  }

  @media (max-width: 980px) {
    column-gap: 16px;
    row-gap: 16px;
  }

  @media (max-width: 788px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 588px) {
    grid-template-columns: 1fr;
  }
`;

export const TransferBtn = styled.div`
  box-sizing: border-box;

  aspect-ratio: 260/180;
  grid-column: span 1;
  border-radius: 18px;
  background: var(--main-color-bg-widgets);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;

  position: relative;

  svg {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  @media (max-width: 980px) {
    aspect-ratio: 250/180;
  }

  @media (max-width: 588px) {
    padding: 30px 0;
    aspect-ratio: auto;
    border-radius: 16px;
  }
`;

export const IconWrapper = styled.div`
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 8px;

  position: absolute;
  top: 8px;
  right: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardImg = styled.img`
  width: 88px;
  @media (max-width: 1440px) {
    width: 48px;
  }
  @media (max-width: 979px) {
    width: 88px;
  }
`;

export const TransferBtnText = styled.p`
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--main-color-text-title);
  text-align: center;

  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 980px) {
    font-size: 17px;
  }
`;
export const TransferSubText = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--main-color-notification-description);
  text-align: center;
`;
