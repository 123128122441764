import React from "react";
import theme from "theme";
import { Stack, Typography } from "@mui/material";
import { HistoryChartHeaderProps } from "./HistoryChartHeader.types";
import { getExpenseSum, getIncomeSum } from "./HistoryChartHeader.utils";
import { SkeletonContainer } from "components/SkeletonContainer/SkeletonContainer";

export const HistoryChartHeader: React.FC<HistoryChartHeaderProps> = ({
  isLoading,
  chartType,
  currencySign,
  marginBottom,
  expenseSum = 0,
  incomeSum = 0,
}) => (
  <SkeletonContainer
    isLoading={isLoading}
    height={32 + (parseInt(String(marginBottom)) || 0)}
    width="100%"
  >
    <Stack
      direction="row"
      sx={{
        marginBottom,

        "& .MuiTypography-h5": {
          [theme.breakpoints.down("md")]: {
            fontSize: 18,
          },
        },
      }}
    >
      {chartType !== "income" && (
        <Typography
          color="gray.b900"
          variant="h5"
          fontWeight={500}
          className="history-chart-header__expenseSum"
        >
          {getExpenseSum(expenseSum, currencySign)}
        </Typography>
      )}

      {!chartType && (
        <Typography
          color="gray.b900"
          variant="h5"
          fontWeight={500}
          sx={{
            margin: "0 5px",
          }}
        >{` / `}</Typography>
      )}

      {chartType !== "expense" && (
        <Typography
          color="#739B67"
          variant="h5"
          fontWeight={500}
          className="history-chart-header__incomeSum"
        >
          {getIncomeSum(incomeSum, currencySign)}
        </Typography>
      )}
    </Stack>
  </SkeletonContainer>
);
