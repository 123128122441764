import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CARD_STATUS } from "components/CardsInfo/model";
import { CardDto } from "api/account";
import { getCardIcon } from "utils/card";
import { IconCheckMark } from "atoms/IconCheckMark";

interface CardItemComponentProps {
  card: CardDto;
  onClick: (card: CardDto) => void;
  mainCard: CardDto;
}

export const CardSelectItem: React.FC<CardItemComponentProps> = ({
  card,
  mainCard,
  onClick,
}) => {
  const cardIsClosed = card.status === CARD_STATUS.Closed;
  const cardIsBlocked = CARD_STATUS.Blocked.includes(card.status as string);
  const cardIsFrozen =
    card.status === CARD_STATUS.Temporary ||
    card.status === CARD_STATUS.Referral;

  const isBlocked = cardIsClosed || cardIsBlocked || cardIsFrozen;

  return (
    <Box
      py={4}
      sx={{
        cursor: isBlocked ? "not-allowed" : "pointer",
        backgroundColor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "48px",
        padding: "0 16px",
        opacity: isBlocked ? 0.5 : 1,
        ":hover": {
          backgroundColor: "var(--brand-color-hover)",
        },
      }}
      onClick={() => {
        if (!isBlocked) {
          onClick(card);
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "24px",
            }}
          >
            {getCardIcon(card)}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "4px",
            }}
          >
            <Typography
              noWrap
              sx={{
                color: "var(--main-color-text-title)",
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              {card.paymentNetwork
                ? card.paymentNetwork === "MIR"
                  ? "МИР"
                  : card.paymentNetwork
                : "МИР"}
            </Typography>
            <Box
              sx={{
                width: "5px",
                height: "5px",
                borderRadius: "100px",
                backgroundColor: "var(--main-color-text-secondary)",
              }}
            />
            <Typography
              noWrap
              sx={{
                color: "var(--main-color-text-secondary)",
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              {card?.cardPan?.slice(-4)}
            </Typography>
          </Box>
        </Box>
        {card?.cardPan === mainCard?.cardPan && <IconCheckMark />}
      </Box>
    </Box>
  );
};
