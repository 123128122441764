import { TabVariants, systemActions } from "store/slices/system";
import ReplenishImg from "images/replenish.png";
import TransferImg from "images/transfer.png";
import { IconArrow } from "atoms/IconArrowTransfer";
import { AnotherPages } from "store/slices/system/slice";
import { LatestTransactions } from "./LatestTransactions";
import { SHOW_REFILL } from "utils/constants";
import {
  CardImg,
  Container,
  IconWrapper,
  Title,
  TransferBtn,
  TransferBtnContainer,
  TransferBtnText,
  TransferWrapper,
} from "./components";
import { useDispatch } from "store";

export const Main = () => {
  const dispatch = useDispatch();

  const goToHandler = (tab: AnotherPages | TabVariants) => {
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  return (
    <>
      <Container>
        <Title>Перевести и пополнить</Title>
        <TransferWrapper>
          <TransferBtnContainer>
            <TransferBtn onClick={() => goToHandler(AnotherPages.transfers)}>
              <IconWrapper>
                <IconArrow rotate={45} />
              </IconWrapper>

              <CardImg src={TransferImg} />
              <TransferBtnText>Перевести</TransferBtnText>
            </TransferBtn>

            {SHOW_REFILL && (
              <TransferBtn onClick={() => goToHandler(AnotherPages.refill)}>
                <IconWrapper>
                  <IconArrow rotate={180} />
                </IconWrapper>
                <CardImg src={ReplenishImg} />
                <TransferBtnText>Пополнить</TransferBtnText>
              </TransferBtn>
            )}
          </TransferBtnContainer>
        </TransferWrapper>

        <LatestTransactions />
      </Container>
    </>
  );
};
