// @ts-ignore
import { Typography, BoxTypeMap, Box, SxProps, Theme } from "@mui/material";
import { Button } from "components/Button";

type NotificationResultProps = {
  pageTitle?: string;
  title: string;
  titleSize?: any;
  subtitle?: string | React.ReactNode;
  subtitleSize?: any;
  icon?: React.ReactNode;
  buttonIcon?: React.ReactNode;
  height?: BoxTypeMap["props"]["height"];
  buttons?: {
    onClick: () => void;
    name: string;
    variant: "primary" | "secondary" | "text";
  }[];
  type?: "success" | "error";
  bottomSpace?: number;
  buttonStyle?: SxProps<Theme>;
  titleContainerStyle?: SxProps<Theme>;
  titleStyle?: object;
  titleSx?: SxProps<Theme>;
  subtitleStyle?: object;
  buttonsMarginTop?: number;
  boxWidth?: number | string;
  boxPadding?: string;
};

export const NotificationResult = (props: NotificationResultProps) => {
  const {
    pageTitle,
    title,
    subtitle,
    icon,
    buttons,
    height,
    titleSize = "text_8",
    subtitleSize = "text_4",
    bottomSpace = 0,
    buttonStyle,
    titleContainerStyle,
    titleStyle,
    titleSx,
    subtitleStyle,
    buttonsMarginTop,
    buttonIcon,
    boxWidth,
    boxPadding,
  } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={height}
      minHeight={height}
      width={{ xl: boxWidth ? boxWidth : 394 }}
      textAlign="center"
      padding={boxPadding ? boxPadding : { sm: "0px 16px", lg: "0px 48px" }}
      marginBottom={bottomSpace}
    >
      {pageTitle && (
        <Typography
          sx={{ fontWeight: 600 }}
          mt={{ xs: 32, xl: 60 }}
          mb={{ xs: 24, xl: 48 }}
          variant="text_9"
        >
          {pageTitle}
        </Typography>
      )}
      <Box display="flex" flexDirection="column">
        {icon && (
          <Box display="flex" justifyContent="center">
            {icon}
          </Box>
        )}

        <Box sx={{ mt: 24, ...titleContainerStyle }}>
          <Typography
            variant={titleSize}
            color="gray.b900"
            fontWeight={600}
            whiteSpace="pre-wrap"
            sx={titleSx}
            style={{ ...titleStyle }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          display="flex"
          margin="10px auto 0"
          style={{ whiteSpace: "pre-wrap" }}
          mt={10}
          maxWidth={375}
          justifyContent="center"
        >
          {typeof subtitle === "string" ? (
            <Typography
              variant={subtitleSize}
              color="#393E46"
              fontWeight={500}
              style={{ ...subtitleStyle }}
            >
              {subtitle}
            </Typography>
          ) : (
            subtitle
          )}
        </Box>
      </Box>
      {buttons && (
        <Box
          width="100%"
          margin={{ xs: `${buttonsMarginTop || 100}px auto 0` }}
          sx={buttonStyle}
        >
          {buttons?.map(({ name, onClick, variant }) => (
            <Button
              key={name}
              title={name}
              startIcon={buttonIcon}
              variant={variant}
              onClick={onClick}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};
