export const IconChatError = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={151} height={150} fill="none">
    <path
      fill="url(#a)"
      d="M75.5 150c41.421 0 75-33.579 75-75s-33.579-75-75-75S.5 33.579.5 75s33.579 75 75 75Z"
    />
    <path
      fill="#739B67"
      d="M36.506 104.063c.279-1.027-.338-2.454-.926-3.483a10.508 10.508 0 0 0-.595-.89 46.83 46.83 0 0 1-7.735-25.782C27.168 47.599 48.984 26.25 75.96 26.25c23.527 0 43.165 16.298 47.754 37.934a46.604 46.604 0 0 1 1.036 9.759c0 26.346-20.974 48.03-47.95 48.03-4.29 0-10.079-1.078-13.236-1.961-3.157-.884-6.31-2.056-7.122-2.37a7.281 7.281 0 0 0-2.607-.485 7.184 7.184 0 0 0-2.833.57l-15.898 5.737a3.74 3.74 0 0 1-1.094.286 2.261 2.261 0 0 1-1.599-.673 2.248 2.248 0 0 1-.644-1.61c.02-.261.066-.52.14-.771l4.599-16.633Z"
    />
    <path
      fill="#fff"
      d="M69.438 50.5c2.694-4.667 9.43-4.667 12.124 0l18.62 32.25c2.694 4.667-.674 10.5-6.063 10.5H56.88c-5.388 0-8.756-5.833-6.062-10.5l18.62-32.25Z"
    />
    <rect
      width={4.255}
      height={22.695}
      x={73.536}
      y={58.432}
      fill="#739B67"
      rx={2.128}
    />
    <circle cx={75.663} cy={84.673} r={2.128} fill="#739B67" />
    <defs>
      <linearGradient
        id="a"
        x1={75.5}
        x2={148.768}
        y1={0}
        y2={129.337}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset={1} stopColor="#F5F5F5" />
      </linearGradient>
    </defs>
  </svg>
);
