import { useState, useCallback, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useIsPWA } from "hooks/useIsPWA";
import { authWebApi } from "api";
import { PinEnter } from "organisms/PinCode/PinEnter";

export const PrivateRoutePinEnter = () => {
  const [pinAllowed, setPinAllowed] = useState(true);
  const isPWA = useIsPWA();
  const checkPin = useCallback(async () => {
    const {
      data: { pinAllowed },
    } = await authWebApi.checkPinAllowed({
      deviceId:
        (isPWA
          ? localStorage.getItem("PWADeviceId")
          : localStorage.getItem("deviceId")) || "",
    });
    setPinAllowed(pinAllowed || false);
  }, [isPWA]);

  useEffect(() => {
    checkPin();
  }, [checkPin]);

  if (!pinAllowed) {
    return <Navigate to="/sign-in" replace={true} />;
  }

  return <PinEnter />;
};
