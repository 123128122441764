export const IconArrowOutcoming = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66667 14.1663H11.3333C11.7015 14.1663 12 13.8679 12 13.4997C12 13.1315 11.7015 12.833 11.3333 12.833H4.66667C4.29848 12.833 4 13.1315 4 13.4997C4 13.8679 4.29848 14.1663 4.66667 14.1663Z"
      fill="#739B67"
    />
    <path
      d="M3.21396 7.02387C3.50093 7.28235 3.96441 7.28235 4.25139 7.02387L7.28275 4.2772V11.3639C7.28275 11.7321 7.61216 12.0305 8.01852 12.0305C8.42487 12.0305 8.75428 11.7321 8.75428 11.3639V4.2772L11.7856 7.02387C12.0776 7.25038 12.5127 7.23516 12.7845 6.98891C13.0563 6.74266 13.0731 6.34837 12.8231 6.08387L8.54091 2.1972C8.40276 2.07099 8.2147 2 8.01852 2C7.82233 2 7.63427 2.07099 7.49612 2.1972L3.21396 6.08387C2.92868 6.34389 2.92868 6.76384 3.21396 7.02387Z"
      fill="#739B67"
    />
  </svg>
);
