import { Box, Grid, Typography } from "@mui/material";
import { IconBlocked } from "atoms/IconBlocked";
import { Button } from "components/Button";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch } from "store";
import { authActions } from "store/slices/auth";
import { profileActions } from "store/slices/profile";

export const BlockedScreenPinChange = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const handleClick = () => {
    dispatch(authActions.setLoginIsBlocked(false));
    dispatch(profileActions.setIsModalOpened(false));
  };

  return (
    <Grid
      flexGrow="1"
      display="flex"
      container
      px={{ xs: 16, xl: 86 }}
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
      flexDirection="column"
    >
      <Box
        p={{ xs: "32px 0 180px", md: "50px 30.5px" }}
        component="div"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          justifyContent="center"
          marginTop={isMobile ? 0 : 40}
          marginBottom={20}
        >
          <IconBlocked />
        </Box>
        <Typography
          sx={{
            fontWeight: isMobile ? 500 : 600,
            fontSize: isMobile ? "20px" : "24px",
            lineHeight: isMobile ? "24px" : "28px",
            textAlign: "center",
            color: isMobile ? "var(--main-color-text-title)" : "#454A3FE5",
            whiteSpace: "pre-wrap",
          }}
        >
          {`Вход по короткому коду\nзаблокирован`}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20px",
            textAlign: "center",
            color: "var(--main-color-text-secondary-2)",
            marginTop: isMobile ? "8px" : "12px",
            whiteSpace: "pre-wrap",
          }}
        >
          Вы можете войти по логину и паролю
        </Typography>
        <Box
          maxWidth="376px"
          width="100%"
          margin={{ xs: "40px auto 0", xl: "40px auto 0" }}
        >
          <Button
            variant="primary"
            onClick={handleClick}
            title="Войти по логину и паролю"
          />
        </Box>
      </Box>
    </Grid>
  );
};
