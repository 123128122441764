import { Box, Dialog, Theme } from "@mui/material";
import { styled } from "@mui/styles";
import { useIsMobile } from "hooks/useIsMobile";
import { useResetStore } from "hooks/useResetStore";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { hideErrorMessage } from "store/slices/alerts";
import { authActions } from "store/slices/auth";
import { ModalHeader } from "./../../components/ModalHeader/ModalHeader";
import { useAuthCommonActions } from "hooks/useAuthCommonActions";

interface Props {
  top?: number;
  isMobile?: boolean;
  theme: Theme;
}

const DialogContainer = styled(Dialog)(({ theme, top, isMobile }: Props) => ({
  backdropFilter: "blur(3.25px)",

  "& .MuiPaper-root": {
    margin: 0,
    padding: 0,
    paddingTop: 0,
    boxSizing: "border-box",
    maxHeight: "100%",
    maxWidth: "476px",
    width: "100%",
    height: "100%",
    position: "fixed",
    top: top && isMobile ? top : 0,
    boxShadow: top && isMobile ? "none" : undefined,
    right: 0,
    borderRadius: 0,
    overflowX: "hidden",
    ...(theme.breakpoints?.down?.("sm") && {
      boxSizing: "border-box",
      maxHeight: "100%",
      width: "100%",
      overflowX: "hidden",
      paddingTop: 0,
    }),
  },
}));

export const Modal = ({
  title,
  subTitle = "",
  isOpen,
  onClose,
  buttonVisible,
  callback,
  children,
  centerText = false,
  isProfile = false,
  headerTop = false,
  showTitle = true,
  isCenteredTitle = false,
  titleMarginTop = "",
  center = false,
  titleStyle = {},
  ...props
}) => {
  const { isMobile } = useIsMobile();
  const top = props.top;
  const dispatch = useDispatch();
  const { isLoginUpdated, updatePasswordStep } = useSelector(
    (state) => state.profile
  );
  const isPasswordUpdated = updatePasswordStep === "SUCCESS_UPDATE";
  const navigate = useNavigate();
  const resetStore = useResetStore();
  const { handleLogout } = useAuthCommonActions();

  const handleClose = () => {
    if (isLoginUpdated || isPasswordUpdated) {
      dispatch(authActions.setLogout());
      const deviceIdItem = localStorage.getItem("deviceId");
      const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");
      localStorage.clear();
      if (deviceIdItem !== null) localStorage.setItem("deviceId", deviceIdItem);
      if (PWAdeviceIdItem !== null)
        localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
      sessionStorage.clear();
      navigate("/sign-in", { replace: true });
      resetStore();
    } else {
      onClose();
      dispatch(hideErrorMessage());
    }
  };

  const onBack = () => {
    if (buttonVisible) {
      callback();
    } else {
      onClose();
    }
    dispatch(hideErrorMessage());
  };

  return (
    <DialogContainer
      hideBackdrop={top && isMobile}
      open={isOpen}
      onClose={handleClose}
      {...props}
      isMobile={isMobile}
    >
      <ModalHeader
        title={title}
        subTitle={subTitle}
        isMobile={isMobile}
        isProfile={isProfile}
        buttonVisible={buttonVisible}
        isLoginUpdated={isLoginUpdated}
        isPasswordUpdated={isPasswordUpdated}
        onBack={onBack}
        handleLogout={handleLogout}
        titleMarginTop={titleMarginTop}
        titleStyle={titleStyle}
      />
      <Box
        sx={{
          overflowY: "scroll",
          height: "100%",
          paddingTop: isMobile ? "76px" : "92px",
        }}
      >
        {children}
      </Box>
    </DialogContainer>
  );
};
