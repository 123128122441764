import { LoaderWrapper } from "./components";
import { useEffect, useState } from "react";
import { RefillInfoCard } from "pages/home/Cards/RefillInfoCard/RefillInfoCard";
import { getRefillInfo } from "store/slices/transferSlice";
import { OrderInfo } from "api/account";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "store";
import { Loader } from "components/Loader/Loader";

interface RefillInfoPageProps {
  refillId?: string;
}

export const RefillInfoPage: React.FC<RefillInfoPageProps> = ({ refillId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refill, setRefill] = useState<OrderInfo | null>(null);
  const [refillError, setRefillError] = useState<boolean>(false);
  const [refillLoading, setRefillLoading] = useState<boolean>(true);

  useEffect(() => {
    const urlId = location.pathname.split("/").at(-1);

    if (!refillId && !urlId) return;

    dispatch(getRefillInfo((refillId || urlId) as string))
      .unwrap()
      .then((res) => setRefill((res.orders as OrderInfo[])[0]))
      .catch(() => setRefillError(true))
      .finally(() => setRefillLoading(false));
  }, [dispatch, refillId]);

  if (refillError) {
    navigate("/404", { replace: true });
  }

  if (refillLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return <RefillInfoCard refill={refill} />;
};
