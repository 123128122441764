import styles from "./Exit.module.css";
import cn from "classnames";
import { IconContainer } from "atoms/IconContainer";
import { makeStyles } from "@mui/styles";
import { IconArrow } from "atoms/IconArrow/IconArrow";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
    "& > svg > path": {
      transition: ".2s",
    },
    "&:hover": {
      "& > svg > path": {
        transition: ".2s",
        fill: "#D9E3CC",
      },
    },
  },
}));

export const Exit = () => {
  const classes = useStyles();

  return (
    <IconContainer className={cn(styles.iconExitContainer, classes.icon)}>
      <IconArrow />
    </IconContainer>
  );
};
