import { Box, Link } from "@mui/material";
import { URL_FOOTER_ADDRESSES, URL_FOOTER_SITE } from "constants/url";
import styles from "./styles.module.css";
import logo from "./RuStore.png";
import { env } from "../../env";
import { useIsMobile } from "hooks/useIsMobile";

export const Footer = () => {
  const { isMobile } = useIsMobile();
  const isRefillPage = window.location.href.includes("refill-info");

  if (isRefillPage) return null;

  return (
    <Box bgcolor="var(--main-color-bg-widgets)">
      <div className={styles.container}>
        <div className={styles.content}>
          <div>
            <p className={styles.title}>Связь с банком</p>
            <Link
              variant="text_5"
              color="#739b67"
              fontWeight="500"
              underline="none"
              href="tel:+7 (800) 200-45-67"
            >
              +7 (800) 200-45-67
            </Link>
            <p className={styles.description}>Бесплатный звонок по России</p>
            <div style={{ marginTop: "16px" }}>
              <Link
                variant="text_5"
                color="#739b67"
                fontWeight="500"
                underline="none"
                href="tel:+7 (812) 603-00-90"
              >
                +7 (812) 603-00-90
              </Link>
              <p className={styles.description}>
                Телефон горячей линии для звонков за пределами
                {!isMobile ? <br /> : " "}
                России
              </p>
            </div>
          </div>
          <div className={styles.block}>
            <p className={`${styles.title} ${styles.invariant}`}>
              Банкоматы и офисы
            </p>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Link
                target="_blank"
                href={URL_FOOTER_ADDRESSES}
                variant="text_5"
                color="#739b67"
                fontWeight="500"
                underline="none"
              >
                Адреса
              </Link>
              <Link
                target="_blank"
                href={URL_FOOTER_SITE}
                underline="none"
                variant="text_5"
                color="#8b8c88"
              >
                перейти на сайт
              </Link>
            </div>
          </div>
          <div>
            <p
              className={`${styles.title} ${styles.invariant}`}
              style={{ marginBottom: "12px" }}
            >
              Приложение для мобильных устройств
            </p>
            <a
              href="https://www.rustore.ru/catalog/app/com.fynativemobile.pu"
              target="_blank"
              rel="noreferrer"
            >
              <img src={logo} className={styles.logo} />
            </a>
          </div>
        </div>
        <div className={styles.line} />
        <div className={styles.infoBlock}>
          <div>
            <p className={styles.info}>© Лучи, 2024</p>
          </div>
          <p className={styles.info}>Версия {env.REACT_APP_VERSION}</p>
        </div>
      </div>
    </Box>
  );
};
