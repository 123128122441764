import { pushApi } from "api";
import { PmpDeviceDto } from "api/notification";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface State {
  isLoadingDevices: boolean;
  isLoadingDisable: boolean;
  isLoadingToggle: boolean;
  isOn: boolean;
  devices: PmpDeviceDto[];
}

const initialState: State = {
  isLoadingDevices: false,
  isLoadingDisable: false,
  isLoadingToggle: false,
  isOn: false,
  devices: [],
};

export const handleCheckPushStatus = createAsyncThunk(
  "handleCheckPushStatus",
  async (payload: { deviceId: string; fcm: string }) => {
    const { data } = await pushApi.pushState("", payload);
    return data;
  }
);

export const allDevicesWithPush = createAsyncThunk(
  "allDevicesWithPush",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await pushApi.allDevicesWithPush("");

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const pushDisableAll = createAsyncThunk(
  "pushDisableAll",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await pushApi.pushDisableAll("");

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const allNotificationsSlice = createSlice({
  name: "allNotifications",
  initialState,
  reducers: {
    setIsOn: (state, { payload }) => {
      state.isOn = payload;
    },
    resetStore: (state) => {
      state.isLoadingDevices = false;
      state.isLoadingDisable = false;
      state.isLoadingToggle = false;
      state.devices = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(handleCheckPushStatus.pending, (state) => {
      state.isLoadingToggle = true;
    });
    builder.addCase(handleCheckPushStatus.fulfilled, (state, { payload }) => {
      if (!payload.enabled && localStorage.getItem("isPushOn")) {
        localStorage.removeItem("isPushOn");
        state.isOn = false;
      }
      state.isLoadingToggle = false;
    });
    builder.addCase(handleCheckPushStatus.rejected, (state) => {
      state.isLoadingToggle = false;
    });
    builder.addCase(allDevicesWithPush.pending, (state) => {
      state.isLoadingDevices = true;
    });
    builder.addCase(allDevicesWithPush.fulfilled, (state, { payload }) => {
      state.devices = payload as unknown as PmpDeviceDto[];
      state.isLoadingDevices = false;
    });
    builder.addCase(allDevicesWithPush.rejected, (state) => {
      state.isLoadingDevices = false;
    });
    builder.addCase(pushDisableAll.pending, (state) => {
      state.isLoadingDisable = true;
    });
    builder.addCase(pushDisableAll.fulfilled, (state, { payload }) => {
      state.devices = [];
      localStorage.removeItem("isPushOn");
      state.isOn = false;
      state.isLoadingDisable = false;
    });
    builder.addCase(pushDisableAll.rejected, (state) => {
      state.isLoadingDisable = false;
    });
  },
});

export const allNotificationsActions = allNotificationsSlice.actions;
