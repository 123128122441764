import { IconErrorBiometry } from "atoms/IconErrorBiometry/IconErrorBiometry";
import {
  Container,
  Description,
  Flex,
  Title,
  TitleBlock,
  Xbtn,
} from "./components";
import { useState } from "react";

type WarningAlertProps = {
  title: string;
  subtitle?: string;
};

export const WarningAlert: React.FC<WarningAlertProps> = ({
  title,
  subtitle,
}) => {
  const [open, setOpen] = useState(true);

  if (!open) return null;

  return (
    <Container>
      <TitleBlock>
        <Flex>
          <IconErrorBiometry width={20} height={20} />
          <Title>{title}</Title>
        </Flex>
        <Xbtn onClick={() => setOpen(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M11.5909 9.99902L16.0441 5.5459C16.2554 5.33492 16.3743 5.04864 16.3745 4.75002C16.3748 4.45139 16.2564 4.1649 16.0455 3.95355C15.8345 3.74221 15.5482 3.62333 15.2496 3.62306C14.951 3.6228 14.6645 3.74118 14.4531 3.95215L9.99999 8.40527L5.54687 3.95215C5.33553 3.7408 5.04888 3.62207 4.75 3.62207C4.45111 3.62207 4.16447 3.7408 3.95312 3.95215C3.74178 4.16349 3.62305 4.45014 3.62305 4.74902C3.62305 5.04791 3.74178 5.33455 3.95312 5.5459L8.40625 9.99902L3.95312 14.4521C3.74178 14.6635 3.62305 14.9501 3.62305 15.249C3.62305 15.5479 3.74178 15.8345 3.95312 16.0459C4.16447 16.2572 4.45111 16.376 4.75 16.376C5.04888 16.376 5.33553 16.2572 5.54687 16.0459L9.99999 11.5928L14.4531 16.0459C14.6645 16.2572 14.9511 16.376 15.25 16.376C15.5489 16.376 15.8355 16.2572 16.0469 16.0459C16.2582 15.8345 16.3769 15.5479 16.3769 15.249C16.3769 14.9501 16.2582 14.6635 16.0469 14.4521L11.5909 9.99902Z"
              fill="#E57616"
            />
          </svg>
        </Xbtn>
      </TitleBlock>
      {subtitle && <Description>{subtitle}</Description>}
    </Container>
  );
};
