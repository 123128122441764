import { requestPermission } from "./../firebase";
import { AppDispatch } from "store";
import { allNotificationsActions } from "store/slices/allNotifications";
import { handleTogglePush } from "store/slices/notifications";

interface PushToggleParams {
  deviceId: string;
  isOn: boolean;
  dispatch: AppDispatch;
  onSuccess?: () => void;
  onError?: (message: string) => void;
  onPermissionError?: () => void;
}

export const pushNotificationsHandler = async ({
  deviceId,
  isOn,
  dispatch,
  onError,
  onSuccess,
  onPermissionError,
}: PushToggleParams) => {
  try {
    const permissionResult = await requestPermission();

    try {
      await dispatch(
        handleTogglePush({
          deviceId,
          fcm: permissionResult.token || "",
          enabled: isOn,
          device_type: "W",
        })
      ).unwrap();

      dispatch(allNotificationsActions.setIsOn(isOn));

      if (isOn) {
        localStorage.setItem("isPushOn", "true");
      } else {
        localStorage.removeItem("isPushOn");
      }

      onSuccess?.();
    } catch {
      onError?.("Проверьте соединение");
    }
  } catch (err: any) {
    if (err.alert && isOn) {
      onPermissionError?.();
    } else {
      if (!isOn) {
        dispatch(allNotificationsActions.setIsOn(false));
        localStorage.removeItem("isPushOn");
      } else {
        onError?.(err.text || "Произошла ошибка");
      }
    }
  }
};
