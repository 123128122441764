import styled from "@emotion/styled/macro";

const checkboxStyle = `
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 10px;
`;

interface ItemCardContainerProps {
  fullWidth: boolean;
}

export const ItemCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0;
  border-radius: 12px;
  margin-left: ${(props: ItemCardContainerProps) =>
    !props.fullWidth ? "32px" : "0"};
`;

export const ItemCardBody = styled.div`
  background: ${(props: { background: string }) => props.background};
  padding: 12px 16px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: all 0.3s;
`;

export const ItemCardActionsBlock = styled.div`
  position: absolute;
  z-index: 100;
  top: 48px;
  border-radius: 9px;
  box-shadow: 0px 4px 11.3px 0px rgba(133, 131, 131, 0.24);
  background: #fff;
  padding: 15px;
  cursor: pointer;
  margin-top: 8px;
  width: 172px;
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ActionTitle = styled.p`
  margin: 0px;
  font-size: 13px;
  line-height: 14px;
  margin-left: 6px;
`;

export const ItemCardIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props: { background: string }) => props.background};
`;

export const ItemCardTitle = styled.p`
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--main-color-text-title);
`;

export const ItemCardNumber = styled.span`
  marign: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #7e7f7a;
`;

export const SmallTitle = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
  padding: 10px 0;
`;

export const ItemCardIcon = styled.img`
  width: 30px;
  height: 22.5px;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;
`;

export const AllCardsWrapper = styled.div`
  position: relative;
`;
