import { Box } from "@mui/material";
import { IconLuchi } from "atoms/IconLuchi";

export const OperatorIcon = () => (
  <Box
    display="flex"
    alignSelf="flex-end"
    sx={{
      width: 24,
      height: 24,
      borderRadius: "50%",
      marginBottom: 2,
    }}
  >
    <IconLuchi width={24} height={24} />
  </Box>
);
