import theme from "theme";
import { ChatMessage, ChatMessageDto } from "api/notification";
import { Typography, Box } from "@mui/material";
import { formattedDate } from "store/slices/chat/utils";
import { MessageItem } from "../MessageItem/MessageItem";

interface ChatBlockProps {
  date: string;
  messages: ChatMessage[];
  isWindow: boolean;
  isReply: boolean;
  isScrolled: boolean;
  onMsgSelect: (msg: ChatMessage) => void;
  selectedMsg: ChatMessage | null;
  onReplyMessageClick: (messageId: string) => void;
}

const sortByDate = (messages: ChatMessage[]) => {
  return messages.sort((messageA, messageB) => {
    const dateA = new Date(messageA.timestamp || "").getTime();
    const dateB = new Date(messageB.timestamp || "").getTime();

    return dateA - dateB;
  });
};

export const ChatBlock = ({
  messages,
  date,
  isWindow,
  onMsgSelect,
  selectedMsg,
  isReply,
  isScrolled,
  onReplyMessageClick,
}: ChatBlockProps) => (
  <Box marginTop="auto">
    <Typography
      typography="text_3"
      color={theme.primary.main.gray["300"]}
      textAlign="center"
    >
      {formattedDate(date)}
    </Typography>
    {sortByDate(messages).map((message) => (
      <MessageItem
        message={message}
        key={message.id}
        isWindow={isWindow}
        onMsgSelect={() => {
          isReply || isScrolled ? null : onMsgSelect(message);
        }}
        onReplyMessageClick={onReplyMessageClick}
        selectedMsg={selectedMsg}
        useCompactPadding
        showIcon
      />
    ))}
  </Box>
);
