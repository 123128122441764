export const IconCheckMark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M7.45526 15.1956C7.16657 15.1954 6.88684 15.0953 6.66359 14.9122L2.93026 11.8539C2.41977 11.4104 2.3545 10.6414 2.78295 10.1183C3.2114 9.5951 3.9782 9.50751 4.51359 9.92058L7.40526 12.2872L15.7386 4.61224C16.0518 4.2398 16.5527 4.083 17.0224 4.21037C17.4921 4.33773 17.8451 4.72611 17.9273 5.20576C18.0095 5.68542 17.8058 6.16916 17.4053 6.44557L8.30526 14.8622C8.075 15.078 7.7708 15.1973 7.45526 15.1956Z"
      fill={props.color ?? "#739B67"}
    />
  </svg>
);
