import { Typography, Box, SvgIcon } from "@mui/material";
import { styled } from "@mui/system";

import { useSelector } from "store";

const MobileTabItemContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  rowGap: "2px",
});

export const MobileTabItem = ({ tabInfo, ...props }) => {
  const {
    system: { activeTab },
    chat: { newMessageCounter },
    cards: { isLoading },
  } = useSelector((state) => state);

  const { title, getIcon } = tabInfo;

  const activeColor =
    tabInfo.id === activeTab
      ? "var(--brand-color-primary)"
      : "var(--main-color-text-secondary-unactive)";

  return (
    <MobileTabItemContainer
      {...props}
      style={{ pointerEvents: isLoading ? "none" : "auto" }}
    >
      <SvgIcon>{getIcon(activeColor, newMessageCounter > 0)}</SvgIcon>
      <Typography variant="text_15" color={activeColor}>
        {title}
      </Typography>
    </MobileTabItemContainer>
  );
};
