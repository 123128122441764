import { useCallback, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { Modal as ModalUI } from "molecules/Modal/Modal";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "store";
import { profileActions } from "store/slices/profile";
import { Button } from "components/Button";
import { ProfileModalType } from "./models";
import { ProfileOption } from "./ProfileOption";
import { useIsMobile } from "hooks/useIsMobile";
import { formatPhoneNumberHide } from "utils/formatPhoneNumber";
import { CreatePinCode } from "organisms/ModalContent/ModalProfile/CreatePinCode";
import { useIsPWA } from "hooks/useIsPWA";
import { authWebApi } from "api";
import { authActions } from "store/slices/auth";
import { ProfileTogglesRender } from "./ProfileTogglesRender";
import { IconPhone } from "atoms/IconPhone/IconPhone";
import { IconBell } from "atoms/IconBell/IconBell";
import { ProfileToggle } from "./ProfileToggle";
import { IconProfileLogout } from "atoms/IconProfileLogout/IconProfileLogout";
import { isServerError } from "utils/isServerError";
import { IconLoginChangeWarning } from "atoms/IconLoginChangeWarning/IconLoginChangeWarning";
import { IconBellNotification } from "atoms/IconBellNotification/IconBellNotification";
import { ProfileTogglePush } from "./ProfileTogglePush";
import { ProfileToggleBiometry } from "./ProfileToggleBiometry";
import { AllNotifications } from "./components/AllNotifications";
import { allDevicesWithPush } from "store/slices/allNotifications";
import { SkeletonContainer } from "components/SkeletonContainer/SkeletonContainer";
import { useAuthCommonActions } from "hooks/useAuthCommonActions";
import { UpdatePassword } from "organisms/ModalContent/ModalProfile/UpdatePassword";
import { UpdatePinCode } from "organisms/ModalContent/ModalProfile/UpdatePinCode";
import { LoginModal } from "./components/LoginModal";
import { LogoutModal } from "./components/LogoutModal";
import { NotificationNewsModal } from "./components/NotificationNewsModal/NotificationNewsModal";

const ProfileWrapper = styled.div`
  font-family: "Geologica";
  font-style: normal;
`;

const ProfileContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const AlertLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const AlertLoginContent = styled.div`
  padding: 32px 32px 20px;
  border-radius: 24px;
  background: var(--main-color-white);
  width: 100%;
  max-width: 464px;
  text-align: center;

  @media (max-width: 767px) {
    max-width: 300px;
  }
`;

const AlertTitle = styled.p`
  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: var(--main-color-text-title);
  text-align: center;
  margin: 12px 0;
`;

const AlertDescription = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-secondary-2);
  text-align: center;
  margin: 0;
`;

const AlertButtonsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 40px;
`;

const newsAndNotifications = Object.freeze([
  {
    id: ProfileModalType.THIS_DEVICE_NOTIFICATIONS,
    title: "Уведомления",
    icon: <IconBell width={20} height={20} />,
  },
]);

export const Profile = ({ phoneNumber }: { phoneNumber: string }) => {
  const { isProfileLoading, user, error, modalType } = useSelector(
    (state) => state.profile
  );
  const { elseDevice, isPinAllowed, isPinUpdated } = useSelector(
    (state) => state.auth
  );
  const { notifications, news } = useSelector((state) => state.notifications);
  const { isMobile } = useIsMobile();
  const isPWA = useIsPWA();
  const { handleLogout } = useAuthCommonActions();
  const dispatch = useDispatch();

  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);

  const hasNotifications = Object.keys(notifications).length > 0;
  const hasNews = Object.keys(news).length > 0;

  const hasAnyEntries = hasNotifications || hasNews;

  const hideBackButton = isServerError(error);

  const handleBackPassword = useCallback(() => {
    dispatch(profileActions.setCurrentPassword(""));

    dispatch(profileActions.setModalType(null));
    dispatch(profileActions.setUpdatePasswordStep("CURRENT_PASSWORD"));
  }, [dispatch]);

  const handleCloseModal = useCallback(() => {
    if (hideBackButton) {
      dispatch(profileActions.clearError());
    }
    if (modalType === ProfileModalType.PASSWORD) {
      handleBackPassword();
    } else {
      dispatch(profileActions.setModalType(null));
    }
  }, [modalType, handleBackPassword, dispatch, hideBackButton]);

  const handleOptionClick = (id: ProfileModalType) => {
    if (id === ProfileModalType.LOGIN) {
      setShowLoginAlert(true);
    }
    if (id === ProfileModalType.PASSWORD) {
      dispatch(profileActions.clearErrorCurrentPassword());
      setShowPasswordAlert(true);
    }

    dispatch(profileActions.setModalType(id));
  };

  const handleNotificationClick = () => {
    dispatch(
      profileActions.setModalType(ProfileModalType.THIS_DEVICE_NOTIFICATIONS)
    );
  };

  const handleClose = useCallback(() => {
    handleCloseModal();
    dispatch(profileActions.setIsLoginUpdated(false));
    dispatch(authActions.setIsPinUpdated(false));
  }, [dispatch, handleCloseModal]);

  const handleCloseLodinAndPassword = useCallback(() => {
    handleClose();
    if (
      modalType === ProfileModalType.PASSWORD ||
      modalType === ProfileModalType.LOGIN
    ) {
      dispatch(profileActions.setIsModalOpened(false));
    }
  }, [dispatch, handleClose, modalType]);

  const content = useMemo(
    () => ({
      [ProfileModalType.LOGIN]: (
        <LoginModal login={user?.login} onClose={handleCloseLodinAndPassword} />
      ),
      [ProfileModalType.PASSWORD]: (
        <UpdatePassword onClose={handleCloseLodinAndPassword} />
      ),
      [ProfileModalType.LOGOUT]: (
        <LogoutModal onClick={handleLogout} onCancel={handleCloseModal} />
      ),
      [ProfileModalType.THIS_DEVICE_NOTIFICATIONS]: <NotificationNewsModal />,
      [ProfileModalType.ALL_NOTIFICATIONS]: (
        <AllNotifications onClose={handleCloseModal} />
      ),
      [ProfileModalType.PINCODE]:
        isPinAllowed && !elseDevice ? (
          <UpdatePinCode onClose={handleClose} />
        ) : (
          <CreatePinCode onClose={handleClose} />
        ),
    }),

    [
      user?.login,
      handleCloseLodinAndPassword,
      handleLogout,
      handleCloseModal,
      isPinAllowed,
      elseDevice,
      handleClose,
    ]
  );

  const modalTitle = useMemo(() => {
    switch (modalType) {
      case ProfileModalType.LOGIN:
        return "Изменить логин";
      case ProfileModalType.PASSWORD:
        return "Изменить пароль";
      case ProfileModalType.THIS_DEVICE_NOTIFICATIONS:
        return "Уведомления";
      case ProfileModalType.PINCODE:
        return isPinAllowed && !elseDevice
          ? "Изменить код для входа"
          : "Создать код для входа";
      default:
        return "";
    }
  }, [elseDevice, isPinAllowed, modalType]);

  useEffect(() => {
    const checkPin = async () => {
      const {
        data: { pinAllowed },
      } = await authWebApi.checkPinAllowed({
        deviceId:
          (isPWA
            ? localStorage.getItem("PWADeviceId")
            : localStorage.getItem("deviceId")) || "",
      });
      dispatch(authActions.setIsPinAllowed(pinAllowed || false));
    };
    checkPin();
  }, [dispatch, isPWA]);

  return (
    <ProfileWrapper>
      <ModalUI
        title={
          isMobile && modalType === ProfileModalType.PINCODE
            ? isPinAllowed && !elseDevice
              ? isPinUpdated
                ? ""
                : "Изменить код для входа"
              : "Создать код для входа"
            : modalTitle
        }
        isOpen={Boolean(modalType)}
        onClose={handleClose}
        callback={handleClose}
        buttonVisible={!hideBackButton}
        centerText={modalType === ProfileModalType.PINCODE}
        isProfile
        titleStyle={isMobile ? { fontSize: "24px" } : {}}
      >
        <Modal open={showLoginAlert}>
          <AlertLogin>
            <AlertLoginContent>
              <IconLoginChangeWarning />
              <AlertTitle>
                После смены логина нужно будет заново войти в приложение
              </AlertTitle>
              <AlertDescription>
                Нужно будет заново войти в личный кабинет с новым логином. На
                других устройствах в личный кабинет нужно будет также войти
                заново.
              </AlertDescription>
              <AlertButtonsBlock>
                <Button
                  variant="primary"
                  onClick={() => setShowLoginAlert(false)}
                  title="Понятно"
                />
                <Button
                  title="Отмена"
                  variant="secondary"
                  onClick={() => {
                    setShowLoginAlert(false);
                    dispatch(profileActions.setModalType(null));
                  }}
                />
              </AlertButtonsBlock>
            </AlertLoginContent>
          </AlertLogin>
        </Modal>
        <Modal open={showPasswordAlert}>
          <AlertLogin>
            <AlertLoginContent>
              <IconLoginChangeWarning />
              <AlertTitle>
                После смены пароля нужно будет заново войти в приложение
              </AlertTitle>
              <AlertDescription>
                Нужно будет заново войти в личный кабинет с новым паролем. На
                других устройствах в личный кабинет
                <br /> нужно будет также войти заново.
              </AlertDescription>
              <AlertButtonsBlock>
                <Button
                  variant="primary"
                  onClick={() => setShowPasswordAlert(false)}
                  title="Понятно"
                />
                <Button
                  variant="secondary"
                  title="Отмена"
                  onClick={() => {
                    setShowPasswordAlert(false);
                    dispatch(profileActions.setModalType(null));
                  }}
                />
              </AlertButtonsBlock>
            </AlertLoginContent>
          </AlertLogin>
        </Modal>
        {modalType && content[modalType]}
      </ModalUI>
      <ProfileContent p={{ xs: "0 16px", xl: "0 32px" }}>
        <Box display="flex" flexDirection="column" rowGap={12} mt={0} mb={20}>
          {newsAndNotifications.map(({ title, id, icon }) => (
            <ProfileOption
              key={id}
              title={title}
              id={id}
              onClick={() => handleNotificationClick()}
              icon={hasAnyEntries ? <IconBellNotification /> : icon}
            />
          ))}
        </Box>
        <Typography variant="text_17" color="gray.b900" fontWeight={500} my={6}>
          Настроить
        </Typography>
        <Box display="flex" flexDirection="column" rowGap={12} mt={8} mb={20}>
          {isPWA && <ProfileTogglePush />}
          <ProfileOption
            title={`Отключить push-уведомления\nна всех устройствах`}
            id={ProfileModalType.ALL_NOTIFICATIONS}
            onClick={() => {
              dispatch(allDevicesWithPush());
              dispatch(
                profileActions.setModalType(ProfileModalType.ALL_NOTIFICATIONS)
              );
            }}
            icon={<IconBell />}
          />
          {isPWA && <ProfileToggleBiometry />}
          <ProfileToggle />
        </Box>
        <Typography variant="text_17" color="gray.b900" fontWeight={500} my={6}>
          Изменить
        </Typography>
        <SkeletonContainer
          isLoading={isProfileLoading}
          sx={{ marginTop: "8px" }}
        >
          <Box display="flex" flexDirection="column" rowGap={12} mt={8}>
            <ProfileTogglesRender handleOptionClick={handleOptionClick} />
            <Typography
              variant="text_17"
              color="gray.b900"
              fontWeight={500}
              mt={14}
              mb={2}
            >
              Аккаунт привязан к номеру
            </Typography>
            <Box
              sx={{
                borderRadius: 15,
                backgroundColor: "var(--main-color-bg-widgets)",
                userSelect: "none",
                "-webkit-tap-highlight-color": "transparent",
              }}
              p={{ xs: 12, xl: 12 }}
              display="flex"
              gap={12}
              alignItems="center"
            >
              <Box
                sx={{
                  borderRadius: 11,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 40,
                  height: 40,
                  backgroundColor: "#FFFFFF",
                }}
              >
                <IconPhone width={20} height={20} />
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Typography
                  variant="text_22"
                  fontWeight={500}
                  color="var(--main-color-text-title)"
                  flex={1}
                >
                  {formatPhoneNumberHide(phoneNumber || "")}
                </Typography>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              rowGap={12}
              mt={24}
              mb={20}
              sx={{
                userSelect: "none",
                "-webkit-tap-highlight-color": "transparent",
              }}
            >
              <ProfileOption
                title="Выйти из профиля"
                id={ProfileModalType.LOGOUT}
                onClick={() => handleOptionClick(ProfileModalType.LOGOUT)}
                icon={<IconProfileLogout width={20} height={20} />}
              />
            </Box>
          </Box>
        </SkeletonContainer>
      </ProfileContent>
    </ProfileWrapper>
  );
};
