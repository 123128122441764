export const IconNoNotifications = (props) => (
  <svg
    width="200"
    height="150"
    viewBox="0 0 200 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M100.5 150C141.921 150 175.5 116.421 175.5 75C175.5 33.5786 141.921 0 100.5 0C59.0786 0 25.5 33.5786 25.5 75C25.5 116.421 59.0786 150 100.5 150Z"
      fill="#F5F5F5"
    />
    <g filter="url(#filter0_d_137_14012)">
      <path
        d="M146 10H11C8.23858 10 6 12.2386 6 15V40C6 42.7614 8.23858 45 11 45H146C148.761 45 151 42.7614 151 40V15C151 12.2386 148.761 10 146 10Z"
        fill="white"
      />
    </g>
    <path
      d="M75 18H49C47.3431 18 46 19.3431 46 21C46 22.6569 47.3431 24 49 24H75C76.6569 24 78 22.6569 78 21C78 19.3431 76.6569 18 75 18Z"
      fill="#E9E9E9"
    />
    <path
      d="M93 31H49C47.3431 31 46 32.3431 46 34C46 35.6569 47.3431 37 49 37H93C94.6569 37 96 35.6569 96 34C96 32.3431 94.6569 31 93 31Z"
      fill="#E9E9E9"
    />
    <path
      d="M33 13H14C11.2386 13 9 15.2386 9 18V37C9 39.7614 11.2386 42 14 42H33C35.7614 42 38 39.7614 38 37V18C38 15.2386 35.7614 13 33 13Z"
      fill="#739B67"
    />
    <path
      d="M21.2217 30.7798H23.2217L23.2437 30.0098C23.2437 28.9978 23.8817 28.7338 24.7837 28.4478C25.6885 28.1904 26.4849 27.6454 27.0525 26.8952C27.6201 26.1451 27.928 25.2305 27.9297 24.2898C27.9495 23.7154 27.8499 23.1432 27.6371 22.6094C27.4244 22.0755 27.1031 21.5916 26.6936 21.1884C26.2842 20.7851 25.7954 20.4713 25.2584 20.2667C24.7214 20.0621 24.1477 19.9712 23.5737 19.9998C22.9671 19.9865 22.3642 20.0967 21.8014 20.3235C21.2387 20.5503 20.7279 20.889 20.3 21.3192C19.8721 21.7493 19.536 22.2619 19.3122 22.8258C19.0883 23.3897 18.9813 23.9933 18.9977 24.5998H21.0657C21.0579 24.2649 21.1169 23.9318 21.2395 23.62C21.362 23.3083 21.5455 23.0241 21.7793 22.7842C22.0131 22.5443 22.2924 22.3535 22.6009 22.2229C22.9093 22.0923 23.2408 22.0246 23.5757 22.0238C23.8778 22.0063 24.1801 22.054 24.4621 22.1637C24.7441 22.2734 24.9992 22.4426 25.21 22.6596C25.4208 22.8767 25.5824 23.1366 25.6838 23.4217C25.7852 23.7068 25.8241 24.0103 25.7977 24.3118C25.7844 24.7997 25.6147 25.2704 25.3137 25.6546C25.0127 26.0388 24.5963 26.3161 24.1257 26.4458L23.3997 26.7338C22.6889 26.905 22.0684 27.337 21.6609 27.9441C21.2535 28.5511 21.0889 29.2891 21.1997 30.0118L21.2217 30.7798ZM22.2117 35.5338C22.4076 35.5407 22.6028 35.5083 22.7859 35.4385C22.9691 35.3687 23.1363 35.2629 23.2778 35.1274C23.4194 34.9918 23.5323 34.8293 23.6099 34.6493C23.6875 34.4694 23.7282 34.2757 23.7297 34.0798C23.728 33.884 23.687 33.6905 23.6093 33.5108C23.5316 33.3311 23.4186 33.1688 23.2771 33.0334C23.1356 32.8981 22.9685 32.7925 22.7855 32.7228C22.6025 32.6531 22.4074 32.6208 22.2117 32.6278C22.0161 32.6208 21.821 32.6531 21.638 32.7228C21.455 32.7925 21.2878 32.8981 21.1463 33.0334C21.0048 33.1688 20.8919 33.3311 20.8142 33.5108C20.7364 33.6905 20.6955 33.884 20.6937 34.0798C20.6952 34.2757 20.736 34.4694 20.8136 34.6493C20.8912 34.8293 21.0041 34.9918 21.1456 35.1274C21.2871 35.2629 21.4544 35.3687 21.6375 35.4385C21.8206 35.5083 22.0159 35.5407 22.2117 35.5338Z"
      fill="white"
    />
    <g filter="url(#filter1_d_137_14012)">
      <path
        d="M54 53H189C190.326 53 191.598 53.5268 192.536 54.4645C193.473 55.4021 194 56.6739 194 58V83C194 84.3261 193.473 85.5979 192.536 86.5355C191.598 87.4732 190.326 88 189 88H54C52.6739 88 51.4021 87.4732 50.4645 86.5355C49.5268 85.5979 49 84.3261 49 83V58C49 56.6739 49.5268 55.4021 50.4645 54.4645C51.4021 53.5268 52.6739 53 54 53V53Z"
        fill="white"
      />
    </g>
    <path
      d="M118 61H92C90.3431 61 89 62.3431 89 64C89 65.6569 90.3431 67 92 67H118C119.657 67 121 65.6569 121 64C121 62.3431 119.657 61 118 61Z"
      fill="#E9E9E9"
    />
    <path
      d="M136 74H92C90.3431 74 89 75.3431 89 77C89 78.6569 90.3431 80 92 80H136C137.657 80 139 78.6569 139 77C139 75.3431 137.657 74 136 74Z"
      fill="#E9E9E9"
    />
    <path
      d="M76 56H57C54.2386 56 52 58.2386 52 61V80C52 82.7614 54.2386 85 57 85H76C78.7614 85 81 82.7614 81 80V61C81 58.2386 78.7614 56 76 56Z"
      fill="#739B67"
    />
    <path
      d="M64.2217 73.7798H66.2217L66.2437 73.0098C66.2437 71.9978 66.8817 71.7338 67.7837 71.4478C68.6885 71.1904 69.4849 70.6454 70.0525 69.8952C70.6201 69.1451 70.928 68.2305 70.9297 67.2898C70.9495 66.7154 70.8499 66.1432 70.6371 65.6094C70.4244 65.0755 70.1031 64.5916 69.6936 64.1884C69.2842 63.7851 68.7954 63.4713 68.2584 63.2667C67.7214 63.0621 67.1477 62.9712 66.5737 62.9998C65.9671 62.9865 65.3642 63.0967 64.8014 63.3235C64.2387 63.5503 63.7279 63.889 63.3 64.3192C62.8721 64.7493 62.536 65.2619 62.3122 65.8258C62.0883 66.3897 61.9813 66.9933 61.9977 67.5998H64.0657C64.0579 67.2649 64.1169 66.9318 64.2395 66.62C64.362 66.3083 64.5455 66.0241 64.7793 65.7842C65.0131 65.5443 65.2924 65.3535 65.6009 65.2229C65.9093 65.0923 66.2408 65.0246 66.5757 65.0238C66.8778 65.0063 67.1801 65.054 67.4621 65.1637C67.7441 65.2734 67.9992 65.4426 68.21 65.6596C68.4208 65.8767 68.5824 66.1366 68.6838 66.4217C68.7852 66.7068 68.8241 67.0103 68.7977 67.3118C68.7844 67.7997 68.6147 68.2704 68.3137 68.6546C68.0127 69.0388 67.5963 69.3161 67.1257 69.4458L66.3997 69.7338C65.6889 69.905 65.0684 70.337 64.6609 70.9441C64.2535 71.5511 64.0889 72.2891 64.1997 73.0118L64.2217 73.7798ZM65.2117 78.5338C65.4076 78.5407 65.6028 78.5083 65.7859 78.4385C65.9691 78.3687 66.1363 78.2629 66.2778 78.1274C66.4194 77.9918 66.5323 77.8293 66.6099 77.6493C66.6875 77.4694 66.7282 77.2757 66.7297 77.0798C66.728 76.884 66.687 76.6905 66.6093 76.5108C66.5316 76.3311 66.4186 76.1688 66.2771 76.0334C66.1356 75.8981 65.9685 75.7925 65.7855 75.7228C65.6025 75.6531 65.4074 75.6208 65.2117 75.6278C65.0161 75.6208 64.821 75.6531 64.638 75.7228C64.455 75.7925 64.2878 75.8981 64.1463 76.0334C64.0048 76.1688 63.8919 76.3311 63.8142 76.5108C63.7364 76.6905 63.6955 76.884 63.6937 77.0798C63.6952 77.2757 63.736 77.4694 63.8136 77.6493C63.8912 77.8293 64.0041 77.9918 64.1456 78.1274C64.2871 78.2629 64.4544 78.3687 64.6375 78.4385C64.8206 78.5083 65.0159 78.5407 65.2117 78.5338Z"
      fill="white"
    />
    <g filter="url(#filter2_d_137_14012)">
      <path
        d="M11 96H146C147.326 96 148.598 96.5268 149.536 97.4645C150.473 98.4021 151 99.6739 151 101V126C151 127.326 150.473 128.598 149.536 129.536C148.598 130.473 147.326 131 146 131H11C9.67392 131 8.40215 130.473 7.46447 129.536C6.52678 128.598 6 127.326 6 126V101C6 99.6739 6.52678 98.4021 7.46447 97.4645C8.40215 96.5268 9.67392 96 11 96V96Z"
        fill="white"
      />
    </g>
    <path
      d="M75 104H49C47.3431 104 46 105.343 46 107C46 108.657 47.3431 110 49 110H75C76.6569 110 78 108.657 78 107C78 105.343 76.6569 104 75 104Z"
      fill="#E9E9E9"
    />
    <path
      d="M93 117H49C47.3431 117 46 118.343 46 120C46 121.657 47.3431 123 49 123H93C94.6569 123 96 121.657 96 120C96 118.343 94.6569 117 93 117Z"
      fill="#E9E9E9"
    />
    <path
      d="M33 99H14C11.2386 99 9 101.239 9 104V123C9 125.761 11.2386 128 14 128H33C35.7614 128 38 125.761 38 123V104C38 101.239 35.7614 99 33 99Z"
      fill="#739B67"
    />
    <path
      d="M21.2217 116.78H23.2217L23.2437 116.01C23.2437 114.998 23.8817 114.734 24.7837 114.448C25.6885 114.19 26.4849 113.645 27.0525 112.895C27.6201 112.145 27.928 111.23 27.9297 110.29C27.9495 109.715 27.8499 109.143 27.6371 108.609C27.4244 108.076 27.1031 107.592 26.6936 107.188C26.2842 106.785 25.7954 106.471 25.2584 106.267C24.7214 106.062 24.1477 105.971 23.5737 106C22.9671 105.987 22.3642 106.097 21.8014 106.323C21.2387 106.55 20.7279 106.889 20.3 107.319C19.8721 107.749 19.536 108.262 19.3122 108.826C19.0883 109.39 18.9813 109.993 18.9977 110.6H21.0657C21.0579 110.265 21.1169 109.932 21.2395 109.62C21.362 109.308 21.5455 109.024 21.7793 108.784C22.0131 108.544 22.2924 108.353 22.6009 108.223C22.9093 108.092 23.2408 108.025 23.5757 108.024C23.8778 108.006 24.1801 108.054 24.4621 108.164C24.7441 108.273 24.9992 108.443 25.21 108.66C25.4208 108.877 25.5824 109.137 25.6838 109.422C25.7852 109.707 25.8241 110.01 25.7977 110.312C25.7844 110.8 25.6147 111.27 25.3137 111.655C25.0127 112.039 24.5963 112.316 24.1257 112.446L23.3997 112.734C22.6889 112.905 22.0684 113.337 21.6609 113.944C21.2535 114.551 21.0889 115.289 21.1997 116.012L21.2217 116.78ZM22.2117 121.534C22.4076 121.541 22.6028 121.508 22.7859 121.439C22.9691 121.369 23.1363 121.263 23.2778 121.127C23.4194 120.992 23.5323 120.829 23.6099 120.649C23.6875 120.469 23.7282 120.276 23.7297 120.08C23.728 119.884 23.687 119.691 23.6093 119.511C23.5316 119.331 23.4186 119.169 23.2771 119.033C23.1356 118.898 22.9685 118.792 22.7855 118.723C22.6025 118.653 22.4074 118.621 22.2117 118.628C22.0161 118.621 21.821 118.653 21.638 118.723C21.455 118.792 21.2878 118.898 21.1463 119.033C21.0048 119.169 20.8919 119.331 20.8142 119.511C20.7364 119.691 20.6955 119.884 20.6937 120.08C20.6952 120.276 20.736 120.469 20.8136 120.649C20.8912 120.829 21.0041 120.992 21.1456 121.127C21.2871 121.263 21.4544 121.369 21.6375 121.439C21.8206 121.508 22.0159 121.541 22.2117 121.534Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_d_137_14012"
        x="0"
        y="7"
        width="157"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_137_14012"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_137_14012"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_137_14012"
        x="43"
        y="50"
        width="157"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_137_14012"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_137_14012"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_d_137_14012"
        x="0"
        y="93"
        width="157"
        height="47"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_137_14012"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_137_14012"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
