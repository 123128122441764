import { useIsMobile } from "hooks/useIsMobile";
import { Registration } from "organisms/Registration/Registration";

export function SignUp() {
  const { isMobile } = useIsMobile();

  return (
    <div
      style={{
        paddingTop: isMobile ? "0px" : "150px",
        paddingBottom: isMobile ? "0px" : "150px",
      }}
    >
      <Registration />
    </div>
  );
}
