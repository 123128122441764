import React, { forwardRef, ReactNode, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { IconSearch } from "atoms/IconSearch/IconSearch";

interface SearchInputProps {
  value: string;
  isClear?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  endAdornmentIcon?: ReactNode;
  onEndAdornmentClick?: () => void;
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      value,
      onChange,
      placeholder = "",
      endAdornmentIcon = <IconSearch color="#739B67" />,
      onEndAdornmentClick,
      isClear,
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <TextField
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        sx={{
          overflow: "hidden",
          ".MuiInputBase-input::placeholder": {
            color: "var(--main-color-notification-description)",
            lineHeight: "20px",
          },
          ".MuiInputBase-input": { borderRadius: 0, height: "20px" },
          ".MuiInputBase-root": {
            py: 14,
            px: 16,
            transition: "padding-left 0.3s",
            pl: isFocused || value ? 16 : 48,
            borderRadius: 16,
            position: "relative",
          },
          ".MuiInputBase-root:after": {
            display: "none",
          },
          ".MuiInputBase-root:before": {
            display: "none",
          },
        }}
        inputProps={{
          sx: {
            py: 0,
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transition: "transform 0.3s",
                transform: `translateX(${
                  isFocused || value ? "-100px" : "16px"
                })`,
                margin: "0 !important",
              }}
              position="start"
            >
              <IconSearch />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              sx={{
                position: "absolute",
                right: 0,
                transition: "transform 0.3s",
                transform: `translateX(${
                  isFocused || value ? "-16px" : "50px"
                })`,
              }}
              position="end"
            >
              <span
                onClick={onEndAdornmentClick}
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {endAdornmentIcon}
              </span>
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
        variant="filled"
        fullWidth
        value={isClear ? "" : value}
        onChange={onChange}
        inputRef={ref}
      />
    );
  }
);

SearchInput.displayName = "SearchInput";
