import { useEffect, useRef, FC, PropsWithChildren, RefObject } from "react";
import { Box } from "@mui/material";
import { useSelector } from "store";
import { makeStyles } from "@mui/styles";
import { Loader } from "components/Loader/Loader";

function useRefVariable<T extends any>(value: T) {
  const ref = useRef<any>();
  ref.current = value;
  return ref;
}

type InfiniteScrollProps = PropsWithChildren<{
  onLoadMore?: () => void;
  isLoading?: boolean;
  reverse?: boolean;
  withScrollTrack?: boolean;
  cssVieportHeight?: string;
  lastBlockRef: RefObject<HTMLDivElement>;
  rootSentinelRef: RefObject<HTMLDivElement>;
  onScroll?: () => void;
  overflow?: string;
}>;

const useStyles = makeStyles((theme) => ({
  scrollWithTrack: {
    "&::-webkit-scrollbar": {
      display: "block",
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 20,
      background: "var(--main-color-text-secondary-unactive)",
      border: "1px solid var(--main-color-text-secondary-unactive)",
    },
    "&::-webkit-scrollbar-track": {
      background: "var(--main-color-bg-widgets)",
    },
  },
}));

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
  children,
  onLoadMore,
  isLoading,
  lastBlockRef,
  rootSentinelRef,
  reverse,
  withScrollTrack,
  onScroll,
  cssVieportHeight = "100%",
  overflow = "auto",
}) => {
  const { paginationPage, chatMessagesState } = useSelector(
    (state) => state.chat
  );
  const targetSentinelRef = useRef<HTMLElement>(null);
  const onLoadMoreRef = useRefVariable(onLoadMore);
  const classes = useStyles();

  useEffect(() => {
    !reverse && lastBlockRef.current?.scrollIntoView(true);
  }, [lastBlockRef, reverse]);

  useEffect(() => {
    const rootSentinel = rootSentinelRef.current;
    const targetSentinel: HTMLElement | null = targetSentinelRef.current;
    const lastBlock: HTMLElement | null = lastBlockRef.current;
    const anchor = reverse ? lastBlock : targetSentinel;
    const observer = new IntersectionObserver(
      async ([entry], observer) => {
        if (entry.isIntersecting) {
          onLoadMoreRef.current(entry, observer);
        }
      },
      {
        root: rootSentinel,
        rootMargin: "1px",
        threshold: 1,
      }
    );
    if (anchor) observer.observe(anchor);
    return () => {
      if (anchor) observer.unobserve(anchor);
    };
  }, [
    chatMessagesState,
    lastBlockRef,
    onLoadMoreRef,
    paginationPage,
    rootSentinelRef,
    reverse,
  ]);

  return (
    <Box
      ref={rootSentinelRef}
      sx={{
        overflow: overflow,
        height: cssVieportHeight,
        position: "relative",
      }}
      display="flex"
      flexDirection="column"
      className={withScrollTrack ? classes.scrollWithTrack : undefined}
      onScroll={() => (onScroll ? onScroll() : {})}
    >
      {isLoading ? (
        <Box
          ref={targetSentinelRef}
          width="100%"
          height={15}
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ position: "absolute", top: 30, zIndex: 100 }}
        >
          <Loader size={15} />
        </Box>
      ) : (
        <Box height={reverse ? 0 : 15} ref={targetSentinelRef} />
      )}
      {children}
      <Box ref={lastBlockRef} />
    </Box>
  );
};
