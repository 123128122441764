import { Box } from "@mui/material";
import { CardLimitConfirmResponse } from "api/account";
import { IconSuccess } from "atoms/IconSuccess";
import { NotificationResult } from "molecules/NotificationResult/NotificationResult";

type Props = {
  onClick: () => void;
  limitItem?: CardLimitConfirmResponse;
};

export const Success = ({ onClick, limitItem }: Props) => {
  const renderTitle = () => {
    switch (limitItem?.limitId) {
      case 3051:
        return "Суточный лимит на получение наличных изменён";
      case 3053:
        return "Общий суточный лимит всех расходных операций по карте изменён";
      case 3054:
        return "Суточный лимит на операции в интернете и сервисе «Лучи» изменён";
      default:
        return "";
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box width="100%" mt="32px" height="100%">
        <NotificationResult
          title={renderTitle()}
          titleSize="text_7"
          subtitleSize="text_5"
          icon={<IconSuccess />}
          height="100%"
          titleStyle={{
            width: "80%",
            display: "flex",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "-40px",
            lineHeight: "36px",
          }}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              onClick,
            },
          ]}
        />
      </Box>
    </Box>
  );
};
