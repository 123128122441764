import { Box, Grid, Link, Typography } from "@mui/material";
import { IconPhoneHollow } from "atoms/IconPhoneHollow/IconPhoneHollow";
import { IconUnSuccess } from "atoms/IconUnSuccess";
import { useIsMobile } from "hooks/useIsMobile";
import { authActions } from "store/slices/auth";
import { useDispatch, useSelector } from "store";
import { NewNotificationResult } from "molecules/NewNotificationResult/NewNotificationResult";

export const BlockedScreen = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const { error } = useSelector((state) => state.auth);

  const title = error?.title ?? "Учётная запись заблокирована";
  const subtitle = error?.subtitle ?? "Обратитесь в службу поддержки банка";

  const handleClick = () => {
    dispatch(authActions.setAccountIsBlocked(false));
    dispatch(authActions.resetError());
  };

  return (
    <Grid
      flexGrow="1"
      display="flex"
      component="main"
      container
      px={{ xs: 16, xl: 86 }}
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Box p={{ xs: "104px 0 64px", md: "40px 30.5px 140px" }} component="div">
        <NewNotificationResult
          pageTitle={title}
          title={subtitle}
          subtitle={
            <Box sx={{ maxWidth: "675px" }} mb={{ md: 40 }}>
              <Typography
                variant="text_5"
                color="gray.b500"
                fontWeight={400}
                display="flex"
                alignItems="center"
                sx={{ columnGap: "8px", marginTop: isMobile ? "24px" : "20px" }}
              >
                <IconPhoneHollow /> Служба техподдержки
              </Typography>
              <Link
                href="tel:+ 7 (800) 200-45-67"
                variant="text_5"
                color="#739b67"
                fontWeight={400}
                component="a"
                onClick={handleClick}
                underline="none"
                sx={{
                  marginTop: "8px",
                  display: "block",
                }}
              >
                + 7 (800) 200-45-67
              </Link>
            </Box>
          }
          icon={<IconUnSuccess />}
        />
      </Box>
    </Grid>
  );
};
