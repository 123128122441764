import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { formatAmount } from "utils/formatCurrency";
import { setIsCardInfoOpen } from "store/slices/cardsSlice";
import { CARD_STATUS } from "components/CardsInfo/model";
import { CardDto } from "api/account";
import { getBackgroundColor, getCardIcon } from "utils/card";
import { useDispatch } from "store";
import { IconWrapper } from "components/IconWrapper/IconWrapper";

const CardInfoItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "status",
})<{ status: string }>(({ status }) => ({
  display: "flex",
  columnGap: "12px",
  alignItems: "center",
  padding: "12px",
  cursor: "pointer",
  borderRadius: "15px",
  backgroundColor: getBackgroundColor(status),
}));

interface CardInfoItemProps {
  cardInfo: CardDto;
  index: number;
  customSelect?: boolean;
  showCards?: boolean;
  setShowCards?: () => void;
  setActiveCard?: () => void;
  hideRightArrow?: boolean;
  activeCard?: boolean;
}

export const CardInfoItem = ({
  cardInfo,
  index,
  customSelect,
  setShowCards,
  setActiveCard,
}: CardInfoItemProps) => {
  const { cardPan, amount, currency, status } = cardInfo;
  const dispatch = useDispatch();

  const shortCardPan = cardPan?.slice(-4);
  const cardAmount = formatAmount(amount, currency?.sign);

  const cardClick = () => {
    if (setActiveCard) setActiveCard();
    if (customSelect && setShowCards) {
      setShowCards();
    } else {
      dispatch(setIsCardInfoOpen(index));
    }
  };

  const isBlockedCard = CARD_STATUS.Blocked.includes(status as string);

  return (
    <CardInfoItemContainer onClick={cardClick} status={status || ""}>
      <Box sx={{ display: "flex", position: "relative" }}>
        <IconWrapper
          bgColor={isBlockedCard ? "var(--error-color-notification)" : ""}
        >
          {getCardIcon(cardInfo)}
        </IconWrapper>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          overflow: "hidden",
          opacity: ![
            "ACTIVE",
            "VIP",
            "DECLARED",
            "RESTRICTED",
            "OPEN_DOMESTIC",
            "EXPIRED",
          ].includes(status as string)
            ? 0.5
            : 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "2px" }}>
          <Box
            sx={{
              width: "6px",
              height: "6px",
              borderRadius: "100px",
              backgroundColor: "var(--main-color-text-title)",
            }}
          />
          <Box
            sx={{
              width: "6px",
              height: "6px",
              borderRadius: "100px",
              backgroundColor: "var(--main-color-text-title)",
            }}
          />
          <Typography
            noWrap
            sx={{
              color: "var(--main-color-text-title)",
              fontWeight: 500,
              fontSize: "18px",
              marginLeft: "3px",
            }}
          >
            {shortCardPan}
          </Typography>
        </Box>
        <Typography
          noWrap
          sx={{
            color: "var(--main-color-text-title)",
            fontWeight: 500,
            fontSize: "18px",
          }}
        >
          {cardAmount}
        </Typography>
      </Box>
    </CardInfoItemContainer>
  );
};
