import { useState, useCallback, useEffect } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  ChatAttachment,
  ChatMessage,
  ChatMessageTypeEnum,
} from "api/notification";
import { useDispatch } from "store";
import { loadChatAttachmentV2 } from "store/slices/chat";
import { getDateWithTimezone } from "store/slices/chat/utils";
import theme from "theme";
import {
  StatusIconView,
  StatusIconSent,
  StatusIconError,
} from "widgets/ChatWidget/assets";
import { FilesMessages } from "../FilesMessages/FilesMessages";
import { OperatorIcon } from "../OperatorIcon/OperatorIcon";
import { TextMessage } from "../TextMessage/TextMessage";
import { ReplyMessage } from "../ReplyMessage/ReplyMessage";
import { useLongPress } from "hooks/useLongPress";
import { downloadChatFile } from "utils/downloadChatFile";

type MessageItemProps = {
  message: ChatMessage & { isError?: boolean };
  isWindow: boolean;
  onMsgSelect: () => void;
  selectedMsg: ChatMessage | null;
  useCompactPadding?: boolean;
  showIcon?: boolean;
  onReplyMessageClick?: (messageId: string) => void;
};

export const MessageItem = ({
  message,
  isWindow,
  useCompactPadding,
  onMsgSelect,
  selectedMsg,
  onReplyMessageClick,
  showIcon = false,
}: MessageItemProps) => {
  const dispatch = useDispatch();
  const {
    text,
    timestamp,
    user,
    read,
    attachments,
    isError,
    type,
    replyMessage,
  } = message;
  const [files, setFiles] = useState<ChatAttachment[]>([]);

  const backspaceLongPress = useLongPress(onMsgSelect, 500);

  // считает количество строк в тексте
  const textLineCount = text ? text.split("\n").length : 1;

  const handleFileDownload = useCallback(
    (attachment: ChatAttachment) => () => {
      dispatch(loadChatAttachmentV2(attachment.id || ""))
        .unwrap()
        .then((res) => {
          downloadChatFile(res, attachment.filename, attachment.mime);
        })
        .catch();
    },
    [dispatch]
  );

  const handleReplyMessageClick = () => {
    if (replyMessage?.id) {
      onReplyMessageClick?.(replyMessage.id);
    }
  };

  const hasRead = read ? <StatusIconView /> : <StatusIconSent />;
  const statusIcon = isError ? <StatusIconError /> : hasRead;
  const isMsgRead = !read && !user;

  useEffect(() => {
    setFiles(attachments || []);
  }, [attachments]);

  if (
    (!text && attachments?.length === 0) ||
    type !== ChatMessageTypeEnum.Regular
  )
    return null;

  return (
    <Box
      sx={{
        backgroundColor: isMsgRead ? "rgba(120, 173, 105, 0.15)" : "",
        userSelect: "none",
        "-webkit-tap-highlight-color": "transparent",
      }}
    >
      {isMsgRead && (
        <Typography
          sx={{
            color: "#B5B5B5",
            fontSize: "12px",
            fontWeight: 400,
            lineHeight: "14px",
            textAlign: "center",
            py: "4px",
          }}
        >
          непрочитанное
        </Typography>
      )}
      <Box
        justifyContent={user ? "flex-end" : "flex-start"}
        display="flex"
        gap={8}
        marginBottom={12}
        {...(useCompactPadding && {
          padding: { xs: "0 16px", lg: isWindow ? "0 16px" : "0 32px" },
        })}
      >
        {!user && showIcon && <OperatorIcon />}
        <Box
          display={user ? "flex" : "block"}
          alignItems={user ? "flex-end" : "initial"}
          data-id={`message-box-${message.id}`}
        >
          {!user && (
            <Typography
              mb={4}
              color={theme.palette.blue.b400}
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                lineHeight: "16px",
                letterSpacing: "0.036px",
                marginLeft: "24px",
              }}
            >
              {message?.operatorName}
            </Typography>
          )}

          <Box
            sx={{
              backgroundColor: !user
                ? "#E3EADB"
                : "var(--main-color-bg-secondary)",
              borderRadius: "20px",
              padding: "8px 16px 12px 16px",
              cursor: "pointer",
              position: "relative",
              zIndex: selectedMsg?.id === message.id ? 199 : 0,
            }}
            {...backspaceLongPress}
            maxWidth={282}
          >
            {replyMessage && (
              <Box onClick={handleReplyMessageClick}>
                <ReplyMessage
                  isClientMessage={replyMessage?.isClientMessage}
                  text={replyMessage?.text}
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: textLineCount > 1 ? "flex-end" : "center",
              }}
              gap={8}
              mr={4}
            >
              {/* text and files */}
              <Box
                gap={text && files.length > 0 ? 8 : 0}
                display="flex"
                flexDirection="column"
                id={message.id}
              >
                {text && <TextMessage text={text} user={user} />}

                <FilesMessages files={files} onClick={handleFileDownload} />
              </Box>

              {/* time & status */}
              <Box
                display="flex"
                alignItems="center"
                height="fit-content"
                gap={4}
                alignSelf={files.length > 0 ? "flex-end" : "center"}
              >
                <Typography
                  display="flex"
                  alignSelf="flex-end"
                  typography="text_0"
                  fontWeight={600}
                  color={theme.primary.main.gray["300"]}
                >
                  {getDateWithTimezone(timestamp)}
                </Typography>

                {user && (
                  <Box display="flex" alignItems="flex-end">
                    {statusIcon}
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
