import {
  Container,
  InfoBlock,
  InfoBlockDescription,
  InfoBlockTitle,
  InfoContainer,
  Subtitle,
  SuccessAmount,
  SuccessBlock,
  SuccessInfo,
  Title,
  Wrapper,
} from "./components";
import { OrderInfo, OrderStatus } from "api/account";
import { useIsMobile } from "hooks/useIsMobile";
import { IconErrorMobile } from "atoms/IconErrorMobile/IconErrorMobile";
import { IconSuccessDesktop } from "atoms/IconSuccessDesktop/IconSuccessDesktop";
import { ReactComponent as BlockedCardIcon } from "./assets/card-error.svg";
import { Box, Stack, Typography } from "@mui/material";
import { IconCardMini } from "atoms/IconCardMini/IconCardMini";
import { IconSuccessVirtual } from "atoms/IconSuccessVirtual";
import { getFormattedDate } from "utils/getFormattedDate";
import theme from "theme";

interface RefillInfoCardProps {
  refill: OrderInfo | null;
}

const createAmount = (amount: string) => {
  const amountArr = amount.split("");
  amountArr.splice(amountArr.length - 2, 0, ",");
  return amountArr.join("");
};

export const RefillInfoCard = ({ refill }: RefillInfoCardProps) => {
  const { isMobile } = useIsMobile();
  const isSuccess = refill?.status === OrderStatus.Approved;
  const isCanceled = refill?.status === OrderStatus.Canceled;
  const isFailed = !(isSuccess || isCanceled);

  const errorIcon = isMobile ? <IconErrorMobile /> : <BlockedCardIcon />;

  const amount = refill?.amount ? createAmount(refill.amount) : "";
  const hideAmount = !amount || amount === "0" || amount === ",0";

  const date = getFormattedDate(refill?.createDate);

  const errorTitle = isCanceled ? "Оплата отменена" : "Операция отклонена";

  const successBlock = isMobile ? (
    <Stack alignItems="center" spacing={40}>
      <Typography
        sx={{
          fontSize: 24,
          lineHeight: "32px",
          fontWeight: 500,
          color: "#454A3F",
        }}
      >
        Карта успешно пополнена
      </Typography>

      <IconSuccessDesktop />
    </Stack>
  ) : (
    <SuccessBlock>
      <IconSuccessVirtual />
      <SuccessAmount>+{amount} ₽</SuccessAmount>
      <Stack spacing={8} direction="row" alignItems="center">
        <IconCardMini />
        <SuccessInfo>Пополнение карты</SuccessInfo>
      </Stack>
    </SuccessBlock>
  );

  return (
    <Wrapper>
      <Container>
        {isSuccess ? (
          successBlock
        ) : (
          <Box
            sx={{
              mb: 10,
              mt: 42,
              order: 0,
              [theme.breakpoints.down("md")]: { mb: 0, order: 1 },
            }}
          >
            {errorIcon}
          </Box>
        )}
        {!isSuccess && <Title>{`Ошибка. ${errorTitle}`}</Title>}
        {isFailed && !isMobile && (
          <Subtitle>
            Мы уже работаем над этой проблемой. Попробуйте позже.
          </Subtitle>
        )}
        <InfoContainer>
          {!hideAmount && (
            <InfoBlock>
              <InfoBlockTitle>Сумма</InfoBlockTitle>
              <InfoBlockDescription>{amount} ₽</InfoBlockDescription>
            </InfoBlock>
          )}
          <InfoBlock>
            <InfoBlockTitle>Дата и время</InfoBlockTitle>
            <InfoBlockDescription>{date}</InfoBlockDescription>
          </InfoBlock>
          <InfoBlock>
            <InfoBlockTitle>ID сессии</InfoBlockTitle>
            <InfoBlockDescription>{refill?.sessionId}</InfoBlockDescription>
          </InfoBlock>
          <InfoBlock>
            <InfoBlockTitle>ID заказа</InfoBlockTitle>
            <InfoBlockDescription>{refill?.id}</InfoBlockDescription>
          </InfoBlock>
        </InfoContainer>
      </Container>
    </Wrapper>
  );
};
