import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { ReactNode } from "react";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { systemActions } from "store/slices/system";
import { MobileTabVariants, mobileTabLabels } from "store/slices/system/slice";
import { IconOtherTab } from "./components/icons/IconOtherTab";
import { MobileTabItem } from "./components/MobileTabItem";
import { useDispatch } from "store";
import { IconMainTab } from "./components/icons/IconMainTab";
import { IconTransfersTab } from "./components/icons/IconTransfersTab";
import { IconChatTab } from "./components/icons/IconChatTab";
import { IconHistoryTab } from "./components/icons/IconHistoryTab";

type TabInfoItem = {
  id: MobileTabVariants;
  title: string;
  getIcon: (color: string) => ReactNode;
};

const TABS_INFO_LIST = [
  {
    id: MobileTabVariants.main,
    title: mobileTabLabels[MobileTabVariants.main],
    getIcon: (color: string) => <IconMainTab color={color} />,
  },
  {
    id: MobileTabVariants.transfersHub,
    title: mobileTabLabels[MobileTabVariants.transfersHub],
    getIcon: (color: string) => <IconTransfersTab color={color} />,
  },
  {
    id: MobileTabVariants.chat,
    title: mobileTabLabels[MobileTabVariants.chat],
    getIcon: (color: string, isUnread: boolean) => (
      <IconChatTab color={color} isUnread={isUnread} />
    ),
  },
  {
    id: MobileTabVariants.history,
    title: mobileTabLabels[MobileTabVariants.history],
    getIcon: (color: string) => <IconHistoryTab color={color} />,
  },
  {
    id: MobileTabVariants.other,
    title: mobileTabLabels[MobileTabVariants.other],
    getIcon: (color: string) => <IconOtherTab color={color} />,
  },
];

const MobileTabsContainer = styled(Box)({
  boxSizing: "border-box",
  display: "grid",
  gridAutoColumns: "1fr",
  gridAutoFlow: "column",
  justifyContent: "space-around",
  paddingTop: 12,
  paddingBottom: 20,
  position: "fixed",
  bottom: 0,
  width: "100%",
  zIndex: 1,
  backgroundColor: "#FFFFFF",

  borderЕop: "2px solid #FFF",
  background: "rgba(255, 255, 255, 0.70)",
  backdropFilter: "blur(13px)",
});

export const MobileTabs: React.FC = () => {
  const dispatch = useDispatch();

  const switchToTab = (tab: MobileTabVariants) => {
    if (tab === MobileTabVariants.history) {
      dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
    }
    if (tab === MobileTabVariants.main) {
      dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
    }
    dispatch(systemActions.setActiveTab({ tab }));
  };

  return (
    <MobileTabsContainer>
      {TABS_INFO_LIST.map((tab) => (
        <MobileTabItem
          tabInfo={tab as TabInfoItem}
          key={tab.id}
          onClick={() => switchToTab(tab.id)}
        />
      ))}
    </MobileTabsContainer>
  );
};
