import { useState } from "react";
import { Box } from "@mui/material";
import { authActions, registerCVV } from "store/slices/auth";
import { useDispatch, useSelector } from "../../../store";
import { BackButtonArrow } from "components/BackButtonArrow";
import { CodeInput } from "components/CodeInput";
import { Button } from "components/Button";
import card from "./assets/card.png";
import styles from "./style.module.css";

type Screen = "main" | "hint";

export const ThirdScreen = () => {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [screen, setScreen] = useState<Screen>("main");
  const { error, isLoading } = useSelector((state) => state.auth);

  const handleClick = () => {
    dispatch(registerCVV(code));
  };

  return (
    <div className={styles.container}>
      <div className={styles.backBtn}>
        <BackButtonArrow
          onClick={() => dispatch(authActions.setRegisterScreen("initial"))}
        />
      </div>
      {screen === "main" ? (
        <>
          <div className={styles.titleBlock}>
            <p className={styles.title}>CVV2-код</p>
          </div>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mt={30}
          >
            {error?.parameter && !isLoading && (
              <p className={styles.error}>
                Ошибка: Неверный код. Попробуйте снова, <br />
                осталось попыток: {error?.parameter}
              </p>
            )}
            <CodeInput
              length={3}
              secretDelay={100}
              secret
              setCode={setCode}
              error={Boolean(error)}
              clearError={() => dispatch(authActions.resetError())}
            />
            <Button
              variant="primary"
              disabled={code.length < 3}
              onClick={handleClick}
              isLoading={isLoading}
              style={{ marginTop: 58 }}
              title="Продолжить"
            />
            <Button
              variant="secondary"
              style={{ marginTop: "10px" }}
              onClick={() => setScreen("hint")}
              title="Где найти CVV2-код?"
            />
          </Box>
        </>
      ) : (
        <>
          <div className={styles.titleBlock}>
            <p className={styles.title}>Где найти CVV2-код?</p>
            <p className={styles.description}>
              CVV2 — это трехзначное число
              <br />
              на обратной стороне вашей карты.
            </p>
            <img src={card} className={styles.card} />
          </div>
          <Button
            variant="primary"
            onClick={() => setScreen("main")}
            style={{ marginTop: 40 }}
            title="Понятно"
          />
        </>
      )}
    </div>
  );
};
