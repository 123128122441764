import styled from "@emotion/styled/macro";

export const Container = styled.div`
  padding: 16px;
  border-radius: 0 0 8px 8px;
  border: none;
  background-color: var(--warning-color-secondary-light);
`;

export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
`;

export const Flex = styled.div`
  display: flex;
`;
export const Xbtn = styled.button`
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Title = styled.p`
  margin: 0px;
  margin-left: 8px;

  color: var(--warning-color-primary);

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  @media (max-width: 550px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Description = styled.p`
  margin: 4px 20px 0 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);

  @media (max-width: 550px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
