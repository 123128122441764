export const IconBigKey = ({ ...props }) => (
  <svg
    width={props.width || 56}
    height={props.height || 56}
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="16.375"
      y="25.5625"
      width="23.25"
      height="15.375"
      rx="2.5"
      stroke={props.color || "white"}
      strokeWidth="3"
    />
    <path
      d="M21.4375 25.375V20.125C21.4375 18.3845 22.1289 16.7153 23.3596 15.4846C24.5903 14.2539 26.2595 13.5625 28 13.5625C29.7405 13.5625 31.4097 14.2539 32.6404 15.4846C33.8711 16.7153 34.5625 18.3845 34.5625 20.125V25.375"
      stroke={props.color || "white"}
      strokeWidth="3"
    />
  </svg>
);
