export const IconLuchi = (props) => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <ellipse cx="18.9861" cy="19" rx="18.9861" ry="19" fill="#739B67" />
    <g opacity="0.8">
      <mask
        id="mask0_424_2332"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="39"
        height="38"
      >
        <ellipse cx="19.0153" cy="19" rx="18.9861" ry="19" fill="#D9E3CC" />
      </mask>
      <g mask="url(#mask0_424_2332)">
        <g filter="url(#filter0_f_424_2332)">
          <path
            d="M18.503 -6.5159L-1.255 29.0348L0.38163 30.2995L28.266 -2.23942L18.503 -6.5159Z"
            fill="url(#paint0_linear_424_2332)"
          />
        </g>
        <g filter="url(#filter1_f_424_2332)">
          <path
            d="M35.8967 -2.52477L0.804602 30.398L2.6925 33.466L42.8418 5.88883L35.8967 -2.52477Z"
            fill="url(#paint1_linear_424_2332)"
          />
        </g>
        <g filter="url(#filter2_f_424_2332)">
          <path
            d="M44.4731 9.91336L3.08234 34.4382L4.26057 37.8428L49.4299 19.6338L44.4731 9.91336Z"
            fill="url(#paint2_linear_424_2332)"
          />
        </g>
      </g>
    </g>
    <path
      d="M19.1611 31.1049C25.7533 31.1049 31.0977 25.6849 31.0977 19.0003C31.0977 12.3157 25.7533 6.89575 19.1611 6.89575C12.569 6.89575 7.22461 12.3157 7.22461 19.0003C7.22461 25.6849 12.569 31.1049 19.1611 31.1049Z"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <path
      d="M8.7207 13.1333H29.6033"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <path
      d="M19.1621 6.896V31.1051"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <path
      d="M7.22461 19.3782H31.0961"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <path
      d="M9.16992 25.623H29.1533"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <path
      d="M19.2974 31.1051C15.7705 31.1051 12.9121 25.6851 12.9121 19.0006C12.9121 12.316 15.7705 6.896 19.2974 6.896"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <path
      d="M19.1621 31.1051C22.689 31.1051 25.5474 25.6851 25.5474 19.0006C25.5474 12.316 22.689 6.896 19.1621 6.896"
      stroke="#D9E3CC"
      strokeWidth="0.503655"
      strokeMiterlimit="10"
    />
    <defs>
      <filter
        id="filter0_f_424_2332"
        x="-1.51088"
        y="-6.77187"
        width="30.0325"
        height="37.3274"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.127999"
          result="effect1_foregroundBlur_424_2332"
        />
      </filter>
      <filter
        id="filter1_f_424_2332"
        x="0.548689"
        y="-2.78066"
        width="42.5491"
        height="36.5027"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.127999"
          result="effect1_foregroundBlur_424_2332"
        />
      </filter>
      <filter
        id="filter2_f_424_2332"
        x="2.82603"
        y="9.65733"
        width="46.8597"
        height="28.4414"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="0.127999"
          result="effect1_foregroundBlur_424_2332"
        />
      </filter>
      <linearGradient
        id="paint0_linear_424_2332"
        x1="22.1804"
        y1="-5.14482"
        x2="0.628165"
        y2="29.5131"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E3CC" stopOpacity="0" />
        <stop offset="0.875587" stopColor="#D9E3CC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_424_2332"
        x1="38.1262"
        y1="9.03948"
        x2="3.3577"
        y2="30.3871"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E3CC" stopOpacity="0" />
        <stop offset="0.875587" stopColor="#D9E3CC" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_424_2332"
        x1="44.1439"
        y1="21.6863"
        x2="5.57976"
        y2="34.9893"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9E3CC" stopOpacity="0" />
        <stop offset="0.875587" stopColor="#D9E3CC" />
      </linearGradient>
    </defs>
  </svg>
);
