import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ChatAttachment, ChatAttachmentDto } from "api/notification";
import { IconDocument } from "atoms/IconDocument/IconDocument";
import { isImage } from "utils/isImage";
import theme from "theme";

type FilesMessagesProps = {
  files: ChatAttachment[];
  onClick: (file: ChatAttachment) => () => void;
};

export const FilesMessages = ({ files, onClick }: FilesMessagesProps) => {
  if (!files.length) return null;

  return (
    <Box>
      {files.map((file) => {
        const isFileImage = isImage(file.mime);

        return (
          <Box
            sx={{ cursor: "pointer" }}
            onClick={onClick(file)}
            key={file.id}
            display="flex"
            alignItems="center"
            gap={6}
          >
            {isFileImage ? (
              <Box height={117}>
                <img
                  src={`${process.env.REACT_APP_BASE_PATH}notification${file.url}`}
                  loading="lazy"
                  style={{ height: "100%", pointerEvents: "none" }}
                />
              </Box>
            ) : (
              <IconDocument />
            )}
            {!isFileImage && (
              <Typography
                typography="text_5"
                color={theme.primary.brand.blue[400]}
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: 150,
                }}
              >
                {file.filename}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
