import styled from "@emotion/styled/macro";
import { Box } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { useDispatch, useSelector } from "store";
import { TabVariants, systemActions } from "store/slices/system";
import {
  TRANSFER_ERROR_CODE,
  resetTransferErrorMessage,
  setIsOpenModal,
} from "store/slices/transferSlice";
import { ClickableHeader } from "./ClickableHeader";
import { Button } from "components/Button";
import { IconWarning } from "atoms/IconWarning";

const Title = styled.p`
  margin: 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: var(--main-color-text-title);
  margin-top: 22px;
  @media (max-width: 500px) {
  }
`;

const Subtitle = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--main-color-text-title);
  margin-top: 8px;
  text-align: center;
  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const TransferError = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const {
    transfer: {
      errorMessage: { title, subtitle, code },
      isOpenModal,
    },
  } = useSelector((state) => state);

  const isPaymentError = code === TRANSFER_ERROR_CODE.PAYMENT_ERROR;
  const isOtpError = code === TRANSFER_ERROR_CODE.OTP_BLOCKED;

  const clearError = () => {
    if (isOpenModal) {
      setTimeout(() => {
        dispatch(resetTransferErrorMessage());
      }, 200);
    }
  };

  const submitButtonHandler = () => {
    clearError();
    dispatch(setIsOpenModal(!isOpenModal));
    if (!isPaymentError) {
      dispatch(systemActions.setActiveTab({ tab: TabVariants.history }));
    }
  };

  const backButtonHandler = () => {
    clearError();
    dispatch(setIsOpenModal(!isOpenModal));
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
    >
      <Box
        mt={isMobile ? "0" : "24px"}
        mb="4px"
        height="100%"
        position="relative"
      >
        <Box>
          <ClickableHeader onBack={backButtonHandler} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "60px",
            }}
          >
            <IconWarning />
          </Box>

          <Title>{title || "Возникла ошибка во время перевода"}</Title>
          <Subtitle>
            {subtitle ||
              "Проверьте, есть ли перевод на странице “история операций”, если перевода нет - повторите операцию."}
          </Subtitle>
        </Box>
        {!isOtpError && (
          <Button
            variant="primary"
            onClick={submitButtonHandler}
            style={{
              marginTop: "40px",
            }}
            title={isPaymentError ? "Понятно" : "Перейти к истории"}
          />
        )}
      </Box>
    </Box>
  );
};
