import {
  IconBoxChecked,
  IconBoxUnchecked,
} from "atoms/NewIconCheckbox/IconCheckBox";

export const Checkbox = ({ isToggle, onToggle }) => (
  <div onClick={onToggle} style={{ display: "flex", alignItems: "center" }}>
    {isToggle ? <IconBoxChecked /> : <IconBoxUnchecked />}
  </div>
);
