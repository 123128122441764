import styled from "@emotion/styled/macro";
import theme from "theme";
import { FC, MutableRefObject } from "react";
import { createPortal } from "react-dom";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { IconCaret } from "atoms/IconCaret/IconCaret";
export interface DropdownProps {
  trigger: string | JSX.Element;
  children: string | JSX.Element;
  className?: string;
  isDropped?: boolean;
  disabled?: boolean;
  withArrow?: boolean;
  clear?: boolean;
  refElement?: MutableRefObject<HTMLDivElement | null> | null;
  onDrop?(): void;
  onBlur?(): void;
  onClear?(): void;
}

export const DropdownStyled = styled.div`
  font-family: Geologica;
  font-weight: 400;
  cursor: pointer;
  position: absolute;
  background: ${theme.primary.white};
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0px 2px 16px -4px var(--main-color-box-shadow-1),
    0px 4px 6px -2px var(--main-color-box-shadow-2);
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 60px);
  top: 200px;
  left: 15px;
`;

export const TriggerStyled = styled.div`
  font-family: Geologica;
  font-weight: 400;
  cursor: pointer;
  max-width: 400px;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  white-space: nowrap;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

export const Dropdown: FC<DropdownProps> = ({
  trigger,
  children,
  className,
  isDropped,
  withArrow = true,
  clear = false,
  disabled,
  refElement,
  onDrop,
  onBlur,
  onClear,
}) => {
  const handleMouseDown = () => {
    if (!disabled) {
      onDrop?.();
    }
  };
  const handleClear = () => {
    onClear?.();
    if (isDropped) {
      onDrop?.();
    }
  };
  const handleClickOutside = () => {
    onBlur?.();
    document.removeEventListener("mousedown", handleClickOutside);
  };
  const handleMouseLeave = () => {
    document.addEventListener("mousedown", handleClickOutside);
  };
  const handleMouseEnter = () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
  const DropdownContent =
    isDropped &&
    createPortal(
      <DropdownStyled>{children}</DropdownStyled>,
      refElement?.current || document.body
    );

  return (
    <DropdownWrapper
      className={className}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      ref={refElement}
    >
      <TriggerStyled onMouseDown={handleMouseDown}>
        {trigger}
        {withArrow && !clear && (
          <IconCaret
            style={{
              rotate: isDropped ? "180deg" : "0deg",
              transition: "rotate 0.2s ease-out",
            }}
          />
        )}
      </TriggerStyled>
      {clear && onClear && (
        <IconCircleClose
          style={{ cursor: "pointer", marginLeft: "6px" }}
          onClick={handleClear}
        />
      )}
      {DropdownContent}
    </DropdownWrapper>
  );
};
