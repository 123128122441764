import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Subtitle = styled.h4`
  color: #393e46;
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0 0;
  font-weight: 400;
`;

export const SuccessBlock = styled.div`
  background-color: #f5f5f5;
  border-radius: 16px;
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 28px 16px;
  color: #739b67;
  box-sizing: border-box;
`;

export const SuccessAmount = styled.div`
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
`;
export const SuccessInfo = styled.div`
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
`;

export const InfoContainer = styled.div`
  margin-top: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  order: 2;

  @media (max-width: 768px) {
    margin-top: 12px;

    > div:not(:last-child) {
      border-bottom: 1px solid #d3d3d3;
    }
  }
`;

export const Wrapper = styled.div`
  margin-top: 10px;

  @media (max-width: 1440px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const Title = styled.p`
  order: 1;
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--main-color-notification-title);
  width: 100%;
  text-align: center;

  @media (max-width: 767px) {
    text-align: left;
    order: 0;
    padding: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #454a3f;
  }
`;

export const InfoBlock = styled.div`
  width: 100%;
  max-width: 426px;
  padding-bottom: 12px;
`;

export const InfoBlockTitle = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: #8b8c88;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const InfoBlockDescription = styled.p`
  margin: 0px;
  font-size: 16px;
  line-height: 20px;
  color: #454a3f;
  word-break: break-all;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
