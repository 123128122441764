import Box from "@mui/material/Box";
import PinInput from "react-pin-input";
import { useEffect, useRef, useState } from "react";
import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "store";
import { NotificationResult } from "molecules/NotificationResult/NotificationResult";
import { CodeInput } from "components/CodeInput";
import { authActions, createPin } from "store/slices/auth";
import { IconLoginChangeSuccess } from "atoms/IconLoginChangeSuccess/IconLoginChangeSuccess";
import { Button } from "components/Button";
import { PIN_LENGTH } from "organisms/PinCode/PinCode";

type CreatePinCodeType = {
  value1: string;
  value2: string;
};

type CreatePinCodeProps = {
  onClose: () => void;
};

const initialValues = {
  value1: "",
  value2: "",
};

export const CreatePinCode = ({ onClose }: CreatePinCodeProps) => {
  const dispatch = useDispatch();
  let ele = useRef<PinInput | null>(null);

  const [pinCode, setPinCode] = useState<CreatePinCodeType>(initialValues);
  const [errorStatus, setErrorStatus] = useState(false);
  const [pinCreate, setPinIsCreate] = useState(false);
  const [value, setValue] = useState("");

  const { isLoading, elseDevice } = useSelector((state) => state.auth);
  const elseLogin = localStorage.getItem("elseLogin");
  const tmpLogin = localStorage.getItem("tmpLogin");

  const { value1, value2 } = pinCode;
  const isInitialPin = String(value1).length < PIN_LENGTH;
  const isPinComplete =
    value1.length === PIN_LENGTH && value2.length === PIN_LENGTH;

  const handleChangeWeb = (code?: string) => {
    const currentStep = isInitialPin ? 1 : 2;
    setErrorStatus(false);
    if (code?.length === PIN_LENGTH) {
      setPinCode({
        ...pinCode,
        [`value${currentStep}`]: code
          ? `${pinCode[`value${currentStep}`]}${code}`
          : pinCode[`value${currentStep}`].slice(0, -1),
      });
      //@ts-ignore
      ele?.current?.clear();
      setValue("");
    }
  };

  const setRef = (ref: PinInput | null) => {
    ele.current = ref;
  };

  useEffect(() => {
    if (isPinComplete) {
      if (value1 === value2) {
        dispatch(createPin({ pinCode: value1, repeatPin: value2 }))
          .unwrap()
          .then(() => {
            setPinIsCreate(true);
            if (tmpLogin) {
              localStorage.setItem("login", tmpLogin);
              localStorage.removeItem("tmpLogin");
              localStorage.removeItem("useBiometry");
            }
            if (elseDevice && elseLogin) {
              localStorage.setItem("login", elseLogin);
              localStorage.removeItem("elseLogin");
              localStorage.removeItem("useBiometry");
            }
          })
          .catch(() => {
            setErrorStatus(true);
            setPinCode(initialValues);
          });
      } else {
        setErrorStatus(true);
        setPinCode(initialValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCode]);

  if (pinCreate) {
    return (
      <Box
        height="100%"
        display="flex"
        marginTop={12}
        pr="20px"
        pl="20px"
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
      >
        <NotificationResult
          title={"Короткий код\nдля входа создан"}
          subtitleSize="text_5"
          titleSize="text_7"
          icon={<IconLoginChangeSuccess />}
          height="100%"
          buttonStyle={{ margin: 24 }}
          boxWidth="auto"
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              onClick: () => {
                dispatch(authActions.setElseDevice(false));
                onClose();
                dispatch(authActions.setIsPinAllowed(true));
              },
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%" }} px={{ xs: 16, xl: 32 }}>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        textAlign="center"
        alignItems="center"
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 32,
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography
              color="var(--main-color-text-title)"
              fontSize={16}
              lineHeight="20px"
              fontWeight={500}
              marginBottom={12}
            >
              {isInitialPin
                ? "Придумайте 4-х значный код"
                : "Повторите 4-х значный код"}
            </Typography>
            <CodeInput
              length={4}
              setRef={setRef}
              setCode={setValue}
              error={errorStatus}
              secret
              clearError={() => setErrorStatus(false)}
            />

            {errorStatus && (
              <Typography variant="text_5" color="var(--error-color-primary)">
                Коды не совпадают
              </Typography>
            )}
            <Button
              disabled={value.length !== 4}
              onClick={() => {
                handleChangeWeb(value);
              }}
              variant="primary"
              style={{ marginTop: "48px", marginBottom: "8px" }}
              isLoading={isLoading}
              title="Продолжить"
            />
          </Box>
        </>
      </Box>
    </Box>
  );
};
