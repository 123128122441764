import styled from "@emotion/styled/macro";
import { useDispatch } from "store";
import { getPromotionById } from "store/slices/promotion";
import { systemActions } from "store/slices/system";
import { AnotherPages, ICardState } from "store/slices/system/slice";

const Container = styled.div`
  border-radius: 24px;
  border: 1px solid #e6e7e8;
  aspect-ratio: 4/3;
  width: 212px;
  cursor: pointer;
  display: flex;
  margin: 0;

  @media (max-width: 588px) {
    width: auto;
    height: 112px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 18px;
  }
`;

const Title = styled.p`
  margin: 0;
  color: rgba(45, 50, 39, 0.85);
  font-size: 17px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  line-clamp: 1;
  word-wrap: break-word;

  word-break: break-word;

  @media (max-width: 588px) {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
`;

const Gradient = styled.div<{ hasTitle: boolean }>`
  width: 100%;
  border-radius: 0px 0px 22px 22px;
  margin-top: auto;
  padding: 12px 20px;
  padding-bottom: 18px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  backdrop-filter: ${(props) => (props.hasTitle ? "blur(3px)" : "")};

  @media (max-width: 588px) {
    padding: 12px 8px;
    width: 89%;
  }
`;

interface ICardProps {
  card: ICardState;
  processCardClick: boolean;
}

export const Card = ({ card, processCardClick }: ICardProps) => {
  const dispatch = useDispatch();

  const setTab = () => {
    if (processCardClick) {
      dispatch(systemActions.setActiveTab({ tab: AnotherPages.promotion }));
      if (card.id) dispatch(getPromotionById(card.id));
    }
  };

  return (
    <Container
      onClick={setTab}
      style={{
        backgroundImage: `url(${card.srcImgLenta})`,
        backgroundSize: "cover",
      }}
    >
      <Gradient hasTitle={!!card.titleShort}>
        <Title>{card.titleShort}</Title>
      </Gradient>
    </Container>
  );
};
