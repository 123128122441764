import { ReactComponent as GreenIcon } from "./assets/green.svg";
import { ReactComponent as RedIcon } from "./assets/red.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { getBankRates } from "store/slices/profile";
import { Box, useMediaQuery } from "@mui/material";
import {
  BlockIcon,
  Container,
  FlexBox,
  ImgWrapper,
  Item,
  ItemReduction,
  ItemValue,
  ItemsBlock,
  ItemsBlockWrapper,
  MainContainer,
  Title,
} from "./components";
import { IconDocument } from "atoms/IconDocument/IconDocument";
import { IconDots } from "atoms/IconDots/IconDots";
import { systemActions } from "store/slices/system";
import { AnotherPages } from "store/slices/system/slice";
import { SkeletonContainer } from "components/SkeletonContainer/SkeletonContainer";

export const ExchangeRates = () => {
  const { isLoadingBankRates, bankRates } = useSelector(
    (state) => state.profile
  );
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    dispatch(getBankRates());
  }, [dispatch]);

  const handleClick = () => {
    dispatch(systemActions.setActiveTab({ tab: AnotherPages.exchangeRates }));
  };

  return (
    <MainContainer>
      {isLoadingBankRates ? (
        <SkeletonContainer
          height={isMobile ? "44px" : "200px"}
          isLoading={isLoadingBankRates}
          width="100%"
        />
      ) : (
        <>
          <Container>
            <IconDocument />
            <Title>Курсы валют</Title>
          </Container>

          <ItemsBlockWrapper>
            <ItemsBlock>
              {bankRates.map((item) => {
                const itemIcon = item.isIncrease ? <GreenIcon /> : <RedIcon />;
                const rateWithoutZero = item.currentRate
                  .replace(/(\.\d*?)0+$/, "$1")
                  .replace(/\.$/, "");

                const rate = isMobile
                  ? rateWithoutZero
                      .split(".")
                      .map((item, idx) => (idx == 1 ? item.slice(0, 3) : item))
                      .join(", ")
                  : rateWithoutZero.split(".").join(", ");

                return (
                  <Item key={item.currencyCode}>
                    <FlexBox>
                      <ImgWrapper
                        style={{ backgroundImage: `url(${item.flag})` }}
                      />

                      <ItemReduction>
                        {item.currencySymbol} ({item.currencyCode})
                      </ItemReduction>
                    </FlexBox>

                    <FlexBox>
                      <ItemValue>{rate}</ItemValue>
                      <BlockIcon>{itemIcon}</BlockIcon>
                    </FlexBox>
                  </Item>
                );
              })}
            </ItemsBlock>

            {isMobile && (
              <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
                <IconDots />
              </Box>
            )}
          </ItemsBlockWrapper>
        </>
      )}
    </MainContainer>
  );
};
