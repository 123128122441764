import { RefillBlock } from "components/RefillBlock";
import { ClickableHeader } from "../Transfers/ClickableHeader";
import { useDispatch } from "react-redux";
import { systemActions, TabVariants } from "store/slices/system";
import { useEffect, useRef, useState } from "react";
import { refillAction } from "utils/refillAction";
import { useSelector } from "store";
import { RefillErrorScreen } from "components/CardsInfo/CardModalContent/RefillErrorScreen";
import { RefillInfoPage } from "organisms/RefillInfo/RefillInfo";
import { clearRefillUrl, setRefillId } from "store/slices/transferSlice";
import { ReactComponent as RefillIcon } from "./assets/refill.svg";
import { Dialog } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult/NotificationResult";
import { useIsMobile } from "hooks/useIsMobile";
import theme from "theme";

export const Refill = () => {
  const { createRefillError, refillUrl, refillId, refillError, isNewCard } =
    useSelector((state) => state.transfer);
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const [dialogOpen, setDialogOpen] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const isError = createRefillError || refillError;

  const onClose = () => {
    setDialogOpen(false);
  };

  const onBack = () => {
    dispatch(
      systemActions.setActiveTab({
        tab: TabVariants.transfersHub,
      })
    );
    dispatch(clearRefillUrl());
  };

  useEffect(() => {
    refillAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    function onPaymentFinish(e: MessageEvent<any>) {
      if (typeof e.data !== "string" || !e.data.includes("PAYMENT FINISHED"))
        return;

      const match = e.data.match(/REFILL_ID=(.+)/);
      if (!match) return;

      dispatch(setRefillId(match[1]));
    }

    window.addEventListener("message", onPaymentFinish);

    return () => {
      window.removeEventListener("message", onPaymentFinish);
    };
  }, [dispatch]);

  useEffect(() => {
    if (!refillUrl || !isNewCard) return;

    setDialogOpen(true);
  }, [refillUrl, isNewCard]);

  return (
    <div>
      <ClickableHeader
        onBack={onBack}
        title={(isError && !isMobile) || refillId ? null : "Пополнение карты"}
      />

      {!isError && !refillUrl && !refillId && <RefillBlock onClose={onBack} />}

      {refillUrl && (
        <iframe
          ref={iframeRef}
          src={refillUrl}
          width="100%"
          height="710px"
          style={{
            border: "none",
          }}
        />
      )}

      {refillId && <RefillInfoPage refillId={refillId} />}

      {createRefillError && <RefillErrorScreen onClose={onBack} />}

      <Dialog
        open={dialogOpen}
        onClose={onClose}
        sx={{
          [".MuiPaper-root"]: {
            padding: 32,
            maxWidth: 528,
            width: "100%",
            boxSizing: "border-box",
            borderRadius: 25,
            margin: 0,

            [theme.breakpoints.down("md")]: {
              width: "90%",
              padding: "20px 16px 12px 16px",
              borderRadius: 14,
            },

            "@media (max-width: 500px)": {
              maxWidth: 293,
            },
          },
        }}
      >
        <NotificationResult
          icon={!isMobile ? <RefillIcon /> : null}
          title="Пополнение возможно только с карт ПУ Банка России"
          height="100%"
          buttonsMarginTop={isMobile ? 20 : 30}
          buttonStyle={{
            [theme.breakpoints.down("md")]: {
              margin: "10px 0px 0px",
              pt: 10,
              fontSize: 17,
              fontWeight: 500,
              borderTop: "1px solid #D3D3D3",
              display: "flex",
              justifyContent: "center",
              width: "calc(100% + 50px)",
            },
          }}
          boxWidth="100%"
          boxPadding={isMobile ? "0" : "4px 8px 0"}
          titleContainerStyle={{
            mt: 12,

            [theme.breakpoints.down("md")]: {
              mt: 0,
            },
          }}
          titleSx={{
            fontSize: "24px",
            fontWeight: 600,
            lineHeight: "28px",
            color: "#454A3FE5",

            [theme.breakpoints.down("md")]: {
              fontWeight: 500,
              fontSize: 18,
              lineHeight: "24px",
              color: "#454A3F",
            },
          }}
          buttons={[
            {
              name: "Понятно",
              variant: isMobile ? "text" : "primary",
              onClick: onClose,
            },
          ]}
        />
      </Dialog>
    </div>
  );
};

export default Refill;
