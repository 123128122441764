import React from "react";
import styles from "./Grid.module.css";
import { findAvailableSizeKey } from "utils/findAvailableSizeKey";
import { useSizeKey } from "hooks/useSize";

export type SizeType = "xs" | "md" | "l" | "xl";

type FractionsObject = {
  [key in SizeType]?: [number, number];
};

interface SizeThreshold {
  key: SizeType;
  minWidth: number;
}

export const sizeThresholds: SizeThreshold[] = [
  { key: "xs", minWidth: 0 },
  { key: "md", minWidth: 780 },
  { key: "l", minWidth: 980 },
  { key: "xl", minWidth: 1440 },
];

interface GridProps {
  children: React.ReactNode;
  main?: boolean;
  fractions?: FractionsObject;
  paddings?: { [key in SizeType]?: string };
  container?: boolean;
}

/**
 * Основной контейнер для построения контента
 *
 * @param {React.ReactNode} children - Дочерние элементы, которые будут отображаться внутри контейнера.
 * @param {boolean} [main] - Указывает, является ли этот контейнер основным.
 * @param {FractionsObject} [fractions] - Объект, содержащий количество колонок и расстояние между ними для разных размеров экрана.
 * @param {{ [key in SizeType]?: string }} [paddings] - Объект, содержащий значения отступов для разных размеров экрана.
 * @param {boolean} [container=false] - Указывает, является ли контейнер центральным, с автоматическими отступами по бокам.
 */

export const Grid: React.FC<GridProps> = ({
  children,
  main,
  fractions,
  container = false,
  paddings,
}) => {
  let size: SizeType = useSizeKey();

  let containerStyle = {};

  if (fractions && !main) {
    if (!fractions[size]) {
      size = findAvailableSizeKey(size, fractions);
    }

    const [columnNumber, columnGap] = fractions[size] || [1, 0];
    const columnGapPixels = `${columnGap}px`;

    containerStyle = {
      ...containerStyle,
      gridTemplateColumns: `repeat(${columnNumber}, 1fr)`,
      gap: columnGapPixels,
    };
  }

  if (paddings) {
    if (!paddings[size]) {
      size = findAvailableSizeKey(size, paddings);
    }

    const paddingValues = paddings[size]!.split(" ").map(Number);

    const paddingStyles =
      paddingValues.length === 1
        ? `${paddingValues[0]}px`
        : paddingValues.length === 2
        ? `${paddingValues[0]}px ${paddingValues[1]}px`
        : paddingValues.length === 3
        ? `${paddingValues[0]}px ${paddingValues[1]}px ${paddingValues[2]}px`
        : `${paddingValues[0]}px ${paddingValues[1]}px ${paddingValues[2]}px ${paddingValues[3]}px`;

    containerStyle = {
      ...containerStyle,
      padding: paddingStyles,
    };
  }

  if (container) {
    containerStyle = {
      ...containerStyle,
      margin: "0 auto",
    };
  }
  const classNames = main ? styles.main_container : styles.fractions;

  return (
    <div className={classNames} style={containerStyle}>
      {children}
    </div>
  );
};
