import { createPortal } from "react-dom";
import { Box } from "@mui/material";
import { Notification } from "components/Notification";

type Props = {
  title: string;
  refElement: HTMLDivElement | null;
  description: string;
  withInfoIcon?: boolean;
  onClose?: () => void;
};

export const NotificationUnavailable = ({
  description,
  refElement,
  title,
  withInfoIcon,
  onClose,
}: Props) => {
  return createPortal(
    <Box
      sx={{
        position: "absolute",
        zIndex: 999999,
        top: 0,
        left: 0,
        right: 0,

        background: "#F5F5F5",
        boxShadow: "3px 7px 9.6px 0px rgba(139, 143, 135, 0.56)",
      }}
    >
      <Notification
        type="info"
        title={title}
        description={description}
        withInfoIcon={withInfoIcon}
        onClose={onClose}
      />
    </Box>,
    refElement || document.body
  );
};
