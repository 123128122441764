import { FC } from "react";
import { useDispatch, useSelector } from "store";
import { systemActions, TabVariants } from "store/slices/system";
import { AnotherPages, tabLabels } from "store/slices/system/slice";
import styles from "./style.module.css";

export const Navigation: FC = () => {
  const dispatch = useDispatch();

  const {
    system: { activeTab },
    cards: { isLoading },
  } = useSelector((state) => state);

  const tabsList = Object.keys(TabVariants) as Array<TabVariants>;

  const setTab = (tab: TabVariants) => {
    dispatch(systemActions.setActiveTab({ tab }));
  };

  const effectiveActiveTab =
    activeTab === AnotherPages.transfers || activeTab === AnotherPages.refill
      ? TabVariants.transfersHub
      : activeTab;

  return (
    <div className={styles.tabsContainer}>
      {tabsList.map((key) => (
        <button
          className={`${styles.tab} ${
            key === effectiveActiveTab ? styles.selected : ""
          }`}
          key={key}
          disabled={isLoading}
          onClick={() => setTab(key)}
        >
          {tabLabels[key]}
        </button>
      ))}
    </div>
  );
};
