import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChatMessage,
  ChatMessageDto,
  ChatMessagePage,
  GroupedChatMessages,
} from "api/notification";
import { getChatMessagesV2, sendChatMessageV2 } from "./asyncThunks";
import { ErrorResponse } from "api/auth";
import { logout } from "../auth";
import { IFourHundredErr } from "api/types";
import { groupMessagesByDate, mergeMessages } from "utils/chat";

export type ChatState = {
  isChatOpened: boolean;
  messages: Record<string, ChatMessage[]>;
  messagesArr: ChatMessage[];
  size: GroupedChatMessages["size"];
  page: GroupedChatMessages["page"];
  latestPage: number | null;
  paginationPage: number | null;
  isLoading: boolean;
  chatMessagesState: "initial" | "scrolling" | "firstMessagesLoaded";
  error: ErrorResponse | null;
  isImageViewerShown: boolean;
  selectedImage: string | null;
  files: { id?: string; file: File }[];
  unavailableError: ErrorResponse | null;
  isSizeError: boolean;
  isSizeErrorVisible: boolean;
  isMessageSending: boolean;
  initSumMessages: number;
  chatMessageCounter: number;
  newMessageCounter: number;
  chatError: boolean;
  showOperatorRating: boolean;
  operatorRatingTitle: string;
  idRatingMessage: string;
};

const initialState: ChatState = {
  isChatOpened: false,
  messages: {},
  messagesArr: [],
  isLoading: false,
  error: null,
  latestPage: null,
  paginationPage: null,
  chatMessagesState: "initial",
  isImageViewerShown: false,
  selectedImage: null,
  size: 0,
  page: 0,
  files: [],
  unavailableError: null,
  isSizeError: false,
  isSizeErrorVisible: false,
  isMessageSending: false,
  initSumMessages: 0,
  chatMessageCounter: 0,
  newMessageCounter: Number(localStorage.getItem("lastKnownNewMessageCount")),
  chatError: true,
  showOperatorRating: false,
  idRatingMessage: "",
  operatorRatingTitle: "",
};

// Максимальный размер файла 5MB
const MAX_FILE_SIZE = 5242880;

const chatSlice = createSlice({
  name: "chatSlice",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setChatMessageCounter: (state) => {
      state.chatMessageCounter++;
    },
    clearChatMessageCounter: (state) => {
      state.chatMessageCounter = 0;
    },
    setIsChatOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isChatOpened = payload;
    },
    setLatestPage: (state, { payload }: PayloadAction<number>) => {
      state.latestPage = payload;
    },
    setPaginationPage: (state, { payload }: PayloadAction<number>) => {
      state.paginationPage = payload;
    },
    setChatMessageState: (
      state,
      { payload }: PayloadAction<"initial" | "scrolling">
    ) => {
      state.chatMessagesState = payload;
    },
    setMessages: (state, { payload }: PayloadAction<ChatMessage[]>) => {
      state.messagesArr = mergeMessages(state.messagesArr, payload);
      state.messages = groupMessagesByDate(state.messagesArr);
    },
    setReadStatus: (state) => {
      state.messagesArr = state.messagesArr.map((msg) => {
        return !msg.read && !msg.user ? { ...msg, read: true } : msg;
      });

      state.messages = groupMessagesByDate(state.messagesArr);

      state.newMessageCounter = 0;
      localStorage.removeItem("lastKnownNewMessageCount");
    },
    setReadStatusByIds: (state, action: PayloadAction<string[]>) => {
      const messageIds = action.payload;

      state.messagesArr = state.messagesArr.map((msg) => {
        return messageIds.includes(msg.id) ? { ...msg, read: true } : msg;
      });

      state.messages = groupMessagesByDate(state.messagesArr);
    },
    setShowImageViewer: (state, { payload }: PayloadAction<boolean>) => {
      state.isImageViewerShown = payload;
    },
    setSelectedImage: (state, { payload }: PayloadAction<string>) => {
      state.selectedImage = payload;
    },
    clearIsFileWithErrorSize: (state) => {
      state.isSizeError = false;
    },
    setMessage: (state, { payload }: PayloadAction<ChatMessage>) => {
      state.messagesArr = mergeMessages(state.messagesArr, [payload]);
      state.messages = groupMessagesByDate(state.messagesArr);
    },
    addFile: (
      state,
      { payload }: PayloadAction<{ id?: string; file: File }>
    ) => {
      if (payload.file.size < MAX_FILE_SIZE) {
        state.isSizeError = false;
        state.isSizeErrorVisible = false;
        state.files.push(payload);
      } else {
        state.isSizeError = true;
        state.isSizeErrorVisible = true;
      }
    },
    removeFile: (state, { payload }: PayloadAction<string>) => {
      state.files = state.files.filter(({ id }) => id !== payload);
    },
    clearFiles: (state) => {
      state.files = [];
    },
    clearState: () => initialState,
    setIsSizeErrorClosed: (state) => {
      state.isSizeErrorVisible = false;
    },
    setInitSumMessages: (state, { payload }: PayloadAction<number>) => {
      state.initSumMessages += payload;
    },
    setNewMessageCounter: (state, { payload }: PayloadAction<number>) => {
      state.newMessageCounter = payload;
    },
    clearUnavailableError: (state) => {
      state.unavailableError = null;
    },
    setShowOperatorRating: (state, { payload }) => {
      state.showOperatorRating = payload;
    },
    setIdRatingMessage: (state, { payload }) => {
      state.idRatingMessage = payload;
    },
    setOperatorRatingTitle: (state, { payload }) => {
      state.operatorRatingTitle = payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(getChatMessagesV2.pending, (state) => {
        state.isLoading = true;
        if (state.error) {
          state.error = null;
        }
      })
      .addCase(
        getChatMessagesV2.fulfilled,
        (state, { payload }: PayloadAction<ChatMessagePage>) => {
          state.isLoading = false;
          state.paginationPage = payload.page;
          state.chatError = true;

          state.page = payload.page;
          state.size = payload.size;

          state.messagesArr = mergeMessages(state.messagesArr, payload.content);
          state.messages = groupMessagesByDate(state.messagesArr);

          if (state.chatMessagesState === "initial") {
            state.chatMessagesState = "firstMessagesLoaded";
          }
        }
      )
      .addCase(getChatMessagesV2.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.isLoading = false;
        state.error = payload?.response?.data || undefined;
        state.chatError = false;
      })

      .addCase(sendChatMessageV2.pending, (state) => {
        state.isMessageSending = true;
      })
      .addCase(
        sendChatMessageV2.fulfilled,
        (state, { payload }: PayloadAction<ChatMessage>) => {
          state.isMessageSending = false;
          state.unavailableError = null;
          state.error = null;

          state.messagesArr = mergeMessages(state.messagesArr, [payload]);
          state.messages = groupMessagesByDate(state.messagesArr);
        }
      )
      .addCase(sendChatMessageV2.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.isMessageSending = false;
        if (payload?.response?.status === 406) {
          state.unavailableError = payload?.response?.data;
        }
        state.error = payload?.response?.data;
      })
      .addCase(logout.fulfilled, () => {
        return initialState;
      });
  },
});

export default chatSlice;
