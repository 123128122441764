import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllPromotions, getPromotionById } from "./asyncThunks";
import { ErrorResponse } from "api/auth";
import { ICardState } from "../system/slice";
import { IFourHundredErr } from "api/types";

interface ActivePromotion {
  title?: string;
  description?: string;
  srcImgDetail?: string;
  srcImgLenta?: string;
  id?: number;
  category?: string;
  dateFrom?: string;
  buttonName?: string;
  buttonLink?: string;
}

export type PromotionsState = {
  promotions: Array<ICardState>;
  loading: boolean;
  error: ErrorResponse | null;
  activePromotion: ActivePromotion;
};

const initialState: PromotionsState = {
  promotions: [],
  loading: false,
  error: null,
  activePromotion: {},
};

const promotionsSlice = createSlice({
  name: "promotions",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPromotions.pending, (state) => {
        state.loading = true;
        if (state.error) {
          state.error = null;
        }
      })
      .addCase(getAllPromotions.fulfilled, (state, action) => {
        const payload = action.payload as Array<ICardState>;
        state.loading = false;
        state.promotions = payload;
      })
      .addCase(getAllPromotions.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.loading = false;
        state.error = payload?.response?.data || undefined;
      })
      .addCase(getPromotionById.pending, (state) => {
        state.loading = true;
        if (state.error) {
          state.error = null;
        }
      })
      .addCase(
        getPromotionById.fulfilled,
        (state, { payload }: PayloadAction<ActivePromotion>) => {
          state.loading = false;
          state.activePromotion = payload;
        }
      )
      .addCase(getPromotionById.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.loading = false;
        state.error = payload?.response?.data || undefined;
      });
  },
});

export default promotionsSlice;
