export const IconChat = ({ count = 0 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    fill="none"
    viewBox="0 0 56 56"
  >
    <rect width="56" height="56" fill="#739B67" rx="22"></rect>
    {count > 0 ? (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        x="35"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="20" height="20" rx="10" fill="#E57616" />
        <text
          fill="white"
          x={count < 10 || count > 99 ? "6" : "2"}
          y="15"
          fontSize="14"
        >
          {count > 99 ? "∞" : count}
        </text>
      </svg>
    ) : null}

    <path
      fill="#FFFFFF"
      transform="translate(13, 12)"
      d="M28.1071 17.2276C27.3071 23.3476 21.2538 28.3076 15.0805 28.3076H6.18714C5.4323 28.3079 4.73277 27.9117 4.34481 27.2642C3.95685 26.6167 3.93748 25.813 4.2938 25.1476L4.6538 24.4676C5.04047 23.809 5.04047 22.9928 4.6538 22.3342C1.6232 17.5469 2.09655 11.3376 5.8178 7.06505C9.53906 2.79248 15.6246 1.47118 20.7826 3.81589C25.9406 6.16059 28.9463 11.6145 28.1738 17.2276H28.1071Z"
    />
  </svg>
);
