import { useDispatch } from "store";
import { CardActions } from "components/CardsInfo/CardActions";
import { useEffect } from "react";
import { CARD_ACTION, setScreen } from "store/slices/cardsSlice";

export const CardModalWrapper = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setScreen(CARD_ACTION.INITIAL));
    };
  }, [dispatch]);

  return <CardActions />;
};
