import { Stack } from "@mui/system";
import React from "react";
import theme from "theme";
import { HistoryCategoryItemProps } from "./HistoryCategoryItem.types";
import {
  HEX_TO_FILTER_CHART_COLORS,
  WHITE_FILTER_COLOR,
} from "../HistoryCategoryChart.const";
import { Typography } from "@mui/material";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { formatAmount } from "utils/formatCurrency";

export const HistoryCategoryItem: React.FC<HistoryCategoryItemProps> = ({
  selectedCategoryId,
  onSelectCategory,
  category,
  currencySign,
}) => {
  const isSelected = selectedCategoryId === category.id;

  const getSumColor = () => {
    if (isSelected) return theme.primary.white;

    return Number(category.sum) > 0
      ? theme.primary.brand.blue["400"]
      : theme.palette.gray.b700;
  };

  return (
    <Stack
      onClick={onSelectCategory}
      key={category.id}
      direction="row"
      alignItems="center"
      spacing={{
        xs: 8,
        md: 12,
      }}
      padding={{
        xs: 4,
        md: "8px 12px 8px 8px",
      }}
      borderRadius={{
        xs: 8,
        md: 10,
      }}
      sx={{
        cursor: "pointer",
        backgroundColor: isSelected ? category.color : theme.primary.white,
        width: "fit-content",
        opacity: selectedCategoryId && !isSelected ? 0.4 : 1,

        "& .MuiTypography-root": {
          lineHeight: "20px",

          [theme.breakpoints.down("md")]: {
            lineHeight: "14px",
            fontSize: 13,
          },
        },
      }}
    >
      <Stack spacing={4} direction="row" alignItems="center">
        {category.logo && (
          <svg
            width="16"
            height="16"
            filter={
              isSelected
                ? WHITE_FILTER_COLOR
                : `brightness(0) saturate(100%) ${
                    HEX_TO_FILTER_CHART_COLORS[category.color!]
                  }`
            }
          >
            <image xlinkHref={category.logo} width={16} height={16}></image>
          </svg>
        )}

        <Typography
          color={isSelected ? theme.primary.white : "#000"}
          fontWeight={{
            md: 500,
            xs: 400,
            filter: isSelected
              ? WHITE_FILTER_COLOR
              : `brightness(0) saturate(100%) ${
                  HEX_TO_FILTER_CHART_COLORS[category.color!]
                }`,
          }}
        >
          {category.name}
        </Typography>
      </Stack>

      <Typography
        color={getSumColor()}
        fontWeight={{
          md: 500,
          xs: 400,
        }}
        sx={{
          "&:before": {
            content: Number(category.sum) > 0 ? `"+"` : "unset",
          },
        }}
      >
        {formatAmount(category.sum || 0, currencySign)}
      </Typography>

      {isSelected && (
        <IconCircleClose width={20} height={20} color={theme.primary.white} />
      )}
    </Stack>
  );
};
