export const downloadChatFile = (
  base64URL: string,
  name: string = "File",
  type: string
) => {
  try {
    // Очистка и валидация входных данных
    const cleanBase64 = base64URL.replace(/\s/g, "");

    // Декодируем base64
    const byteCharacters = atob(cleanBase64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i += 1) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    // Создаем Blob с правильным MIME-типом
    const blob = new Blob([byteArray], { type: type });
    const url = URL.createObjectURL(blob);

    // Создаем временную ссылку для скачивания
    const link = document.createElement("a");
    link.href = url;
    link.download = name;
    link.target = "_blank";
    link.style.display = "none";

    document.body.appendChild(link);
    link.click();

    // Уборка: удаляем ссылку и освобождаем память
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("[File download failed]");
    throw new Error("Failed to download file");
  }
};
