import { Box } from "@mui/material";
import { useMemo, useState } from "react";
import "swiper/css";
import "swiper/css/pagination";
import {
  CARD_ACTION,
  setIsCardInfoOpen,
  setScreen,
} from "../../../../store/slices/cardsSlice";
import { IconLimit } from "atoms/IconLimit/IconLimit";
import { IconLock } from "atoms/IconLock/IconLock";
import { IconSnow } from "atoms/IconSnow/IconSnow";
import { IconTransfer } from "atoms/IconTransfer/IconTransfer";
import { HISTORY_TABS, setActiveTab } from "store/slices/historySlice";
import { TabVariants, systemActions } from "store/slices/system";
import { CardAccount } from "./CardAccount";
import { CardButtons } from "./CardButtons";
import { CardsSlider } from "./CardsSlider";
import { IconFlame } from "atoms/IconFlame/IconFlame";
import { IconBtnPlus } from "atoms/IconBtnPlus/IconBtnPlus";
import { SHOW_REFILL } from "utils/constants";
import { useIsMobile } from "hooks/useIsMobile";
import { IconTime } from "atoms/IconTime";
import { useDispatch, useSelector } from "store";
import { AnotherPages } from "store/slices/system/slice";
import { BackSideCard } from "components/CardFullSize/BackSideCard";

export const CardInfo = () => {
  const [isIndex, setIsIndex] = useState(0);
  const { isMobile } = useIsMobile();

  const {
    cards: { allCards, chosenCardIndex },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const activeCard = allCards[isIndex || chosenCardIndex || 0];

  const setTab = (tab: TabVariants) => {
    switch (tab) {
      case TabVariants.history:
        dispatch(setActiveTab(HISTORY_TABS.ALL_OPERATIONS));
        dispatch(
          systemActions.setActiveTab({
            tab,
            state: { cardId: activeCard.cardId },
          })
        );
        break;
      case TabVariants.main:
        dispatch(setActiveTab(HISTORY_TABS.EXPENSES));
        dispatch(systemActions.setActiveTab({ tab }));
        break;
      default:
        dispatch(systemActions.setActiveTab({ tab }));
    }
  };

  const handleClick = async (id: CARD_ACTION) => {
    switch (id) {
      case CARD_ACTION.SHOW_HISTORY:
        dispatch(setIsCardInfoOpen(0));
        setTab(TabVariants.history);
        break;
      case CARD_ACTION.TRANSFER:
        setTab(TabVariants.transfersHub);
        dispatch(setIsCardInfoOpen(0));
        break;
      case CARD_ACTION.REFILL:
        dispatch(setIsCardInfoOpen(0));
        dispatch(
          systemActions.setActiveTab({
            tab: AnotherPages.refill,
          })
        );
        break;
      default:
        dispatch(setScreen(id));
        break;
    }
  };

  const dataIcon = useMemo(
    () =>
      [
        {
          id: CARD_ACTION.REFERRAL,
          title: "Заморозить",
          title2: "Разморозить",
          svg: () => <IconSnow color="#739B67" />,
          svg2: <IconFlame />,
        },
        {
          id: CARD_ACTION.LOST,
          title: "Заблокировать",
          svg: (color: string) => (
            <IconLock color="var(--error-color-illustration)" />
          ),
        },
        {
          id: CARD_ACTION.LIMIT,
          title: "Установить лимит",
          svg: (color: string) => <IconLimit color={color} />,
        },
        {
          id: CARD_ACTION.SHOW_HISTORY,
          title: "Посмотреть историю",
          svg: () => <IconTime />,
        },
        {
          id: CARD_ACTION.TRANSFER,
          title: "Перевести",
          svg: (color: string) => <IconTransfer color={color} />,
        },
        SHOW_REFILL && {
          id: CARD_ACTION.REFILL,
          title: "Пополнить",
          svg: () => <IconBtnPlus />,
        },
      ].filter(Boolean),
    []
  );

  return (
    <Box position="relative">
      <CardsSlider
        chosenCardIndex={chosenCardIndex}
        setIsIndex={setIsIndex}
        allCards={allCards}
      />
      <Box px={isMobile ? 16 : 32}>
        <CardButtons
          card={activeCard}
          dataIcon={dataIcon}
          handleClick={handleClick}
        />

        {/* @ts-ignore  */}
        <BackSideCard card={activeCard} />
        <CardAccount />
      </Box>
    </Box>
  );
};
