import styled from "@emotion/styled/macro";

const BackButton = styled.div`
  display: flex;
  padding-left: 86px;
  padding-top: 30px;
  margin-bottom: -20px;
  @media (max-width: 1440px) {
    padding-left: 34px;
  }
  @media (max-width: 767px) {
    padding-left: 15px;
    margin-bottom: 0px;
  }
`;

export const BackButtonContainer = ({ children }) => {
  return <BackButton>{children}</BackButton>;
};
