import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  checkCurrentPassword,
  getBankAddress,
  getBankDocuments,
  getBankRates,
  getUserInfo,
  updateLogin,
  updatePassword,
} from "./asyncThunks";
import { ErrorResponse, PmpDto } from "api/auth";
import { logout } from "../auth";
import { AddressResponse } from "api/account";
import { IFourHundredErr } from "api/types";
import { NOTIFICATION_TABS, ProfileModalType } from "pages/profile/models";

type UpdatePasswordSteps =
  | "CURRENT_PASSWORD"
  | "NEW_PASSWORD"
  | "SUCCESS_UPDATE";

interface BankRates {
  currencyCode: string;
  currencySymbol: string;
  currentRate: string;
  flag: string;
  isIncrease: boolean;
}

export interface Error extends ErrorResponse {
  status?: number;
}

export interface FilterState {
  cashIn: boolean;
  allTime: boolean;
  RUB: boolean;
  TJS: boolean;
  KGS: boolean;
  KZT: boolean;
  AMD: boolean;
  search: string;
}
export type FilterKeys = keyof Omit<FilterState, "search">;

export const initFiltersState: FilterState = {
  cashIn: false,
  allTime: false,
  RUB: false,
  TJS: false,
  KGS: false,
  KZT: false,
  AMD: false,
  search: "",
};

export type ProfileState = {
  isProfileLoading: boolean;
  isLoading: boolean;
  isLoginUpdated: boolean;
  isModalOpened: boolean;
  modalType: ProfileModalType | null;
  notificationActiveTab: NOTIFICATION_TABS;
  user: PmpDto | null;
  error: Error | null;
  errorCurrentPassword: Error | null;
  isBiometryConnected: string;
  isLoadingBankRates: boolean;
  isLoadingBankAddress: boolean;
  isLoadingBankDocuments: boolean;
  bankRates: Array<BankRates>;
  bankAddress: Array<AddressResponse>;
  bankDocuments: Blob | null;
  currentPassword: string;
  updatePasswordStep: UpdatePasswordSteps;
  filters: FilterState;
};

const initialState: ProfileState = {
  isProfileLoading: false,
  isLoading: false,
  isLoginUpdated: false,
  isModalOpened: false,
  modalType: null,
  notificationActiveTab: NOTIFICATION_TABS.NOTIFICATIONS,
  user: null,
  error: null,
  errorCurrentPassword: null,
  isBiometryConnected: "",
  isLoadingBankRates: false,
  isLoadingBankAddress: false,
  isLoadingBankDocuments: false,
  bankRates: [],
  bankAddress: [],
  bankDocuments: null,
  currentPassword: "",
  updatePasswordStep: "CURRENT_PASSWORD",
  filters: initFiltersState,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setBiometryConnected: (state, { payload }) => {
      state.isBiometryConnected = payload;
    },
    setIsLoginUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoginUpdated = payload;
    },
    setIsModalOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalOpened = payload;
      localStorage.setItem("activeModal", "isModalOpened");
    },
    setModalType: (
      state,
      { payload }: PayloadAction<ProfileModalType | null>
    ) => {
      state.modalType = payload;
      localStorage.setItem("profileModalType", payload || "");

      if (payload !== ProfileModalType.THIS_DEVICE_NOTIFICATIONS) {
        localStorage.removeItem("notificationActiveTab");
        state.notificationActiveTab = NOTIFICATION_TABS.NOTIFICATIONS;
      }
    },
    setNotificationActiveTab: (
      state,
      { payload }: PayloadAction<NOTIFICATION_TABS>
    ) => {
      state.notificationActiveTab = payload;
      localStorage.setItem("notificationActiveTab", payload);
    },
    setIsllCardsShow: (state) => {
      if (state.user) {
        state.user.showAllCards = !state.user?.showAllCards;
      }
    },
    setCurrentPassword: (state, { payload }: PayloadAction<string>) => {
      state.currentPassword = payload;
    },
    setUpdatePasswordStep: (
      state,
      { payload }: PayloadAction<UpdatePasswordSteps>
    ) => {
      state.updatePasswordStep = payload;
    },
    clearError: (state) => {
      state.error = null;
    },
    clearErrorCurrentPassword: (state) => {
      state.errorCurrentPassword = null;
    },
    setFilters: (state, { payload }: PayloadAction<Partial<FilterState>>) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    toggleFilter: (state, { payload }: PayloadAction<FilterKeys>) => {
      state.filters[payload] = !state.filters[payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.isProfileLoading = true;
        if (state.error) {
          state.error = null;
        }
      })
      .addCase(getUserInfo.fulfilled, (state, { payload }) => {
        state.isProfileLoading = false;
        state.user = payload as PmpDto;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.isProfileLoading = false;
        state.error = payload?.response?.data || undefined;
      })
      .addCase(updateLogin.pending, (state) => {
        state.isLoading = true;
        if (state.error) {
          state.error = null;
        }
      })
      .addCase(updateLogin.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isLoginUpdated = true;
        state.user = payload as PmpDto;
      })
      .addCase(updateLogin.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.isLoading = false;
        state.error = payload?.response?.data
          ? { ...payload.response.data, status: payload.response.status }
          : null;
      })
      .addCase(checkCurrentPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkCurrentPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.updatePasswordStep = "NEW_PASSWORD";
      })
      .addCase(checkCurrentPassword.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.isLoading = false;
        state.errorCurrentPassword = payload?.response?.data
          ? { ...payload.response.data, status: payload.response.status }
          : null;
      })
      .addCase(updatePassword.pending, (state) => {
        if (state.error) {
          state.error = null;
        }
        state.isLoading = true;
      })
      .addCase(updatePassword.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.updatePasswordStep = "SUCCESS_UPDATE";
        state.user = payload as PmpDto;
      })
      .addCase(updatePassword.rejected, (state, action) => {
        const payload = action.payload as IFourHundredErr;
        state.isLoading = false;
        state.error =
          payload?.response?.data || payload?.response?.status
            ? { ...payload.response.data, status: payload.response.status }
            : null;
      })
      .addCase(getBankRates.pending, (state) => {
        state.isLoadingBankRates = true;
      })
      .addCase(getBankRates.fulfilled, (state, { payload }) => {
        state.isLoadingBankRates = false;
        state.bankRates = payload as BankRates[];
      })
      .addCase(getBankRates.rejected, (state) => {
        state.isLoadingBankRates = false;
      })

      .addCase(getBankAddress.pending, (state) => {
        state.isLoadingBankAddress = true;
      })
      .addCase(
        getBankAddress.fulfilled,
        (state, { payload }: PayloadAction<Array<AddressResponse>>) => {
          state.bankAddress = payload;
          state.isLoadingBankAddress = false;
        }
      )
      .addCase(getBankAddress.rejected, (state) => {
        state.isLoadingBankAddress = false;
      })

      .addCase(getBankDocuments.pending, (state) => {
        state.isLoadingBankDocuments = true;
      })
      .addCase(
        getBankDocuments.fulfilled,
        (state, { payload }: PayloadAction<Blob>) => {
          state.bankDocuments = payload;
          state.isLoadingBankDocuments = false;
        }
      )
      .addCase(getBankDocuments.rejected, (state) => {
        state.isLoadingBankDocuments = false;
      })
      .addCase(logout.fulfilled, () => initialState);
  },
});

export default profileSlice;
