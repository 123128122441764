export const IconCirclePlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53268 1.66699H10.466C14.8107 1.66699 18.3327 5.18902 18.3327 9.53366V10.467C18.3327 14.8116 14.8107 18.3337 10.466 18.3337H9.53268C5.18804 18.3337 1.66602 14.8116 1.66602 10.467V9.53366C1.66602 5.18902 5.18804 1.66699 9.53268 1.66699ZM10.6244 10.6253H13.3327C13.6779 10.6253 13.9577 10.3455 13.9577 10.0003C13.9577 9.65515 13.6779 9.37533 13.3327 9.37533H10.6244V6.66699C10.6244 6.32182 10.3445 6.04199 9.99935 6.04199C9.65417 6.04199 9.37435 6.32182 9.37435 6.66699V9.37533H6.66602C6.32084 9.37533 6.04102 9.65515 6.04102 10.0003C6.04102 10.3455 6.32084 10.6253 6.66602 10.6253H9.37435V13.3337C9.37435 13.6788 9.65417 13.9587 9.99935 13.9587C10.3445 13.9587 10.6244 13.6788 10.6244 13.3337V10.6253Z"
        fill="#739B67"
      />
    </svg>
  );
};
