import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { statementApi } from "../../api";
import {
  GetListRequest,
  StatementListResponse,
  StatementFull,
  ListExpenseStatementRequest,
  ListIncomeStatementRequest,
  ListStatementRequest,
  StatementCategoryFull,
  StatementHistoryResponse,
  StatementHistoryRequest,
  StatementView,
} from "api/account";
import { getAllPromotions } from "./promotion";
import { transformOperationType } from "utils/transformOperationType";
import { getEndOfDay } from "utils/getEndOfDay";
import moment from "moment";

export enum HISTORY_TABS {
  ALL_OPERATIONS = "Все",
  EXPENSES = "Расходы",
  INCOMES = "Зачисления",
}

export const STATEMENT_TYPE_TO_TABS = {
  [HISTORY_TABS.ALL_OPERATIONS]: StatementView.All,
  [HISTORY_TABS.EXPENSES]: StatementView.Expense,
  [HISTORY_TABS.INCOMES]: StatementView.Income,
};

interface SearchFiltersState {
  search: string;
  fromAmount: number | null;
  toAmount: number | null;
  from: any;
  to: any;
  operationFilterType: any;
  page: number;
  cardId: string;
  acctId: string;
  categoryIds?: number[];
}

export type HistoryState = {
  isLoading: boolean;
  isItemLoading: boolean;
  historyList: Array<StatementListResponse>;
  expenseList: Array<StatementListResponse>;
  incomeList: Array<StatementListResponse>;
  historyChartData: StatementHistoryResponse;
  historyItem: StatementFull | null;
  activeTab: HISTORY_TABS;
  categoriesData?: Array<StatementCategoryFull>;
  categoriesExpenseSum?: number;
  categoriesIncomeSum?: number;
  isCategoriesDataLoading: boolean;
  isHistoryChartDataLoading: boolean;
  activeCategoryId: number | null;
  hideWidget: boolean;
  date: {
    from: string;
    to: string;
  };
  isReportLoading: boolean;
  selectedDay: string | null;
  isChartError: null | boolean;
  filters: SearchFiltersState;
};

const initialFilterState: SearchFiltersState = {
  search: "",
  fromAmount: null,
  toAmount: null,
  from: "",
  to: "",
  operationFilterType: "",
  page: 0,
  cardId: "",
  acctId: "",
  categoryIds: undefined,
};

const initialState: HistoryState = {
  isLoading: true,
  isItemLoading: false,
  isHistoryChartDataLoading: false,
  historyList: [],
  expenseList: [],
  incomeList: [],
  historyChartData: {},
  historyItem: null,
  activeTab: HISTORY_TABS.ALL_OPERATIONS,
  categoriesData: [],
  isCategoriesDataLoading: false,
  activeCategoryId: null,
  selectedDay: null,
  isChartError: null,
  hideWidget: false,
  categoriesExpenseSum: 0,
  categoriesIncomeSum: 0,
  date: {
    from: moment().utc().startOf("month").toISOString(),
    to: getEndOfDay(new Date()),
  },
  isReportLoading: false,
  filters: { ...initialFilterState },
};

export const getListReport = createAsyncThunk(
  "getListReport",
  async (payload: ListStatementRequest, { rejectWithValue }) => {
    try {
      const operationFilterType = payload.operationFilterType
        ? transformOperationType(payload.operationFilterType)
        : "";

      const response = await statementApi.getListReport(
        "",
        // @ts-ignore
        payload.operationViewType || "",
        payload.cardId || "",
        payload.acctId || "",
        payload.from || "",
        payload.to || "",
        payload.fromAmount || "",
        payload.toAmount || "",
        payload.search || "",
        payload.forceRefresh || "",
        payload.categoryIds || "",
        operationFilterType
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getListReportPdf = createAsyncThunk(
  "getListReport",
  async (payload: ListStatementRequest, { rejectWithValue }) => {
    try {
      const operationFilterType = payload.operationFilterType
        ? transformOperationType(payload.operationFilterType)
        : "";

      const response = await statementApi.getListReportPdf(
        "",
        // @ts-ignore
        payload.operationViewType || "",
        payload.cardId || "",
        payload.acctId || "",
        payload.from || "",
        payload.to || "",
        payload.fromAmount || "",
        payload.toAmount || "",
        payload.search || "",
        payload.forceRefresh || "",
        payload.categoryIds || "",
        operationFilterType
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHistoryList = createAsyncThunk(
  "getHistoryList",
  async (payload: GetListRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListV2("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHistoryListLight = createAsyncThunk(
  "getHistoryListLight",
  async (payload: GetListRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListV2Light("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appendHistoryList = createAsyncThunk(
  "appendHistoryList",
  async (payload: GetListRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListV2("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHistoryItem = createAsyncThunk(
  "getHistoryItem",
  async (historyId: string, { rejectWithValue }) => {
    try {
      const response = await statementApi.getDetailsV2("string", historyId);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCategoryExpense = createAsyncThunk(
  "getCategoryExpense",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getCategoryExpense("string", {
        ...payload,
        toAmount: payload.toAmount || undefined,
        fromAmount: payload.fromAmount || undefined,
        operationFilterType: payload.operationFilterType || undefined,
        page: 0,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCategoryExpenseLight = createAsyncThunk(
  "getCategoryExpenseLight",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getCategoryExpenseLight("string", {
        ...payload,
        toAmount: payload.toAmount || undefined,
        fromAmount: payload.fromAmount || undefined,
        operationFilterType: payload.operationFilterType || undefined,
        page: 0,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCategoryIncome = createAsyncThunk(
  "getCategoryIncome",
  async (payload: ListIncomeStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getCategoryIncome("string", {
        ...payload,
        toAmount: payload.toAmount || undefined,
        fromAmount: payload.fromAmount || undefined,
        operationFilterType: payload.operationFilterType || undefined,
        page: 0,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getExpenseList = createAsyncThunk(
  "getExpenseList",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListExpense("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appendExpenseList = createAsyncThunk(
  "appendExpenseList",
  async (payload: ListExpenseStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListExpense("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getIncomeList = createAsyncThunk(
  "getIncomeList",
  async (payload: ListIncomeStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListIncome("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const appendIncomeList = createAsyncThunk(
  "appendIncomeList",
  async (payload: ListIncomeStatementRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getListIncome("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getHistoryChartData = createAsyncThunk(
  "getStatementsHistory",
  async (payload: StatementHistoryRequest, { rejectWithValue }) => {
    try {
      const response = await statementApi.getHistory("string", payload);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    resetStore: (state) => {
      state = initialState;
    },
    setActiveTab: (state, { payload }) => {
      state.activeTab = payload;
    },
    setDate: (state, { payload }) => {
      state.date = payload;
    },
    setActiveCategory: (state, { payload }) => {
      state.activeCategoryId = payload;
    },
    setSelectedDay: (state, { payload }) => {
      state.selectedDay = payload;
    },
    setHistorySearchFilters(
      state,
      action: PayloadAction<Partial<SearchFiltersState>>
    ) {
      state.filters = { ...state.filters, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPromotions.rejected, (state) => {
      state.hideWidget = true;
    });
    builder.addCase(getAllPromotions.fulfilled, (state) => {
      state.hideWidget = false;
    });

    builder.addCase(getCategoryExpenseLight.pending, (state) => {
      state.isCategoriesDataLoading = true;
    });
    builder.addCase(getCategoryExpenseLight.fulfilled, (state, { payload }) => {
      if (!payload.categories?.length) {
        state.categoriesData = [];
      } else {
        state.categoriesData = payload.categories?.map((item) => {
          return {
            ...item,
            sum: item.sum ? Math.abs(item.sum) : 0,
          };
        });
      }
      state.isCategoriesDataLoading = false;
    });

    builder.addCase(getCategoryExpense.pending, (state) => {
      state.isCategoriesDataLoading = true;
    });
    builder.addCase(getCategoryIncome.pending, (state) => {
      state.isCategoriesDataLoading = true;
    });
    builder.addCase(getCategoryExpense.fulfilled, (state, { payload }) => {
      state.isCategoriesDataLoading = false;
      state.isChartError = null;

      if (state.activeTab !== HISTORY_TABS.EXPENSES) return;
      if (!payload.categories?.length) {
        state.categoriesData = [];
        state.categoriesExpenseSum = 0;
      } else {
        state.categoriesData = payload.categories;
        state.categoriesExpenseSum = payload.categories.reduce(
          (acc, el) => acc + (el.sum || 0),
          0
        );
      }
    });
    builder.addCase(getCategoryIncome.fulfilled, (state, { payload }) => {
      state.isCategoriesDataLoading = false;
      state.isChartError = null;

      if (state.activeTab !== HISTORY_TABS.INCOMES) return;

      if (!payload.categories?.length) {
        state.categoriesData = [];
        state.categoriesIncomeSum = 0;
      } else {
        state.categoriesData = payload.categories;
        state.categoriesIncomeSum = payload.categories.reduce(
          (acc, el) => acc + (el.sum || 0),
          0
        );
      }
    });
    builder.addCase(getHistoryList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpenseList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getExpenseList.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getIncomeList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIncomeList.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getCategoryExpense.rejected, (state) => {
      state.isChartError = true;
    });
    builder.addCase(getCategoryIncome.rejected, (state) => {
      state.isChartError = true;
    });
    builder.addCase(getHistoryChartData.rejected, (state) => {
      state.isChartError = true;
    });

    builder.addCase(appendHistoryList.fulfilled, (state, action) => {
      const historyList = [...state.historyList, ...action.payload].reduce(
        (acc: any[], cur: any) => {
          const existsIndex = acc.findIndex((item) => item.date === cur.date);
          if (existsIndex === -1) {
            acc.push(cur);
          } else {
            acc[existsIndex].statements = Object.values([
              ...acc[existsIndex].statements,
              ...cur.statements,
            ]).reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, []);
          }
          return acc;
        },
        []
      );
      state.historyList = historyList;
    });
    builder.addCase(appendExpenseList.fulfilled, (state, action) => {
      const expenseList = [...state.expenseList, ...action.payload].reduce(
        (acc: any[], cur: any) => {
          const existsIndex = acc.findIndex((item) => item.date === cur.date);
          if (existsIndex === -1) {
            acc.push(cur);
          } else {
            acc[existsIndex].statements = Object.values([
              ...acc[existsIndex].statements,
              ...cur.statements,
            ]).reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, []);
          }
          return acc;
        },
        []
      );
      state.expenseList = expenseList;
    });
    builder.addCase(appendIncomeList.fulfilled, (state, action) => {
      const incomeList = [...state.incomeList, ...action.payload].reduce(
        (acc: any[], cur: any) => {
          const existsIndex = acc.findIndex((item) => item.date === cur.date);
          if (existsIndex === -1) {
            acc.push(cur);
          } else {
            acc[existsIndex].statements = Object.values([
              ...acc[existsIndex].statements,
              ...cur.statements,
            ]).reduce((acc, cur) => {
              acc.push(cur);
              return acc;
            }, []);
          }
          return acc;
        },
        []
      );
      state.incomeList = incomeList;
    });
    builder.addCase(getHistoryListLight.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getHistoryChartData.pending, (state) => {
      state.isHistoryChartDataLoading = true;
    });
    builder.addCase(getHistoryListLight.fulfilled, (state, action) => {
      state.historyList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getHistoryList.fulfilled, (state, action) => {
      state.historyList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getHistoryChartData.fulfilled, (state, action) => {
      const { statistic } = action.payload;
      state.isChartError = null;
      if (state.activeTab === HISTORY_TABS.ALL_OPERATIONS) {
        state.categoriesExpenseSum = statistic?.expenseSum || 0;
        state.categoriesIncomeSum = statistic?.incomeSum || 0;
        if (!statistic?.expenseValue && !statistic?.incomeValue) {
          state.categoriesData = [];
        } else {
          state.categoriesData = [
            {
              logo: statistic?.expenseIcon,
              color: statistic?.expenseColor,
              percent: statistic?.expenseValue,
              sum: (statistic?.expenseSum || 0) * -1,
              name: "Расходы",
              id: 1,
            },
            {
              logo: statistic?.incomeIcon,
              color: statistic?.incomeColor,
              percent: statistic?.incomeValue,
              sum: statistic?.incomeSum,
              name: "Зачисления",
              id: 2,
            },
          ];
        }
      }

      state.historyChartData = {
        ...action.payload,
        statements: action.payload.statements
          ?.sort(
            (a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime()
          )
          .map((s) => ({
            ...s,
            incomeValue: (s.expenseValue || 0) + Number(s.incomeValue),
          })),
      };
      state.isHistoryChartDataLoading = false;
    });
    builder.addCase(getExpenseList.fulfilled, (state, action) => {
      state.expenseList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getIncomeList.fulfilled, (state, action) => {
      state.incomeList = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getHistoryItem.fulfilled, (state, action) => {
      state.historyItem = action.payload;
      state.isItemLoading = false;
    });
    builder.addCase(getHistoryItem.pending, (state) => {
      state.isItemLoading = true;
    });
    builder.addCase(getListReport.pending, (state) => {
      state.isReportLoading = true;
    });
    builder.addCase(getListReport.rejected, (state) => {
      state.isReportLoading = false;
    });
    builder.addCase(getListReport.fulfilled, (state) => {
      state.isReportLoading = false;
    });
  },
});

export const historyActions = historySlice.actions;
export const {
  setActiveTab,
  setDate,
  setActiveCategory,
  setSelectedDay,
  setHistorySearchFilters,
} = historySlice.actions;

export default historySlice.reducer;
