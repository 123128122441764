import React, { useState } from "react";
import { Box } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult/NotificationResult";
import { IconSuccess } from "atoms/IconSuccess";
import { useDispatch, useSelector } from "store";
import { updateCardInfo } from "store/slices/cardsSlice";
import { Button } from "components/Button";
import { InputText } from "components/InputText";

type LoginModalProps = {
  name?: string;
  onClose: () => void;
  cardId: string;
};

export const CardModal = ({ name, onClose, cardId }: LoginModalProps) => {
  const dispatch = useDispatch();
  const { isLoginUpdated } = useSelector((state) => state.profile);
  const [newCardName, setNewCardName] = useState(name || "");
  const [operationSuccess, setOperationSuccess] = useState(false);

  const disabled = newCardName.length === 0 || newCardName === name;
  const handleClick = () => {
    dispatch(
      updateCardInfo({
        cardUID: cardId,
        description: newCardName,
      })
    )
      .unwrap()
      .then((res) => {
        setOperationSuccess(true);
      })
      .catch((err: any) => {
        return err;
      });
  };

  if (operationSuccess) {
    return (
      <Box
        height="100%"
        pt={{ xs: 46, md: 32 }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <NotificationResult
          title="Название карты успешно изменено"
          icon={<IconSuccess />}
          height="100%"
          buttonsMarginTop={52}
          titleStyle={{
            fontSize: "28px",
            fontWeight: 500,
            lineHeight: "36px",
            color: "#393E46",
          }}
          buttons={[
            {
              name: "Понятно",
              variant: "primary",
              onClick: onClose,
            },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box height="100%">
      <Box boxSizing="border-box" mt={8}>
        <InputText
          value={newCardName}
          styles={{ gap: "8px" }}
          labelStyles={{ fontSize: "16px", color: "#454A3F", fontWeight: 400 }}
          name="newLogin"
          label="Название для карты"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setNewCardName(e.target.value);
          }}
          maxLength={26}
          style={{ color: "#454A3F" }}
        />

        <Box
          width="100%"
          mt={32}
          display="flex"
          flexDirection="column"
          gap={10}
        >
          <Button
            isLoading={isLoginUpdated}
            disabled={disabled}
            variant="primary"
            onClick={handleClick}
            title="Применить"
          />
          <Button variant="secondary" onClick={onClose} title="Отмена" />
        </Box>
      </Box>
    </Box>
  );
};
