import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth, ErrorParameterResponseAllOf } from "api/auth";
import {
  login,
  loginConfirm,
  logout,
  register,
  registerCheck,
  registerCVV,
  registerConfirm,
  fetchCountriesList,
  resetPassword,
  checkPassword,
  confirmResetPassword,
  checkCvv,
  regBiometryToken,
  getBiometryCredentials,
  biometryRegConfirm,
  loginError,
  createPin,
  loginPin,
  biometryAuthConfirm,
  biometryAuth,
  verifyPinCode,
  updatePinCode,
  createLogin,
} from "./asyncThunks";
import { RecoveryPasswordScreens, RegisterScreens, UserStatus } from "./model";
import { CheckBlockType } from "components/CheckBlock/CheckBlock";
import { Error } from "../profile/slice";
import { IFourHundredErr } from "api/types";

export enum LOGIN_ERROR_CODE {
  OTP_BLOCKED = "OTP_BLOCKED",
  ACCOUNT_LOCKED_BY_AUTH_ATTEMPTS = "ACCOUNT_LOCKED_BY_AUTH_ATTEMPTS",
  CLIENT_BLOCKED = "CLIENT_BLOCKED",
  CLIENT_UNBLOCKED = "CLIENT_UNBLOCKED",
  WRONG_REQUEST = "WRONG_REQUEST",
}

export enum REGISTER_ERROR_CODE {
  CARD_DISABLED = "CARD_DISABLED",
  WRONG_REQUEST_CARD = "WRONG_REQUEST_CARD",
  WRONG_REQUEST_PHONE = "WRONG_REQUEST_PHONE",
  WRONG_REQUEST_BIRTHDAY = "WRONG_REQUEST_BIRTHDAY",
  REGISTRATION_LOCKED_BY_ATTEMPTS = "REGISTRATION_LOCKED_BY_ATTEMPTS",
  OTP_BLOCKED = "OTP_BLOCKED",
}

interface AnotherDeviceError {
  title: string;
  description: string;
}

export type AuthInitialState = {
  isLoading: boolean;
  loginToken: string;
  registerToken: string;
  registerPhone: string;
  resetToken: string;
  restoreToken: string;
  countries: any;
  isAuthenticated: boolean;
  isPinAllowed: boolean;
  isPinForgotten?: boolean;
  isConfirmed: boolean;
  registerScreen: RegisterScreens;
  resetScreen: RecoveryPasswordScreens;
  isAccountBlocked: boolean;
  accountStatus: UserStatus;
  isLoginBlocked: boolean;
  error?: Error & ErrorParameterResponseAllOf;
  accessToken?: string;
  isBurgerActive?: boolean;
  isLogoutVisible?: boolean;
  phone?: string;
  elseDevice: boolean;
  isInitialPin: boolean;
  frozenType: string;
  isCodeForgotten: boolean;
  isPinUpdated: boolean;
  anotherDeviceError: AnotherDeviceError;
  createAccountStep: CheckBlockType;
  login: string;
};

const initialState: AuthInitialState = {
  loginToken: "",
  registerToken: "",
  registerPhone: "",
  resetToken: "",
  restoreToken: "",
  accessToken: undefined,
  countries: [],
  error: undefined,
  isLoading: false,
  isAuthenticated: false,
  isPinAllowed: false,
  isPinForgotten: false,
  isConfirmed: false,
  registerScreen: "initial",
  resetScreen: "initial",
  isAccountBlocked: false,
  accountStatus: UserStatus.ACTIVE,
  isLoginBlocked: false,
  isBurgerActive: false,
  isLogoutVisible: false,
  phone: "",
  elseDevice: false,
  isInitialPin: true,
  frozenType: "registration",
  isCodeForgotten: false,
  isPinUpdated: false,
  anotherDeviceError: {
    title: "",
    description: "",
  },
  createAccountStep: "login",
  login: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNewAccessToken: (state, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    setRegisterScreen: (state, { payload }: PayloadAction<RegisterScreens>) => {
      state.registerScreen = payload;
    },
    setRestoreScreen: (
      state,
      { payload }: PayloadAction<RecoveryPasswordScreens>
    ) => {
      state.resetScreen = payload;
    },
    setAccountIsBlocked: (state, { payload }: PayloadAction<boolean>) => {
      state.isAccountBlocked = payload;
    },
    setAccountStatus: (state, { payload }: PayloadAction<UserStatus>) => {
      state.accountStatus = payload;
    },

    setLoginIsBlocked: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoginBlocked = payload;
    },
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
      state.isConfirmed = true;
    },
    setIsPinAllowed: (state, { payload }: PayloadAction<boolean>) => {
      state.isPinAllowed = payload;
    },
    setIsPinForgotten: (state, { payload }: PayloadAction<boolean>) => {
      state.isPinForgotten = payload;
    },
    setIsCodeForgotten: (state) => {
      state.isCodeForgotten = true;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setIsBurgerActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurgerActive = payload;
    },
    setIsLogoutVisible: (state) => {
      state.isLogoutVisible = !state.isLogoutVisible;
    },
    resetError: (state) => {
      state.error = undefined;
    },
    setPhone: (state, { payload }) => {
      state.phone = payload;
    },
    setLogout: (state) => {
      state.isAuthenticated = false;
      state.isConfirmed = false;
      state.elseDevice = false;
    },
    setElseDevice: (state, { payload }: PayloadAction<boolean>) => {
      state.elseDevice = payload;
    },
    setIsInitialPin: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitialPin = payload;
    },
    setRegisterPhone: (state, { payload }: PayloadAction<string>) => {
      state.registerPhone = payload;
    },
    resetStore: (state) => {
      state = initialState;
    },
    setIsPinUpdated: (state, { payload }: PayloadAction<boolean>) => {
      state.isPinUpdated = payload;
    },
    setAnotherDeviceError: (
      state,
      { payload }: PayloadAction<AnotherDeviceError>
    ) => {
      state.anotherDeviceError = payload;
    },
    setLogin: (state, { payload }: PayloadAction<string>) => {
      state.login = payload;
    },
    setCreateAccountStep: (
      state,
      { payload }: PayloadAction<CheckBlockType>
    ) => {
      state.createAccountStep = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.loginToken = payload as string;
      state.isAuthenticated = true;
    });
    builder.addCase(login.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      const error = payload?.response?.data;
      state.error = error;

      if (error?.code === LOGIN_ERROR_CODE.CLIENT_UNBLOCKED) {
        state.accountStatus = UserStatus.UNBLOCKED;
      }

      state.isLoading = false;
    });
    builder.addCase(loginConfirm.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(
      loginConfirm.fulfilled,
      (state, { payload }: PayloadAction<Auth>) => {
        state.isLoading = false;
        state.accessToken = payload.accessToken;
        state.isConfirmed = true;
      }
    );
    builder.addCase(loginConfirm.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.isConfirmed = false;
      state.accessToken = undefined;
    });
    builder.addCase(logout.rejected, (state) => {
      state.isAuthenticated = false;
      state.isConfirmed = false;
      state.accessToken = undefined;
    });
    builder.addCase(fetchCountriesList.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(fetchCountriesList.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.countries = payload;
    });
    builder.addCase(fetchCountriesList.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
    });
    builder.addCase(register.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.registerToken = payload as string;
      state.registerScreen = "confirmation";
    });
    builder.addCase(register.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
      const error = payload?.response?.data;

      if (error?.code === REGISTER_ERROR_CODE.CARD_DISABLED) {
        state.registerScreen = "frozen";
        state.frozenType = "registration";
      }
    });
    builder.addCase(registerCheck.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(registerCheck.fulfilled, (state) => {
      state.isLoading = false;
      state.registerScreen = "cvv";
    });
    builder.addCase(registerCheck.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    });
    builder.addCase(registerCVV.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(registerCVV.fulfilled, (state) => {
      state.isLoading = false;
      state.registerScreen = "createLoginAndPass";
    });
    builder.addCase(registerCVV.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      const error = payload?.response?.data;
      state.isLoading = false;
      if (error?.code === "OUT_OF_CVV_COMMENT") {
        state.registerScreen = "frozen";
        state.frozenType = "cvv";
      }
      state.error = payload?.response?.data || undefined;
    });
    builder.addCase(registerConfirm.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(registerConfirm.fulfilled, (state) => {
      state.isLoading = false;
      state.registerScreen = "success";
    });
    builder.addCase(registerConfirm.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.resetToken = payload as string;
      state.resetScreen = "confirmation";
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
      const error = payload?.response?.data;
      if (error?.code === REGISTER_ERROR_CODE.CARD_DISABLED) {
        state.resetScreen = "frozen";
        state.frozenType = "recovery";
      }
    });
    builder.addCase(checkPassword.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(checkPassword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.resetScreen = "cvv";
      state.restoreToken = payload as string;
    });
    builder.addCase(checkPassword.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    });
    builder.addCase(checkCvv.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(checkCvv.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.resetScreen = "newPassword";
      state.restoreToken = payload as string;
    });
    builder.addCase(checkCvv.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response?.data || undefined;
    });
    builder.addCase(confirmResetPassword.pending, (state) => {
      state.isLoading = true;
      if (state.error) {
        state.error = undefined;
      }
    });
    builder.addCase(confirmResetPassword.fulfilled, (state) => {
      state.isLoading = false;
      state.resetScreen = "success";
    });
    builder.addCase(confirmResetPassword.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    });
    builder.addCase(regBiometryToken.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(regBiometryToken.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(regBiometryToken.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBiometryCredentials.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBiometryCredentials.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getBiometryCredentials.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(biometryRegConfirm.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(biometryRegConfirm.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(biometryRegConfirm.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(loginError.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginError.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(loginError.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createPin.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPin.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(loginPin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      loginPin.fulfilled,
      (state, { payload }: PayloadAction<Auth>) => {
        state.isLoading = false;
        state.isAuthenticated = !!payload?.accessToken;
        state.accessToken = payload?.accessToken;
      }
    );
    builder.addCase(loginPin.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.accessToken = undefined;
      state.error = payload?.response?.data || undefined;
    });
    builder.addCase(biometryAuth.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(biometryAuth.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.error = payload?.response?.data || undefined;
    });
    builder.addCase(biometryAuthConfirm.pending, (state) => {
      state.isLoading = true;
      state.accessToken = undefined;
    });
    builder.addCase(
      biometryAuthConfirm.fulfilled,
      (state, { payload }: PayloadAction<Auth>) => {
        state.isLoading = false;
        state.isAuthenticated = !!payload?.accessToken;
        state.accessToken = payload?.accessToken;
      }
    );
    builder.addCase(biometryAuthConfirm.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.error = payload?.response?.data || undefined;
    });
    builder.addCase(verifyPinCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyPinCode.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(verifyPinCode.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      if (payload.response.status === 403 && state.accessToken) {
        state.accessToken = undefined;
      }
    });
    builder.addCase(updatePinCode.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updatePinCode.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updatePinCode.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createLogin.fulfilled, (state) => {
      state.isLoading = false;
      state.createAccountStep = "password";
    });
    builder.addCase(createLogin.rejected, (state, action) => {
      const payload = action.payload as IFourHundredErr;
      state.isLoading = false;
      state.error = payload?.response
        ? { ...payload.response.data, status: payload.response.status }
        : undefined;
    });
  },
});

export default authSlice;
