import { Box } from "@mui/material";
import { NotificationResult } from "molecules/NotificationResult/NotificationResult";
import { ReactComponent as IconUnSuccess } from "./assets/unsuccess.svg";
import { useIsMobile } from "hooks/useIsMobile";
import { Button } from "components/Button";
import theme from "theme";

const TITLE = "Сервис пополнения \n с другой карты временно недоступен";
const SUBTITLE = "Мы уже работаем над этой проблемой. Попробуйте позже.";

interface RefillErrorScreenProps {
  onClose: () => void;
}

export const RefillErrorScreen: React.FC<RefillErrorScreenProps> = ({
  onClose,
}) => {
  const { isMobile } = useIsMobile();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      height="100%"
      sx={{
        [theme.breakpoints.down("md")]: {
          mb: 100,
        },
      }}
    >
      <Box marginTop="60px" height="100%" width="100%">
        <NotificationResult
          title={TITLE}
          subtitle={SUBTITLE}
          titleStyle={{
            fontSize: "20px",
            lineHeight: "24px",
            dipslay: "inline-block",
            textAlign: "center",
          }}
          subtitleStyle={{ fontWeight: 400 }}
          icon={<IconUnSuccess />}
          height="100%"
          boxPadding="0px"
          boxWidth="100%"
        />
      </Box>

      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            bottom: 36,
            width: "calc(100% - 32px)",
          }}
        >
          <Button variant="primary" onClick={onClose} title="Понятно" />
        </Box>
      )}
    </Box>
  );
};
