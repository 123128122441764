import bankDefoult from "./../images/bank.svg";
import { Box, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "../store";
import { formatCurrency } from "utils/formatCurrency";
import { FileType, downloadFile } from "utils/downloadFile";
import { format } from "date-fns";
import { ru } from "date-fns/locale";
import { utcToZonedTime } from "date-fns-tz";
import { IconCardTranferCheck } from "atoms/IconCardTranferCheck";
import { ClickableHeader } from "pages/home/Cards/Transfers/ClickableHeader";
import { Button } from "./Button";
import { AnotherPages, TabVariants } from "store/slices/system/slice";
import { systemActions } from "store/slices/system";
import {
  getPaymentReport,
  setIsOpenModal,
  setTransferClientBankIcon,
} from "../store/slices/transferSlice";
import { TransferConfirmedResponse } from "api/account";
import { setAllCards, updateMainCard } from "store/slices/cardsSlice";
import { IconDownloadCheck } from "atoms/IconDownloadCheck";
import { IconRefreshReverse } from "./Icons/IconRefreshReverse";

export const OperationCheck = ({
  confirmedTransfer,
}: {
  confirmedTransfer: TransferConfirmedResponse;
}) => {
  const dispatch = useDispatch();
  const {
    transfer: { isCheckLoading, isOpenModal, transferClientBankIcon },
    cards: {
      fromTransferCard: { cardPan: fromCardPan },
      allCards,
      chosenCardIndex,
    },
  } = useSelector((state) => state);

  const onClose = () => {
    const newAllCards = allCards.map((card, index) => {
      if (index === chosenCardIndex) {
        return {
          ...card,
          amount:
            (card.amount as number) - (confirmedTransfer.amount as number),
        };
      } else {
        return { ...card };
      }
    });
    dispatch(setAllCards(newAllCards));
    dispatch(setIsOpenModal(!isOpenModal));
  };

  const goToHandler = (tab: AnotherPages | TabVariants) => {
    dispatch(setIsOpenModal(!isOpenModal));
    dispatch(
      systemActions.setActiveTab({
        tab,
      })
    );
  };

  const { toClientName, toCardPan, toClientBank, amount, dateTime, reason } =
    confirmedTransfer;

  useEffect(() => {
    return () => {
      dispatch(setIsOpenModal(!isOpenModal));
      dispatch(setTransferClientBankIcon(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmedTransferDetails = useMemo(
    () => [
      { title: "Карта списания", value: fromCardPan },
      {
        title: "Получатель",
        value: toClientName || "не указано",
      },
      { title: "Номер карты получателя", value: toCardPan },
      { title: "Банк получателя", value: toClientBank },
      { title: "Сумма перевода", value: amount },
      { title: "Дата и время", value: dateTime },
      { title: "Сообщение", value: reason },
    ],
    [
      amount,
      dateTime,
      fromCardPan,
      reason,
      toCardPan,
      toClientBank,
      toClientName,
    ]
  );

  const downloadCheck = () => {
    if (confirmedTransfer?.paymentId) {
      dispatch(getPaymentReport(confirmedTransfer?.paymentId))
        .unwrap()
        .then((res) => {
          downloadFile(res, "check.pdf", FileType.PDF);
        });
    }
  };

  const formatValue = (title, value) => {
    switch (title) {
      case "Дата и время": {
        const zonedDate = utcToZonedTime(value, "Europe/Moscow");
        return format(zonedDate, "d MMMM yyyy, HH:mm", { locale: ru });
      }
      case "Сумма перевода":
      case "Комиссия":
        return formatCurrency(value, true);
      default:
        return value;
    }
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ rowGap: "16px" }}>
      <ClickableHeader
        onBack={() => goToHandler(TabVariants.transfersHub)}
        title={null}
      />

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          padding: "28px 10px",
          backgroundColor: "var(--main-color-bg-widgets)",
          borderRadius: "9px",
          rowGap: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexShrink: 0,
            width: "48px",
            height: "48px",
            borderRadius: "1000px",
            backgroundColor: "#FFFFFF",
            backgroundImage: `url(${transferClientBankIcon || bankDefoult})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: 500,
            lineHeight: "36px",
            color: "#2D3227",
          }}
        >
          - {formatValue("Сумма перевода", amount)}
        </Typography>
        <Typography
          sx={{
            fontSize: "17px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#739B67",
            textAlign: "center",
          }}
        >
          <IconCardTranferCheck />
          Перевод по номеру карты
        </Typography>
      </Box>
      <Box display="flex" width="100%" gap={12}>
        <Button
          variant="secondary"
          startIcon={<IconRefreshReverse />}
          onClick={onClose}
          title="Повторить"
        />
        <Button
          variant="secondary"
          startIcon={!isCheckLoading && <IconDownloadCheck />}
          onClick={downloadCheck}
          isLoading={isCheckLoading}
          title="Справка"
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", rowGap: "24px" }}>
        {confirmedTransferDetails.map(({ title, value }, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "14px",
                color: "var(--main-color-text-secondary)",
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "20px",
                color: "#2D3227",
              }}
            >
              {formatValue(title, value)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
