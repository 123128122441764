import { Loader } from "components/Loader/Loader";
import { useCallback, useMemo, useState } from "react";
import {
  createRefill,
  deleteSavedCard,
  setIsNewCard,
  setSavedCards,
} from "store/slices/transferSlice";
import { useDispatch, useSelector } from "store";
import { CardIcon } from "./assets/CardIcon";
import { ItemCard } from "./ItemCard";
import { CARD_STATUS } from "components/CardsInfo/model";
import { AllCardsWrapper, ItemsWrapper, SmallTitle } from "./components";
import { Button } from "components/Button";
import { CardDetails } from "components/CardDetails/CardDetails";
import { useIsMobile } from "hooks/useIsMobile";
import { CardsBottomSheet } from "components/CardsBottomSheet/CardsBottomSheet";
import { CardDto, CardStatus } from "api/account";
import { WarningAlert } from "components/WarningAlert/WarningAlert";
import { Box } from "@mui/system";
import { useOutsideClick } from "hooks/useOutsideClick";
import { IconCirclePlus } from "atoms/IconCirclePlus/IconCirclePlus";
import { Stack } from "@mui/material";
import { LoaderWrapper } from "organisms/RefillInfo/components";
import { CardSelectModal } from "components/CardSelectModal/CardSelectModal";
import theme from "theme";

const CARD_COLORS = [
  {
    background: "#D9E3CC",
    color: "#739B67",
  },
  {
    background: "#FFDEDB",
    color: "var(--error-color-icon)",
  },
  {
    background: "var(--brand-color-hover)",
    color: "var(--brand-color-primary)",
  },
  {
    background: "#E5DFFF",
    color: "var(--secondary-color-4)",
  },
  {
    background: "#F8F8D3",
    color: "#F9C81C",
  },
  {
    background: "#FFD8BC",
    color: "#F98166",
  },
];

const primaryButtonStyles = { marginTop: 15 };

interface RefillBlockProps {
  onClose: () => void;
}

export const RefillBlock: React.FC<RefillBlockProps> = ({ onClose }) => {
  const { isMobile } = useIsMobile();
  const {
    cards: { chosenCardIndex, allCards },
    transfer: {
      isSavedCardsLoading,
      savedCards,
      isNewCard,
      isCreateRefillLoading,
    },
  } = useSelector((state) => state);

  const isSelectedCard = useMemo(
    () => savedCards.filter((card) => card.active).length,
    [savedCards]
  );

  const anyActiveCard = allCards.find(
    (item) => item.status === CardStatus.Active
  );

  const isDisabledButton =
    (!isNewCard && !isSelectedCard) || isSavedCardsLoading || !anyActiveCard;
  const displayCards = useMemo(
    () => allCards.filter((card) => card.status === CARD_STATUS.Active),
    [allCards]
  );

  const chosenCard =
    chosenCardIndex && allCards[chosenCardIndex].status === CARD_STATUS.Active
      ? allCards[chosenCardIndex]
      : displayCards[0];

  const [activeCard, setActiveCard] = useState(chosenCard);
  const [showCards, setShowCards] = useState(false);

  const dispatch = useDispatch();

  const createRefillRequest = () => {
    dispatch(
      createRefill({
        receiverCard: activeCard,
        savedSenderCardId: isNewCard
          ? null
          : savedCards.filter((card) => card.active)[0].id,
      })
    );
  };

  const onCardClick = (card: CardDto) => {
    setActiveCard(card);
    setShowCards(false);
  };

  const showCardsHandler = useCallback(() => {
    setShowCards((prevState) => !prevState);
  }, []);

  const card =
    activeCard?.status === CardStatus.Active ? activeCard : anyActiveCard;

  const divFocus = useOutsideClick(() => setShowCards(false));

  if (isSavedCardsLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Box
      sx={{
        [theme.breakpoints.down("md")]: {
          mb: 170,
        },
      }}
    >
      {!card && !isCreateRefillLoading && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            mb: 10,
          }}
        >
          <WarningAlert title="У вас нет карт доступных для пополнения" />
        </Box>
      )}
      <SmallTitle>C карты</SmallTitle>
      <ItemsWrapper>
        {savedCards.map((card) => (
          <ItemCard
            key={card.id}
            checked={card.active}
            title="МИР"
            cardNumber={card.pan?.slice(-4)}
            onChange={() => dispatch(setSavedCards(card.id))}
            onDelete={() => dispatch(deleteSavedCard(card.id))}
            background={CARD_COLORS[0].background}
            icon={<CardIcon color={CARD_COLORS[0].color} />}
          />
        ))}
        <ItemCard
          checked={isNewCard}
          title="Новая карта"
          onChange={() => dispatch(setIsNewCard())}
          background="#fff"
          icon={<IconCirclePlus />}
          hideMenu
          fullWidth={!savedCards.length}
        />
      </ItemsWrapper>
      <SmallTitle>На карту</SmallTitle>
      <AllCardsWrapper ref={divFocus}>
        <CardDetails
          card={card}
          hasMultipleCards={allCards.length > 1}
          onClick={showCardsHandler}
        />

        {allCards.length > 1 && showCards && (
          <>
            {isMobile && (
              <CardsBottomSheet
                isOpen={showCards}
                cards={allCards}
                onClick={onCardClick}
                onClose={() => setShowCards(false)}
              />
            )}
            {!isMobile && (
              <CardSelectModal
                cards={allCards}
                mainCard={activeCard}
                onClick={onCardClick}
                isOpen={showCards}
              />
            )}
          </>
        )}
      </AllCardsWrapper>

      <Stack
        spacing={10}
        sx={{
          [theme.breakpoints.down("md")]: {
            position: "fixed",
            bottom: 36,
            width: "calc(100% - 24px)",
          },
        }}
      >
        <Button
          onClick={createRefillRequest}
          variant="primary"
          style={{ ...primaryButtonStyles, marginTop: "28px" }}
          disabled={isDisabledButton}
          isLoading={isCreateRefillLoading}
          title="Продолжить"
        />

        {isMobile && (
          <Button variant="secondary" onClick={onClose} title="Отмена" />
        )}
      </Stack>
    </Box>
  );
};
