import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { setChosenCardIndex, setMainCard } from "store/slices/cardsSlice";
import { useDispatch, useSelector } from "store";
import { CardDto, CardStatus } from "api/account";
import { CardDetails } from "components/CardDetails/CardDetails";
import { CardsBottomSheet } from "components/CardsBottomSheet/CardsBottomSheet";
import { useOutsideClick } from "hooks/useOutsideClick";
import { CardSelectModal } from "components/CardSelectModal/CardSelectModal";

interface Props {
  activeCard?: CardDto;
  hideTitle?: boolean;
}

export const CardSelect = ({ hideTitle, activeCard }: Props) => {
  const { isMobile } = useIsMobile();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mainCard, allCards } = useSelector((state) => state.cards);

  const { firstActiveCard, firstActiveCardIndex } = useMemo(() => {
    const index = allCards.findIndex(
      ({ status }) => status === CardStatus.Active
    );
    return {
      firstActiveCard: allCards[index],
      firstActiveCardIndex: index,
    };
  }, [allCards]);

  const switchCard = useCallback(
    (card: CardDto, index: number) => {
      dispatch(setChosenCardIndex(index));
      dispatch(setMainCard(card));
      setIsModalOpen(false);
    },
    [dispatch]
  );

  const activeCardIndex = useMemo(
    () => allCards.findIndex((card) => card?.cardId === activeCard?.cardId),
    [allCards, activeCard]
  );

  useLayoutEffect(() => {
    if (activeCard) {
      const isActive = activeCard?.status === CardStatus.Active;
      switchCard(
        isActive ? activeCard : firstActiveCard,
        isActive ? activeCardIndex : firstActiveCardIndex
      );
    } else if (mainCard?.status !== CardStatus.Active) {
      switchCard(firstActiveCard, firstActiveCardIndex);
    }
  }, [
    activeCard,
    activeCardIndex,
    firstActiveCard,
    firstActiveCardIndex,
    mainCard?.status,
    switchCard,
  ]);

  const currentCard =
    mainCard?.status === CardStatus.Active ? mainCard : activeCard;
  const divFocus = useOutsideClick(() => setIsModalOpen(false));

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        color: "red",
        rowGap: "8px",
      }}
    >
      {!hideTitle && (
        <Typography
          fontWeight={400}
          sx={{
            fontSize: "16px",
            lineHeight: "20px",
            color: "var(--main-color-text-title)",
          }}
        >
          С карты
        </Typography>
      )}
      <Box sx={{ position: "relative" }} ref={divFocus}>
        <CardDetails
          card={currentCard}
          hasMultipleCards={allCards.length > 1}
          onClick={() => setIsModalOpen((prev) => !prev)}
        />
        {allCards.length > 1 &&
          (isMobile ? (
            <CardsBottomSheet
              isOpen={isModalOpen}
              cards={allCards}
              onClick={switchCard}
              onClose={() => setIsModalOpen(false)}
            />
          ) : (
            <CardSelectModal
              cards={allCards}
              mainCard={currentCard as CardDto}
              onClick={switchCard}
              isOpen={isModalOpen}
            />
          ))}
      </Box>
    </Box>
  );
};
