import { useEffect, useState, useRef } from "react";

/**
 * Хук `useKeyboardVisibility` отслеживает изменение высоты окна (`window.innerHeight`)
 * или переданного контейнера и определяет, открыта ли экранная клавиатура.
 *
 * 🔹 **Как это работает?**
 * - Если передан контейнер (`containerRef`), использует `ResizeObserver`.
 * - Если контейнер не передан, отслеживает `window.innerHeight` через `resize`-событие.
 * - При первом рендере запоминает начальную высоту (`initialHeight`).
 * - Если высота элемента уменьшается — клавиатура считается открытой.
 * - Если высота возвращается к исходному значению — клавиатура закрыта.
 *
 * 🔹 **Использование:**
 * 1️⃣ Отслеживание клавиатуры глобально (`window`):
 * ```tsx
 * const isKeyboardVisible = useKeyboardVisibility();
 * ```
 *
 * 2️⃣ Отслеживание клавиатуры внутри определенного контейнера:
 * ```tsx
 * const containerRef = useRef<HTMLDivElement>(null);
 * const isKeyboardVisible = useKeyboardVisibility(containerRef);
 * ```
 *
 * @param {React.RefObject<HTMLElement>} [containerRef] - необязательная ссылка на контейнер (по умолчанию `window`)
 * @returns {boolean} `true`, если клавиатура открыта, иначе `false`
 */

export const useKeyboardVisibility = (
  containerRef?: React.RefObject<HTMLElement>
): boolean => {
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const initialHeight = useRef<number | null>(null);

  useEffect(() => {
    if (containerRef?.current) {
      const observer = new ResizeObserver(([entry]) => {
        const { height } = entry.contentRect;

        if (initialHeight.current === null) {
          initialHeight.current = height;
        }

        setKeyboardVisible(height < initialHeight.current);
      });

      observer.observe(containerRef.current);

      return () => observer.disconnect();
    } else {
      const handleResize = () => {
        if (initialHeight.current === null) {
          initialHeight.current = window.innerHeight;
        }

        setKeyboardVisible(window.innerHeight < initialHeight.current);
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [containerRef]);

  return isKeyboardVisible;
};
