import styled from "@emotion/styled/macro";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: rgba(90, 99, 87, 0.5);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 390px;
  padding: 32px;
  border-radius: 12px;
  background: #fff;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 550px) {
    padding: 20px 20px 28px 20px;
  }
`;

export const Title = styled.p`
  margin: 0px;
  margin-bottom: 12px;

  font-size: 24px;
  font-weight: 600;
  line-height: 28px;

  text-align: center;

  color: var(--main-color-text-title);
`;

export const Description = styled.p`
  margin: 0px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--main-color-text-secondary-2);
`;
