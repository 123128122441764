import ReplenishImg from "images/replenish.png";
import TransferImg from "images/transfer.png";
import { systemActions } from "store/slices/system";
import { AnotherPages } from "store/slices/system/slice";
import { IconArrow } from "atoms/IconArrowTransfer";
import { SHOW_REFILL } from "utils/constants";
import { setIsOpenModal } from "store/slices/transferSlice";
import {
  CardImg,
  IconWrapper,
  TransferBtn,
  TransferBtnContainer,
  TransferBtnText,
  TransferSubText,
} from "./components";
import { useDispatch } from "store";

export const TransfersHub = () => {
  const dispatch = useDispatch();

  const goToHandler = (tab: AnotherPages) => {
    dispatch(setIsOpenModal(false));
    dispatch(systemActions.setActiveTab({ tab }));
  };

  return (
    <>
      <TransferBtnContainer>
        <TransferBtn onClick={() => goToHandler(AnotherPages.transfers)}>
          <IconWrapper>
            <IconArrow rotate={45} />
          </IconWrapper>
          <CardImg src={TransferImg} />
          <TransferBtnText>По номеру карты</TransferBtnText>
          <TransferSubText>только для карт МИР</TransferSubText>
        </TransferBtn>

        {SHOW_REFILL && (
          <TransferBtn onClick={() => goToHandler(AnotherPages.refill)}>
            <IconWrapper>
              <IconArrow rotate={180} />
            </IconWrapper>
            <CardImg src={ReplenishImg} />
            <TransferBtnText>Пополнить</TransferBtnText>
          </TransferBtn>
        )}
      </TransferBtnContainer>
    </>
  );
};
