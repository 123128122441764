import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { paymentApi, refillApi, statementApi } from "../../api";
import {
  TransferCheckRequest,
  TransferRequest,
  TransferResponse,
  TransferConfirmRq,
  TransferConfirmedResponse,
  SavedCard,
  CardDto,
} from "api/account";
import { IRootState } from "../index";
import { IFourHundredErr } from "api/types";

export enum TRANSFER_ERROR_CODE {
  CARD = "TRANSFER_CARD_NOT_MATCH",
  MESSAGE = "TRANSFER_REASON_NOT_MATCH",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PAYMENT_RESPONSE_ERROR = "PAYMENT_RESPONSE_ERROR",
  AMOUNT_EXCEED_BALANCE = "AMOUNT_EXCEED_BALANCE",
  OTP_BLOCKED = "OTP_BLOCKED",
}

interface IErrorMessage {
  code: TRANSFER_ERROR_CODE | string;
  title: string;
  subtitle: string;
  comment: string;
}

interface ErrorMessagePayload {
  response: {
    data: IErrorMessage;
  };
}

interface CreateRefillResponse {
  sessionId: string;
  orderId: string;
  url: string;
}

interface SavedCards extends SavedCard {
  active: boolean;
}

enum TRANSFER_ACTION {
  INIT_FORM = "INIT_FORM",
  TRANSFER_CHECK = "TRANSFER_CHECK",
  TRANSFER_OTP = "TRANSFER_OTP",
  TRANSFER_CONFIRMED = "TRANSFER_CONFIRMED",
  TRANSFER_ERROR = "TRANSFER_ERROR",
}

export type TransferState = {
  isLoading: boolean;
  isCheckLoading: boolean;
  createdTransfer?: TransferResponse;
  isTransferChecked: boolean;
  confirmedTransfer?: TransferConfirmedResponse;
  errorComment?: string;
  isTransferConfirmed?: boolean;
  transferClientBankIcon: string | null;
  isConfirmLoading: boolean;
  createRefillError: null | string;
  refillError: null | string;
  refillUrl: null | string;
  refillId: null | string;
  errorMessage: IErrorMessage;
  isSavedCardsLoading: boolean;
  savedCards: Array<SavedCards>;
  isNewCard: boolean;
  isCreateRefillLoading: boolean;
  isOpenModal: boolean;
  screen: TRANSFER_ACTION;
};

const initialState: TransferState = {
  isLoading: false,
  isCheckLoading: false,
  isTransferChecked: false,
  errorComment: "",
  isTransferConfirmed: false,
  transferClientBankIcon: null,
  isConfirmLoading: false,
  refillError: null,
  createRefillError: null,
  refillUrl: null,
  refillId: null,
  errorMessage: {
    code: "",
    title: "",
    subtitle: "",
    comment: "",
  },
  isSavedCardsLoading: true,
  savedCards: [],
  isNewCard: false,
  isCreateRefillLoading: false,
  isOpenModal: false,
  screen: TRANSFER_ACTION.INIT_FORM,
};

export const createTransferToCard = createAsyncThunk(
  "createTransferToCard",
  async (payload: TransferRequest, { rejectWithValue }) => {
    try {
      const response = await paymentApi.createTransferToCard("string", payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const transferCheck = createAsyncThunk(
  "transferCheck",
  async (payload: TransferCheckRequest, { rejectWithValue }) => {
    try {
      const response = await paymentApi.transferCheck("string", payload);

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const confirmTransferToCard = createAsyncThunk(
  "confirmTransferToCard",
  async (payload: TransferConfirmRq, { rejectWithValue }) => {
    try {
      const response = await paymentApi.confirmTransferToCard(
        "string",
        payload
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPaymentReport = createAsyncThunk(
  "getPaymentReport",
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await statementApi.getPaymentReport("string", id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReport = createAsyncThunk(
  "getReport",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await statementApi.getReport("string", id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const repeatTransfer = createAsyncThunk(
  "repeatTransfer",
  async (id: string, { rejectWithValue, getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      const { data } = await paymentApi.repeatTransfer(accessToken || "", id);
      return data;
    } catch (e) {
      rejectWithValue(e);
    }
  }
);

export const getRefillInfo = createAsyncThunk(
  "getRefillInfo",
  async (id: string) => {
    const response = await refillApi.getOrderInfo(id);
    return response.data;
  }
);

export const createRefill = createAsyncThunk(
  "createRefill",
  async (
    payload: {
      receiverCard: CardDto;
      savedSenderCardId?: string | null;
    },
    { getState }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    const response = await refillApi.createRefillFromAnotherBank(
      accessToken || "",
      {
        receiverCardId: payload.receiverCard.cardId,
        // @ts-ignore
        savedSenderCardId: payload.savedSenderCardId,
        needRegCard: !payload.receiverCard.savedToRefill,
      }
    );

    return response.data;
  }
);

export const getSavedCards = createAsyncThunk(
  "getSavedCards",
  async (_, { getState }) => {
    const { accessToken } = (getState() as IRootState).auth;
    const response = await refillApi.getSavedSenderCards(accessToken || "");
    return response.data;
  }
);

export const deleteSavedCard = createAsyncThunk(
  "deleteSavedCard",
  async (id: string | undefined, { getState, dispatch }) => {
    const { accessToken } = (getState() as IRootState).auth;
    const response = await refillApi.deleteSavedCard(accessToken || "", {
      savedSenderCardId: id,
    });
    dispatch(getSavedCards());
    return response.data;
  }
);

export const getRefillStatus = createAsyncThunk(
  "getRefillStatus",
  async (
    payload: { sessionId: string; orderId: string },
    { rejectWithValue, getState }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    const { sessionId, orderId } = payload;
    try {
      const response = await refillApi.getRefillOrderStatus(
        accessToken || "",
        sessionId,
        orderId
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const TransferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    resetTransferErrorMessage: (state) => {
      state.errorMessage = {
        code: "",
        title: "",
        subtitle: "",
        comment: "",
      };
    },
    resetStore: (state) => {
      state = initialState;
    },
    resetStateTransfer: (state) => {
      state.isLoading = false;
      state.isTransferChecked = false;
      state.isTransferConfirmed = false;
      state.createdTransfer = undefined;
      state.confirmedTransfer = undefined;
    },
    clearErrorState: (state) => {
      state.errorComment = "";
    },
    resetConfirmedTransfer: (state) => {
      state.confirmedTransfer = undefined;
    },
    setSavedCards: (state, { payload }) => {
      if (state.isNewCard) state.isNewCard = false;
      state.savedCards = state.savedCards.map((card) => ({
        ...card,
        active: card.id === payload ? !card.active : false,
      }));
    },
    setIsNewCard: (state) => {
      state.savedCards = state.savedCards.map((card) => ({
        ...card,
        active: false,
      }));
      state.isNewCard = !state.isNewCard;
    },
    setIsCreateRefillLoading: (state, { payload }) => {
      state.isCreateRefillLoading = payload;
    },
    setIsOpenModal: (state, { payload }) => {
      state.isOpenModal = payload;
    },
    setTransferScreen: (state, { payload }: PayloadAction<TRANSFER_ACTION>) => {
      state.screen = payload;
    },
    setTransferClientBankIcon: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      state.transferClientBankIcon = payload;
    },
    setRefillId: (state, { payload }) => {
      state.refillId = payload;
      state.refillUrl = null;
    },
    clearRefillUrl: (state) => {
      state.refillUrl = null;
    },
    setErrorMessage: (state, { payload }: PayloadAction<IErrorMessage>) => {
      state.errorMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRefill.pending, (state) => {
      state.refillUrl = null;
      state.refillId = null;
      state.isCreateRefillLoading = true;
      state.createRefillError = null;
    });
    builder.addCase(createRefill.fulfilled, (state, action) => {
      state.isCreateRefillLoading = false;
      state.refillUrl = action.payload.url as string;
    });
    builder.addCase(createRefill.rejected, (state, action) => {
      state.isCreateRefillLoading = false;
      state.createRefillError = action.error.message as string;
    });

    builder.addCase(createTransferToCard.fulfilled, (state, action) => {
      state.createdTransfer = action.payload;
      state.isLoading = false;
    });
    builder.addCase(createTransferToCard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createTransferToCard.rejected, (state, action) => {
      const payload = action.payload as ErrorMessagePayload;
      const { code, comment, title, subtitle } = payload.response.data;
      const anotherCode =
        code !== TRANSFER_ERROR_CODE.CARD &&
        code !== TRANSFER_ERROR_CODE.MESSAGE;
      state.errorMessage = {
        code,
        title: anotherCode ? title : comment,
        subtitle: subtitle || "",
        comment: comment || "",
      };
      state.isLoading = false;
    });

    builder.addCase(transferCheck.fulfilled, (state) => {
      state.isTransferChecked = true;
      state.isLoading = false;
    });
    builder.addCase(transferCheck.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(transferCheck.rejected, (state, action) => {
      const {
        response: {
          data: { title, subtitle, code, comment },
        },
      } = action.payload as IFourHundredErr;

      state.errorMessage = {
        title,
        subtitle,
        code,
        comment,
      };

      state.isLoading = false;
    });

    builder.addCase(confirmTransferToCard.fulfilled, (state, action) => {
      state.confirmedTransfer = action.payload;
      state.isConfirmLoading = false;
      state.isTransferConfirmed = true;
    });
    builder.addCase(confirmTransferToCard.pending, (state) => {
      state.isConfirmLoading = true;
    });
    builder.addCase(confirmTransferToCard.rejected, (state, action) => {
      const payload = action.payload as ErrorMessagePayload;
      const { code, comment, title, subtitle } = payload.response.data;
      state.errorMessage = {
        code,
        title,
        subtitle,
        comment,
      };
      state.isConfirmLoading = false;
      // @ts-ignore
      state.errorComment = action.payload.response?.data.comment;
    });

    builder.addCase(getPaymentReport.fulfilled, (state) => {
      state.isCheckLoading = false;
    });
    builder.addCase(getPaymentReport.pending, (state) => {
      state.isCheckLoading = true;
    });
    builder.addCase(getPaymentReport.rejected, (state) => {
      state.isCheckLoading = false;
    });

    builder.addCase(getReport.fulfilled, (state) => {
      state.isCheckLoading = false;
    });
    builder.addCase(getReport.pending, (state) => {
      state.isCheckLoading = true;
    });
    builder.addCase(getReport.rejected, (state) => {
      state.isCheckLoading = false;
    });
    builder.addCase(getRefillStatus.rejected, (state, action) => {
      // @ts-ignore
      state.refillError = action.payload.response?.data.comment;
    });
    builder.addCase(getSavedCards.fulfilled, (state, { payload }) => {
      if (payload.cards && payload.cards.length) {
        state.savedCards = payload.cards.map((card) => ({
          ...card,
          active: false,
        }));
      } else {
        state.savedCards = [];
        state.isNewCard = true;
      }
      state.isSavedCardsLoading = false;
    });
    builder.addCase(getSavedCards.rejected, (state, action) => {
      state.isSavedCardsLoading = false;
      state.createRefillError = String(action.error?.message);
    });
    builder.addCase(getSavedCards.pending, (state) => {
      state.isSavedCardsLoading = true;
      state.createRefillError = null;
    });
  },
});

export const transferActions = TransferSlice.actions;
export const {
  resetStateTransfer,
  clearErrorState,
  resetConfirmedTransfer,
  resetTransferErrorMessage,
  setSavedCards,
  setIsNewCard,
  setIsCreateRefillLoading,
  setRefillId,
  setIsOpenModal,
  setTransferScreen,
  setTransferClientBankIcon,
  clearRefillUrl,
  setErrorMessage,
} = TransferSlice.actions;
export default TransferSlice.reducer;
