import styled from "@emotion/styled/macro";
interface Props {
  isMobile?: boolean;
  marginTop?: boolean;
  disabled?: boolean;
}

interface TabProps {
  active: boolean;
  width: string;
  isMobile?: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: ${(props: Props) => (props.marginTop ? "-10px" : "0px")};
  scroll-behavior: smooth;
  overflow: ${(props: Props) => (props.isMobile ? "visible" : "hidden")};
  overflow-x: ${(props: Props) => (props.isMobile ? "clip" : "scroll")};
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  min-width: 112px;
  padding: ${(props: TabProps) => (props.isMobile ? "12px 0" : "14px 16px")};
  width: ${(props: { width: string; active: boolean }) => props.width};
  background-color: ${(props: { active: boolean }) =>
    props.active ? "var(--main-color-bg-widgets)" : "var(--main-color-white)"};
  border-radius: 12px;

  @media (max-width: 375px) {
    min-width: unset;
    padding: 12px 16px;
  }

  @media (max-width: 320px) {
    padding: 12px 10px;
  }
`;

export const TabText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0em;
  font-weight: 400;
  color: ${(props: { active: boolean }) =>
    props.active
      ? "var(--main-color-text-title)"
      : "var(--main-color-text-secondary)"};
`;

export const DownloadIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 12px;
  background: var(--main-color-bg-widgets);
  cursor: pointer;
  opacity: ${(props: Props) => (props.disabled ? "0.6" : "1")};
  position: unset;
  top: 30px;
  right: 16px;

  &:hover {
    background-color: var(--brand-color-disabled);
  }

  @media (max-width: 550px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;
