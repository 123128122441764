interface MessagingData {
  createTime: number;
  subscriptionOptions: Object;
  token: string;
}

export const fetchAllDataFromIndexedDB = (): Promise<MessagingData[]> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open("firebase-messaging-database", 1);

    request.onsuccess = (event) => {
      const db = (event.target as IDBRequest).result as IDBDatabase;

      const transaction = db.transaction(
        "firebase-messaging-store",
        "readonly"
      );
      const store = transaction.objectStore("firebase-messaging-store");

      const getAllRequest = store.getAll();

      getAllRequest.onsuccess = (event) => {
        const result = (event.target as IDBRequest).result;
        resolve(result);
      };

      getAllRequest.onerror = (event) => {
        reject("Ошибка при получении данных");
      };
    };

    request.onerror = () => {
      reject("Ошибка при открытии базы данных");
    };
  });
};
