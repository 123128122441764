import { CardDto } from "api/account";
import {
  IconCreditBlocked,
  IconCreditCard,
  IconCreditCardClosed,
  IconCreditCardFrozen,
  IconVirtualCard,
} from "atoms/IconCards/IconCards";
import { CARD_STATUS } from "components/CardsInfo/model";

export const getBackgroundColor = (status: string) => {
  switch (status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
      return "var(--secondary-color-8)";
    case CARD_STATUS.Lost:
    case CARD_STATUS.Stolen:
    case CARD_STATUS.Compromised:
      return "var(--error-color-notification-light)";
    case CARD_STATUS.Closed:
      return "var(--main-color-bg-secondary)";
    default:
      return "var(--main-color-bg-widgets)";
  }
};

export const getCardIcon = (card: CardDto | undefined) => {
  if (!card) return <IconCreditCard color="#B5B5B5" />;

  if (card?.cardType === "VIRTUAL" && card?.status === CARD_STATUS.Active)
    return <IconVirtualCard />;

  switch (card?.status) {
    case CARD_STATUS.Temporary:
    case CARD_STATUS.Referral:
      return <IconCreditCardFrozen />;
    case CARD_STATUS.Lost:
    case CARD_STATUS.Stolen:
    case CARD_STATUS.Compromised:
      return <IconCreditBlocked />;
    case CARD_STATUS.Closed:
      return <IconCreditCardClosed />;
    default:
      return <IconCreditCard />;
  }
};
