import { useDispatch, useSelector } from "store";
import { useEffect, useCallback } from "react";
import { chatActions, getChatMessagesV2 } from "store/slices/chat";
import { INITIAL_CHAT_PAGE_SIZE } from "constants/chat";

const INITIAL_VALUES = {
  page: -1,
  size: INITIAL_CHAT_PAGE_SIZE,
};

export const useGetChatMessages = () => {
  const dispatch = useDispatch();
  const { latestPage } = useSelector((state) => state.chat);

  const fetchMessages = useCallback(
    async (page: number | undefined, size: number = INITIAL_VALUES.size) => {
      const result = await dispatch(getChatMessagesV2({ page, size })).unwrap();

      const { size: newSize, page: newPage } = result || {};

      if (newSize !== undefined)
        dispatch(chatActions.setInitSumMessages(newSize));

      return { page: newPage, size: newSize };
    },
    [dispatch]
  );

  const loadInitialMessages = useCallback(async () => {
    const { page = INITIAL_VALUES.page, size = INITIAL_VALUES.size } =
      await fetchMessages(undefined);

    if (size && size <= 20) {
      dispatch(chatActions.setLatestPage(page));
      dispatch(chatActions.setPaginationPage(page - 1));

      if (size < 15 && page > 0) {
        const prevPageResult = await fetchMessages(page - 1);
        if (prevPageResult.size !== undefined) {
          dispatch(chatActions.setInitSumMessages(prevPageResult.size));
        }
      }
    }
  }, [dispatch, fetchMessages]);

  const loadLatestMessages = useCallback(async () => {
    if (latestPage !== undefined && latestPage !== null) {
      await fetchMessages(latestPage);
    } else {
      await loadInitialMessages();
    }
  }, [latestPage, fetchMessages, loadInitialMessages]);

  useEffect(() => {
    loadLatestMessages();
  }, [loadLatestMessages]);
};
