import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { IconNoNotifications } from "atoms/IconNoNotifications/IconNoNotifications";

interface INoDataScreen {
  title: string;
}

export const NoDataScreen: FC<INoDataScreen> = ({ title }) => (
  <Box>
    <Box width="fit-content" margin="auto">
      <IconNoNotifications />
    </Box>
    <Box
      gap={8}
      mt={24}
      paddingX={30}
      display="flex"
      flexDirection="column"
      textAlign="center"
    >
      <Typography variant="text_6" fontWeight={500}>
        {title}
      </Typography>
      <Typography variant="text_3">
        Попробуйте обновить этот экран или обратитесь в службу поддержки
      </Typography>
    </Box>
  </Box>
);
