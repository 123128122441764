import { useSelector } from "store";
import { useEffect, useMemo, useState } from "react";
import PullToRefresh, { PullToRefreshInstance } from "pulltorefreshjs";
import { useLocation } from "react-router-dom";

export const usePullToRefresh = () => {
  const { activeTab } = useSelector((state) => state.system);
  const location = useLocation();
  const isTouchBelow = useTouchPosition();

  const isChatTab = useMemo(() => activeTab === "chat", [activeTab]);
  const isHomePage = location.pathname === "/";

  useEffect(() => {
    let instance: PullToRefreshInstance | null = null;
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    if (isIOS && !isChatTab && isHomePage && !isTouchBelow) {
      instance = PullToRefresh.init({
        mainElement: "body",
        onRefresh() {
          window.location.reload();
        },
        iconArrow: `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"/><polyline points="19 12 12 19 5 12"/></svg>`,
        iconRefreshing: `
          <div style="
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 24px;
          ">
            <div style="
              width: 20px;
              height: 20px;
              border: 2px solid rgba(0, 0, 0, 0.2);
              border-top-color: rgba(0, 0, 0, 0.8);
              border-radius: 50%;
              animation: spin 1s linear infinite;
            "></div>
          </div>
          <style>
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          </style>
        `,
        instructionsPullToRefresh: "Потяните вниз, чтобы обновить",
        instructionsReleaseToRefresh: "Отпустите, чтобы обновить",
        instructionsRefreshing: "Обновляется...",
        refreshTimeout: 100,
      });
    }

    return () => {
      if (instance) {
        instance.destroy();
      }
    };
  }, [isChatTab, isHomePage, isTouchBelow]);
};

const useTouchPosition = () => {
  const [isTouchBelowScreen, setIsTouchBelowScreen] = useState(false);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const getBodyScrollTop = () =>
      document.body.scrollTop || document.documentElement.scrollTop;

    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    const handleTouchStart = (e: TouchEvent) => {
      const scrollTop = getBodyScrollTop();
      const touchY = e.touches[0].clientY + scrollTop;
      setIsTouchBelowScreen(touchY > screenHeight - 200);
    };

    window.addEventListener("resize", handleResize);
    document.body.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.removeEventListener("touchstart", handleTouchStart);
    };
  }, [screenHeight]);

  return isTouchBelowScreen;
};
