import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RedirectState {
  redirectUrl: string | null;
}

const initialRedirectState: RedirectState = {
  redirectUrl: null,
};

const redirectSlice = createSlice({
  name: "redirect",
  initialState: initialRedirectState,
  reducers: {
    setRedirectUrl: (state, action: PayloadAction<RedirectState>) => {
      state.redirectUrl = action.payload.redirectUrl;
    },
  },
});

export default redirectSlice;
