import { ChatMessage, ChatMessageDto } from "api/notification";

export const getSortedMessages = (messages: Record<string, ChatMessage[]>) => {
  if (!Object.entries(messages).length || messages === null) return [];

  return Object.entries(messages).sort(([d1], [d2]) => {
    const date1 = d1.split(".").reverse().join("-");
    const date2 = d2.split(".").reverse().join("-");
    return new Date(date1).getTime() - new Date(date2).getTime();
  });
};
