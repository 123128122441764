import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  authApi,
  otpApi,
  registrationApi,
  countryApi,
  authWebApi,
  resetApi,
  registrationWEBApi,
  authBiometryWebApi,
  pinChange,
} from "api";
import {
  PMPLoginRequest,
  RegistrationRequest,
  PMPRegisterConfirmRequest,
  PasswordResetRequest,
  PMPLoginCreatePinWebRequest,
  RegistrationWebAuthnConfirmRequest,
  AuthorizationWebAuthnRequest,
  AuthorizationWebAuthnConfirmRequest,
  AddEnterRecordRequest,
} from "api/auth";
import { IRootState } from "store";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { systemActions } from "../system";
import DeviceDetector from "device-detector-js";
import bcryptjs from "bcryptjs-react";
import { CheckBlockType } from "components/CheckBlock/CheckBlock";

const timeZone = `${Intl.DateTimeFormat().resolvedOptions().timeZone}`;

interface PMPLoginRequestWithPWA extends PMPLoginRequest {
  isPWA: boolean;
}

export const login = createAsyncThunk(
  "login",
  async (
    { login, password, isPWA }: PMPLoginRequestWithPWA,
    { rejectWithValue }
  ) => {
    const getDeviceId = async () => {
      const generateUUID = () => {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
          /[xy]/g,
          function (c) {
            const r = (Math.random() * 16) | 0,
              v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
          }
        );
      };
      let deviceId: string | null;
      if (isPWA) {
        deviceId = localStorage.getItem("PWADeviceId");
        if (deviceId && deviceId !== null) {
          return deviceId;
        } else {
          deviceId = generateUUID();
          localStorage.setItem("PWADeviceId", deviceId);
          return deviceId;
        }
      } else {
        deviceId = localStorage.getItem("deviceId");
        if (deviceId && deviceId !== null) {
          return deviceId;
        } else {
          const fpPromise = FingerprintJS.load();
          const fp = await fpPromise;
          const result = await fp.get();
          localStorage.setItem("deviceId", result.visitorId);
          return result.visitorId;
        }
      }
    };
    try {
      const deviceId = await getDeviceId();
      const response = await authApi.loginRequest({
        login,
        password,
        device: {
          deviceId: deviceId || "",
        },
      });
      return response.data.loginToken;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loginConfirm = createAsyncThunk(
  "loginConfirm",
  async (
    { pinCode, isPWA }: { pinCode: string; isPWA: boolean },
    { rejectWithValue, getState }
  ) => {
    const { loginToken } = (getState() as IRootState).auth;
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    try {
      const response = await authWebApi.loginConfirmWeb({
        pinCode,
        loginToken,
        timeZone,
        // @ts-ignore
        device: {
          deviceId:
            (isPWA
              ? localStorage.getItem("PWADeviceId")
              : localStorage.getItem("deviceId")) || "",
          manufacturer: device.device?.brand || "—",
          model: device.device?.model || "—",
          deviceTypeFull: device.device?.type,
          deviceType: "W",
          systemName: device.os?.name,
          systemVersion:
            device.os?.version || `${device.os?.name} ${device.os?.platform}`,
          deviceName: device.client?.name,
          version: device.client?.version,
        },
      });
      sessionStorage.setItem("isAuthenticated", "true");
      if (response?.data?.accessToken) {
        sessionStorage.setItem("accessToken", response?.data?.accessToken);
      }

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const logout = createAsyncThunk(
  "logout",
  async (isPWA: boolean, { rejectWithValue, getState, dispatch }) => {
    const { accessToken } = (getState() as IRootState).auth;
    const tokenFromStorage = sessionStorage.getItem("accessToken");
    const token = accessToken || tokenFromStorage;

    try {
      if (token) {
        const response = await authApi.logout(token, {
          deviceId:
            (isPWA
              ? localStorage.getItem("PWADeviceId")
              : localStorage.getItem("deviceId")) || "",
        });
        return response.data;
      } else {
        return true;
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const retryOneTimePassword = createAsyncThunk(
  "retryOneTimePassword",
  async (token: string, { rejectWithValue }) => {
    try {
      const { data } = await otpApi.retryOneTimePassword({ token });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Registration

export const fetchCountriesList = createAsyncThunk(
  "fetchCountriesList",
  async (_, { rejectWithValue }) => {
    try {
      const response = await countryApi.getAvailableCountries();
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const register = createAsyncThunk(
  "register",
  async (payload: RegistrationRequest, { rejectWithValue }) => {
    try {
      const response = await registrationApi.registerV3(payload);
      return response.data.registerToken;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerCheck = createAsyncThunk(
  "registerCheck",
  async (verifyCode: string, { rejectWithValue, getState }) => {
    const { registerToken } = (getState() as IRootState).auth;
    try {
      const response = await registrationApi.registerCheckV3({
        registerToken,
        verifyCode,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerCVV = createAsyncThunk(
  "registerCVV",
  async (cvv: string, { rejectWithValue, getState }) => {
    const { registerToken } = (getState() as IRootState).auth;
    try {
      const response = await registrationApi.registerCvvV3({
        registerToken,
        cvv,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const registerConfirm = createAsyncThunk(
  "registerConfirm",
  async (
    payload: Pick<PMPRegisterConfirmRequest, CheckBlockType>,
    { rejectWithValue, getState }
  ) => {
    const { registerToken } = (getState() as IRootState).auth;
    try {
      const response = await registrationApi.registerConfirmV3({
        registerToken,
        timeZone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
        ...payload,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

// Recovery Password

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload: PasswordResetRequest, { rejectWithValue }) => {
    try {
      const response = await resetApi.resetPassword(payload);
      return response.data.resetToken;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkPassword = createAsyncThunk(
  "checkPassword",
  async (verifyCode: string, { rejectWithValue, getState }) => {
    const { resetToken: passwordResetToken } = (getState() as IRootState).auth;
    try {
      const response = await resetApi.checkPassword({
        passwordResetToken,
        verifyCode,
      });
      return response.data.restoreToken;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const confirmResetPassword = createAsyncThunk(
  "confirmResetPassword",
  async (password: string, { rejectWithValue, getState }) => {
    const { restoreToken } = (getState() as IRootState).auth;
    try {
      const response = await resetApi.confirmPassword({
        passwordResetToken: restoreToken,
        password,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkCvv = createAsyncThunk(
  "checkCvv",
  async (cvv: string, { rejectWithValue, getState }) => {
    const { restoreToken: passwordResetToken } = (getState() as IRootState)
      .auth;
    try {
      const response = await resetApi.checkCvv({
        passwordResetToken,
        cvv,
      });
      return response.data.restoreToken;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createPin = createAsyncThunk(
  "createPin",
  async (
    payload: PMPLoginCreatePinWebRequest,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { accessToken } = (getState() as IRootState).auth;
    try {
      if (accessToken) {
        await authWebApi.createPin(accessToken, payload);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const checkIsBiometryKeyExist = createAsyncThunk(
  "checkPin",
  async (_, { rejectWithValue }) => {
    const login = localStorage.getItem("login");
    try {
      const { data } = await authBiometryWebApi.loginCheckWebAuthn({
        identityId: login ?? undefined,
        deviceId: localStorage.getItem("PWADeviceId") || "",
      });

      return data.webAuthnAllowed;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyPinCode = createAsyncThunk(
  "verifyPinCode",
  async (pinCode: string, { rejectWithValue, getState, dispatch }) => {
    const hashSalt = bcryptjs.genSaltSync(12);
    const hashedPin = await bcryptjs.hash(pinCode, hashSalt);
    try {
      const response = await pinChange.verifyPin("", {
        pinCode: hashedPin,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePinCode = createAsyncThunk(
  "updatePinCode",
  async (
    params: {
      verifyPinToken: string;
      newPinCode: string;
      repeatNewPinCode: string;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      await pinChange.changePin("", params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loginPin = createAsyncThunk(
  "loginPin",
  async (
    { pinCode, isPWA }: { pinCode: string; isPWA: boolean },
    { rejectWithValue }
  ) => {
    const deviceDetector = new DeviceDetector();
    const userAgent = window.navigator.userAgent;
    const device = deviceDetector.parse(userAgent);
    const hashSalt = bcryptjs.genSaltSync(12);
    const hashedPin = await bcryptjs.hash(pinCode, hashSalt);
    try {
      const response = await authWebApi.loginPinWeb({
        pinCode: hashedPin,
        timeZone,
        device: {
          deviceId:
            (isPWA
              ? localStorage.getItem("PWADeviceId")
              : localStorage.getItem("deviceId")) || "",
          manufacturer: device.device?.brand || "—",
          model: device.device?.model || "—",
          deviceTypeFull: device.device?.type,
          deviceType: "W",
          systemName: device.os?.name,
          systemVersion:
            device.os?.version || `${device.os?.name} ${device.os?.platform}`,
          deviceName: device.client?.name,
          version: device.client?.version,
        },
      });

      sessionStorage.setItem("isAuthenticated", "true");
      if (response?.data?.accessToken) {
        sessionStorage.setItem("accessToken", response?.data?.accessToken);
      }
      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const regBiometryToken = createAsyncThunk(
  "regBiometryToken",
  async (isPWA: boolean, { rejectWithValue }) => {
    try {
      const response = await registrationWEBApi.registrationToken("", {
        //@ts-ignore
        deviceId:
          (isPWA
            ? localStorage.getItem("PWADeviceId")
            : localStorage.getItem("deviceId")) || "",
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getBiometryCredentials = createAsyncThunk(
  "getBiometryCredentials",
  async (registrationAddToken: string, { rejectWithValue }) => {
    try {
      const response = await registrationWEBApi.registration("", {
        registrationAddToken,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const biometryRegConfirm = createAsyncThunk(
  "biometryRegConfirm",
  async (params: RegistrationWebAuthnConfirmRequest, { rejectWithValue }) => {
    try {
      const response = await registrationWEBApi.registrationConfirm("", params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const biometryAuth = createAsyncThunk(
  "biometryAuth",
  async (params: AuthorizationWebAuthnRequest, { rejectWithValue }) => {
    try {
      const response = await authBiometryWebApi.loginWebAuthn(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const biometryAuthConfirm = createAsyncThunk(
  "biometryAuthConfirm",
  async (params: AuthorizationWebAuthnConfirmRequest, { rejectWithValue }) => {
    try {
      const response = await authBiometryWebApi.loginConfirmWebAuthn(params);
      sessionStorage.setItem("isAuthenticated", "true");
      if (response?.data?.accessToken) {
        sessionStorage.setItem("accessToken", response?.data?.accessToken);
      }
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loginError = createAsyncThunk(
  "loginError",
  async (params: AddEnterRecordRequest, { rejectWithValue }) => {
    try {
      const response = await authApi.addEnterRecord(params);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const createLogin = createAsyncThunk(
  "createLogin",
  async (login: string, { rejectWithValue, getState }) => {
    const { registerToken } = (getState() as IRootState).auth;
    try {
      const response = await registrationApi.registerCheckLoginV3({
        registerToken,
        login,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
