import { Box, Typography, useMediaQuery } from "@mui/material";
import { isEmpty } from "lodash";
import { useCallback, useEffect } from "react";
import { IconClose } from "atoms/IconClose/IconClose";
import { ErrorFullScreen } from "components/ErrorFullScreen";
import { sectionTitles } from "constants/sectionTitles";
import { useIsMobile } from "hooks/useIsMobile";
import { Modal } from "molecules/Modal/Modal";
import { NewsInfo } from "organisms/Notifications/NewsInfo";
import { Other } from "organisms/Other/Other";
import { useDispatch, useSelector } from "store";
import {
  CARD_ACTION,
  getCardInfo,
  setCardError,
  setIsCardInfoOpen,
  setScreen,
  setStatement,
} from "store/slices/cardsSlice";
import {
  checkNotifications,
  notificationActions,
} from "store/slices/notifications";
import { profileActions } from "store/slices/profile";
import { getUserInfo } from "store/slices/profile/asyncThunks";
import {
  MobileTabVariants,
  TabVariants,
  systemActions,
} from "store/slices/system";
import { AllTabVariants, AnotherPages } from "store/slices/system/slice";
import { TRANSFER_ERROR_CODE } from "store/slices/transferSlice";
import { Profile } from "../profile";
import { Cards } from "./Cards/Cards";
import { Contacts } from "organisms/Other/components/Contacts";
import { ChatScreen } from "organisms/ChatScreen/ChatScreen";
import { setIsOpenVirtualCard } from "store/slices/virtualCardSlice";
import { VirtualCard } from "components/VirtualCard";
import { CardModalWrapper } from "components/CardModalWrapper";
import { IconAccessBiometry } from "atoms/IconAccessBiometry/IconAccessBiometry";
import { ExchangeRatesScreen } from "organisms/Other/components/ExchangeRatesScreen";
import { IconErrorBiometry } from "atoms/IconErrorBiometry/IconErrorBiometry";
import { ActivityMonitor } from "components/ActivityMonitor/ActivityMonitor";
import { Grid } from "components/Grid/Grid";
import { GridItem } from "components/Grid/GridItem";
import { MainSlider } from "components/MainSlider/MainSlider";
import { AccountsAndCards } from "widgets/AccountsAndCards";
import { ChatWidget } from "widgets/ChatWidget/ChatWidget";
import { NotificationNewsModal } from "pages/profile/components/NotificationNewsModal/NotificationNewsModal";
import {
  NOTIFICATION_TABS,
  ProfileModalType as ProfileModalEnum,
} from "pages/profile/models";
import styles from "./styles.module.css";
import { ERROR_MESSAGE_DATETO_INTERVAL } from "./Cards/History/components/SearchFilters/components/OperationDate/OperationDate";

// PRIKOL НЕ УБИРАТЬ, СЛОМАЕТСЯ КАЛЕНДАРЬ В ИСТОРИИ ОПЕРАЦИЙ
const PRIKOL = ERROR_MESSAGE_DATETO_INTERVAL;

export const Home = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const {
    transfer: { errorMessage },
    virtualCard: { isOpenVirtualCard, step },
    notifications: { selected },
    profile: { isModalOpened, user, isBiometryConnected },
    system: { activeTab, chatIsOpen, isNewAndNotificationsOpen },
    cards: {
      isCardInfoOpen,
      chosenCardIndex,
      allCards,
      errorMessage: cardsErrMsg,
      screen,
      account: { description },
    },
  } = useSelector((state) => state);

  const pushType = localStorage.getItem("pushType");
  const isInitialStep = step === "initial";
  const card = allCards[chosenCardIndex || 0];
  const lastFourNumber = card?.cardPan?.slice(-4);
  const postFix = (
    <span style={{ color: "var(--main-color-notification-description)" }}>
      ● {lastFourNumber}
    </span>
  );

  const showTransferError =
    activeTab === MobileTabVariants.transfersHub &&
    errorMessage.title.length &&
    errorMessage.code !== TRANSFER_ERROR_CODE.CARD &&
    errorMessage.code !== TRANSFER_ERROR_CODE.PAYMENT_ERROR &&
    errorMessage.code !== TRANSFER_ERROR_CODE.PAYMENT_RESPONSE_ERROR &&
    errorMessage.code !== TRANSFER_ERROR_CODE.MESSAGE;

  const isDesktop = useMediaQuery("(min-width:980px)");

  const displayCardsAndAccountsBlock =
    activeTab === MobileTabVariants.main ||
    (isDesktop && activeTab !== AnotherPages.promotion);

  const displayOtherBlock = activeTab === TabVariants.other;
  const displayChatBlock = activeTab === MobileTabVariants.chat;
  const displayContactsBlock = activeTab === AnotherPages.contacts;
  const displayExchangeRatesBlock = activeTab === AnotherPages.exchangeRates;
  const displayTransfersBlock = activeTab !== AnotherPages.transfers;

  const getModalTitle = () => {
    switch (screen) {
      case CARD_ACTION.LIMIT:
        return "Суточные лимиты";
      case CARD_ACTION.CHANGE_CARD_NAME:
        return "Названия карты";
      case CARD_ACTION.REFILL:
        return "Пополнение карты";
      case CARD_ACTION.ACCOUNT_INFO:
        return description;
      case CARD_ACTION.LOST:
      case CARD_ACTION.REFERRAL:
        return (
          <Typography
            sx={{
              color: "#454A3F",
              fontSize: isMobile ? "18px" : "24px",
              fontWeight: 500,
              display: "inline",
              lineHeight: isMobile ? "24px" : "normal",
            }}
          >
            Карта {postFix}
          </Typography>
        );
      default:
        return "Детали карты";
    }
  };

  const getModalSubTitle = () => {
    switch (screen) {
      case CARD_ACTION.CHANGE_CARD_NAME:
        return card?.cardPan?.substring(card?.cardPan.length - 4);

      default:
        return undefined;
    }
  };

  const modalTitle = getModalTitle();

  const handleProfileClose = useCallback(() => {
    dispatch(profileActions.setIsModalOpened(false));
    localStorage.removeItem("activeModal");
  }, [dispatch]);

  const handleCardModalClose = () => {
    if (cardsErrMsg.code === "CHANGE_CARD_STATUS_RESPONSE_ERROR_CODE") {
      const cardId = allCards[chosenCardIndex || 0].cardId;
      dispatch(setCardError({}));
      if (cardId) dispatch(getCardInfo({ cardId }));
    }

    dispatch(
      setStatement({
        data: {
          accId: "%",
          forceRefresh: false,
        },
      })
    );

    dispatch(setIsCardInfoOpen(0));
    localStorage.removeItem("activeModal");
  };

  const onToggleCardActions = () => {
    if (screen !== CARD_ACTION.INITIAL) {
      dispatch(setScreen(CARD_ACTION.INITIAL));
    } else {
      dispatch(setIsCardInfoOpen(0));
    }
  };

  const onBackNews = () => dispatch(notificationActions.clearSelected());

  const onCloseNewAndNotifications = () => {
    dispatch(systemActions.setNewAndNotificationsOpen(false));
  };

  const onCloseChat = () => {
    if (isMobile) {
      dispatch(systemActions.setBack());
    } else {
      dispatch(systemActions.setChatOpen(false));
    }
  };

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(checkNotifications());
  }, [dispatch]);

  useEffect(() => {
    const activeTab = localStorage.getItem(
      "activeTab"
    ) as AllTabVariants | null;

    if (activeTab) {
      dispatch(
        systemActions.setActiveTab({
          tab: activeTab,
        })
      );
    } else {
      dispatch(systemActions.setInitialTab({ isMobile }));
    }

    const activeModal = localStorage.getItem("activeModal") as
      | "isCardInfoOpen"
      | "setIsOpenVirtualCard"
      | "isModalOpened"
      | null;
    const cardScreen = localStorage.getItem("cardScreen") as CARD_ACTION | null;
    const profileModalType = localStorage.getItem(
      "profileModalType"
    ) as ProfileModalEnum | null;
    const notificationActiveTab = localStorage.getItem(
      "notificationActiveTab"
    ) as NOTIFICATION_TABS | null;

    if (!isMobile || !activeModal) return;

    switch (activeModal) {
      case "setIsOpenVirtualCard":
        dispatch(setIsOpenVirtualCard(true));
        break;
      case "isModalOpened":
        dispatch(profileActions.setIsModalOpened(true));
        dispatch(profileActions.setModalType(profileModalType));
        if (
          profileModalType === ProfileModalEnum.THIS_DEVICE_NOTIFICATIONS &&
          notificationActiveTab
        ) {
          dispatch(
            profileActions.setNotificationActiveTab(notificationActiveTab)
          );
        }
        break;
      case "isCardInfoOpen":
        dispatch(setIsCardInfoOpen(0));
        dispatch(setScreen(cardScreen ? cardScreen : CARD_ACTION.INITIAL));
        break;
    }
  }, [dispatch, isMobile]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(profileActions.setBiometryConnected(""));
    }, 5000);
  }, [dispatch, isBiometryConnected]);

  useEffect(() => {
    if (pushType === "MESSAGE" && activeTab !== MobileTabVariants.chat) {
      setTimeout(() => {
        dispatch(systemActions.setActiveTab({ tab: MobileTabVariants.chat }));
        localStorage.removeItem("pushType");
      }, 500);
    }
  }, [activeTab, dispatch, pushType]);

  return (
    <Grid
      main
      container
      paddings={{
        xs: displayChatBlock
          ? "40 0 0"
          : (isMobile && activeTab === AnotherPages.transfers) ||
            activeTab === AnotherPages.refill ||
            activeTab === AnotherPages.historySearch
          ? "12 16"
          : isMobile && activeTab === TabVariants.main
          ? "12 16 100"
          : "30 16 100",
        md: displayChatBlock ? "40 0 0" : "40 20 100",
        l: displayChatBlock ? "40 0 0" : "40 0 140",
      }}
    >
      {sectionTitles?.[activeTab]?.desktop &&
        sectionTitles?.[activeTab]?.mobile &&
        !!showTransferError && (
          <GridItem columns={{ xs: [1, -1] }}>
            <Box p={{ xs: "24px 0 28px 0", xl: "40px 0 40px 0" }}>
              {showTransferError ? (
                <ErrorFullScreen
                  title={errorMessage.title}
                  description={errorMessage.subtitle}
                />
              ) : (
                ""
              )}
            </Box>
          </GridItem>
        )}
      {(isBiometryConnected === "connected" ||
        isBiometryConnected === "error") && (
        <Box
          sx={{
            position: "fixed",
            top: 20,
            left: 20,
            right: 20,
            padding: 16,
            backgroundColor:
              isBiometryConnected === "connected" ? "#ECFDF3" : "#FDE7D4",
            borderRadius: 16,
            display: "flex",
            alignItems: "flex-start",
            gap: 4,
            zIndex: 1000,
            "-webkit-box-shadow": "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
            "-moz-box-shadow": "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
            boxShadow: "2px 8px 20px 0px rgba(34, 60, 80, 0.37)",
          }}
        >
          {isBiometryConnected === "connected" ? (
            <IconAccessBiometry />
          ) : (
            <IconErrorBiometry />
          )}
          <Typography
            variant="text_3"
            color="var(--main-color-text-secondary)"
            width="90%"
            fontWeight="400"
          >
            {isBiometryConnected === "connected" ? (
              <p className={styles.title} style={{ color: "#33B438" }}>
                Вход по Face и Touch ID
                <br />
                успешно подключён
              </p>
            ) : (
              <p className={styles.title} style={{ color: "#E57616" }}>
                При подключении входа по
                <br />
                Face и Touch ID возникла
                <br />
                ошибка
              </p>
            )}
          </Typography>

          <IconClose
            color={isBiometryConnected === "connected" ? "#33B438" : "#E57616"}
            onClick={() => {
              dispatch(profileActions.setBiometryConnected(""));
            }}
          />
        </Box>
      )}
      {displayOtherBlock && <Other />}
      {displayChatBlock && <ChatScreen />}
      {displayContactsBlock && <Contacts />}
      {displayExchangeRatesBlock && <ExchangeRatesScreen />}
      {!displayOtherBlock &&
        !displayChatBlock &&
        !displayContactsBlock &&
        !displayExchangeRatesBlock &&
        displayCardsAndAccountsBlock &&
        displayTransfersBlock &&
        activeTab !== AnotherPages.refill && (
          <>
            <GridItem columns={{ xs: [1, -1], l: [1, 4] }}>
              <AccountsAndCards />
            </GridItem>
          </>
        )}
      <GridItem
        columns={{
          xs: [1, -1],
          l:
            activeTab === AnotherPages.transfers ||
            activeTab === AnotherPages.refill
              ? [1, 6]
              : activeTab === AnotherPages.promotion
              ? [1, -1]
              : [5, -1],
        }}
      >
        {activeTab === TabVariants.main && <MainSlider />}
        {activeTab !== TabVariants.history && (
          <Typography
            variant="text_14"
            color="var(--main-color-text-title)"
            sx={{
              fontWeight: 500,
              fontSize: `${isMobile ? 28 : 32} !important`,
              lineHeight: "40px",

              "@media (max-width: 600px)": {
                fontSize: "24px !important",
                lineHeight: "28px",
              },
            }}
          >
            {isMobile
              ? sectionTitles?.[activeTab]?.mobile
              : sectionTitles?.[activeTab]?.desktop}
          </Typography>
        )}
        <Cards />
      </GridItem>
      <Modal
        title={modalTitle}
        subTitle={getModalSubTitle()}
        isOpen={isCardInfoOpen}
        onClose={handleCardModalClose}
        buttonVisible
        callback={onToggleCardActions}
        showTitle={false}
        isProfile={CARD_ACTION.INITIAL !== screen}
      >
        <CardModalWrapper />
      </Modal>

      <Modal
        title="Профиль"
        isOpen={isModalOpened}
        onClose={handleProfileClose}
        buttonVisible={isMobile}
        callback={handleProfileClose}
        isProfile
      >
        <Profile phoneNumber={user?.phone as string} />
      </Modal>
      <Modal
        title="Чат с оператором"
        isOpen={chatIsOpen}
        onClose={onCloseChat}
        buttonVisible={false}
        callback={onCloseChat}
        isProfile
        isCenteredTitle={false}
      >
        <ChatWidget />
      </Modal>
      <Modal
        title="Уведомления"
        isOpen={isNewAndNotificationsOpen}
        onClose={onCloseNewAndNotifications}
        buttonVisible={isMobile}
        callback={onCloseNewAndNotifications}
        isProfile
      >
        <NotificationNewsModal />
      </Modal>
      <Modal
        title=""
        isOpen={!isEmpty(selected)}
        onClose={onBackNews}
        buttonVisible
        isProfile
        callback={onBackNews}
      >
        <NewsInfo {...selected} />
      </Modal>
      <Modal
        title={isInitialStep ? "Новая карта" : ""}
        isOpen={isOpenVirtualCard}
        onClose={() => dispatch(setIsOpenVirtualCard(false))}
        callback={() => dispatch(setIsOpenVirtualCard(false))}
        buttonVisible
        isProfile={isMobile}
      >
        <VirtualCard />
      </Modal>
      <ActivityMonitor />
    </Grid>
  );
};
