import { Box, Typography } from "@mui/material";
import { IconClose } from "atoms/IconClose/IconClose";
import { ArrowLeft } from "molecules/Modal/assets/ArrowLeft";

export const ModalHeader = ({
  title,
  subTitle = "",
  isMobile,
  isProfile,
  buttonVisible = false,
  isLoginUpdated,
  isPasswordUpdated,
  onBack,
  handleLogout,
  titleMarginTop = "",
  titleStyle = {},
}) => {
  const Title = () => (
    <Box
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "350px",
        marginTop: titleMarginTop,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "5px",
      }}
    >
      {typeof title === "string" ? (
        <Typography
          sx={{
            color: "#454A3F",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: 500,
            lineHeight: isMobile ? "24px" : "normal",
          }}
          style={titleStyle}
        >
          {title}
        </Typography>
      ) : (
        title
      )}

      {subTitle && (
        <Typography
          noWrap
          sx={{
            color: "#7E7F7A",
            fontSize: isMobile ? "18px" : "24px",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          • {subTitle}
        </Typography>
      )}
    </Box>
  );

  const handleBack =
    isLoginUpdated || isPasswordUpdated ? handleLogout : onBack;

  return (
    <Box
      sx={{
        width: "100%",
        boxSizing: "border-box",
        position: "absolute",
        top: 0,
        right: 0,
        left: 0,
        zIndex: 1000,
        background: "rgba(255, 255, 255, 0.75)",
        backdropFilter: "blur(10px)",
        padding: isMobile ? "12px 16px 8px" : "0 32px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          height: isMobile ? "56px" : "92px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {/* Desktop Header */}
        {!isMobile && (
          <>
            {isProfile && buttonVisible ? (
              <div
                onClick={handleBack}
                style={{
                  paddingLeft: "0px",
                  paddingRight: "10px",
                  marginTop: "5px",
                  cursor: "pointer",
                }}
              >
                <ArrowLeft />
              </div>
            ) : (
              <div />
            )}

            <Title />

            <Box
              sx={{
                cursor: "pointer",
                width: "24px",
                height: "24px",
              }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={onBack}
            >
              <IconClose width={24} height={24} />
            </Box>
          </>
        )}

        {/* Mobile Header */}
        {isMobile && (
          <Box
            sx={{ width: "100%" }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            gap={8}
          >
            <div
              onClick={handleBack}
              style={{
                paddingLeft: "0px",
                paddingRight: "10px",
                marginTop: "5px",
                cursor: "pointer",
              }}
            >
              <ArrowLeft />
            </div>

            {/* Title */}
            <Title />

            <div />
          </Box>
        )}
      </Box>
    </Box>
  );
};
