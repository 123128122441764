import { ClickableHeader } from "components/ClickableHeader/ClickableHeader";
import { SearchInput } from "components/SearchInput/SearchInput";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "store";

import {
  getExpenseList,
  getHistoryList,
  getIncomeList,
  HISTORY_TABS,
  setHistorySearchFilters,
  appendExpenseList,
  appendHistoryList,
  appendIncomeList,
} from "store/slices/historySlice";
import { DateStyled } from "../../History";
import { Box } from "@mui/material";
import { parseTransactionsDate } from "utils/parseTransactionsDate";
import { OperationItem } from "../OperationItem/OperationItem";
import { useIntersectionLoad } from "hooks/useIntersectionLoad";
import { StatementShort } from "api/account";
import { systemActions, TabVariants } from "store/slices/system";
import { HistoryModal } from "../../HistoryModal";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { EmptyScreenCustom } from "../EmptyScreenCustom/EmptyScreenCustom";
import { Loader } from "components/Loader/Loader";
import { SkeletonContainer } from "components/SkeletonContainer/SkeletonContainer";
import { useDebounce } from "hooks/useDebounce";
import { useKeyboardVisibility } from "hooks/useKeyboardVisibility";

export const SearchOperations = () => {
  const {
    history: {
      activeTab,
      filters,
      historyList,
      incomeList,
      expenseList,
      isLoading: isApiLoading,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const [modalStatus, setModalStatus] = useState({
    isOpen: false,
    historyId: "",
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [localSearch, setLocalSearch] = useState(filters.search || "");
  const [isClear, setIsClear] = useState(false);
  const debouncedSearch = useDebounce<string>(localSearch, 1000);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const lastBlockRef = useRef<HTMLDivElement | null>(null);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isFirstRender = useRef(true);
  const isKeyboardVisible = useKeyboardVisibility(containerRef);

  let data = {
    [HISTORY_TABS.ALL_OPERATIONS]: historyList,
    [HISTORY_TABS.EXPENSES]: expenseList,
    [HISTORY_TABS.INCOMES]: incomeList,
  };

  const payload = useMemo(
    () => ({
      size: 10,
      ...filters,
      ...{
        operationFilterType: filters.operationFilterType || null,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, filters.page, filters.search]
  );

  const openModal = (id: string) =>
    setModalStatus(() => ({ historyId: id, isOpen: true }));

  const closeModal = () =>
    setModalStatus(() => ({ historyId: "", isOpen: false }));

  const handleLoadMore = () => {
    const len = data[activeTab].reduce(
      (acc, it) => (it.statements as StatementShort[]).length + acc,
      0
    );
    const isEndOfSlice = len % 10 === 0;
    const isSamePage = Math.floor(len / 10) === filters.page;

    if (!isSamePage && isEndOfSlice) {
      dispatch(
        setHistorySearchFilters({
          ...filters,
          page: Math.floor(len / 10),
        })
      );
    }
  };

  useEffect(() => {
    if (isFirstRender.current && filters.search.length) {
      isFirstRender.current = false;
    }
  }, [isLoading, filters.search.length]);

  useEffect(() => {
    if (isFirstRender.current || filters.page === 0) return;

    if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
      // @ts-ignore
      dispatch(appendHistoryList(payload));
    }
    if (activeTab === HISTORY_TABS.EXPENSES) {
      // @ts-ignore
      dispatch(appendExpenseList(payload));
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      // @ts-ignore
      dispatch(appendIncomeList(payload));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, payload]);

  // Обработка изменений дебаунсированного значения поисковой строки
  useEffect(() => {
    if (debouncedSearch !== filters.search) {
      setLoading(true);
      dispatch(
        setHistorySearchFilters({
          ...filters,
          search: debouncedSearch,
          page: 0,
        })
      );
    }
  }, [debouncedSearch, dispatch, filters]);

  useEffect(() => {
    if (isFirstRender.current) return;

    if (activeTab === HISTORY_TABS.ALL_OPERATIONS) {
      // @ts-ignore
      dispatch(getHistoryList(payload));
    }
    if (activeTab === HISTORY_TABS.EXPENSES) {
      // @ts-ignore
      dispatch(getExpenseList(payload));
    }
    if (activeTab === HISTORY_TABS.INCOMES) {
      // @ts-ignore
      dispatch(getIncomeList(payload));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, dispatch, filters.search, filters.search.length]);

  useEffect(() => {
    if (!isApiLoading) setLoading(isApiLoading);
  }, [isApiLoading]);

  useIntersectionLoad({
    targetRef: lastBlockRef,
    parentRef,
    loadMore: handleLoadMore,
    isLoading: isApiLoading,
  });

  // динамически управляет мета-тегом `viewport` в зависимости от состояния видимости клавиатуры.
  useEffect(() => {
    const metaViewport = document.querySelector('meta[name="viewport"]');
    if (!metaViewport) return;

    const baseContent =
      metaViewport.getAttribute("content") ??
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";

    const updateViewport = (add: boolean) => {
      const newContent = add
        ? `${baseContent}, interactive-widget=resizes-content`
        : baseContent.replace(", interactive-widget=resizes-content", "");

      if (metaViewport.getAttribute("content") !== newContent) {
        metaViewport.setAttribute("content", newContent);
      }
    };

    updateViewport(true);

    return () => {
      updateViewport(false);
    };
  }, []);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  return (
    <Box sx={{ height: "calc(100vh - 24px)" }} ref={containerRef}>
      <ClickableHeader
        onBack={async () => {
          dispatch(systemActions.setActiveTab({ tab: TabVariants.history }));
        }}
        title="Поиск по операциям"
      />

      <SearchInput
        value={localSearch}
        onChange={(event) => {
          if (isClear) {
            setIsClear(false);
            setLocalSearch("");
          }

          setLocalSearch((prev) => event.target.value);
        }}
        onEndAdornmentClick={() => setIsClear(true)}
        isClear={isClear}
        placeholder="Поиск по названию"
        ref={searchInputRef}
        endAdornmentIcon={<IconCircleClose />}
      />

      <SkeletonContainer
        height="calc(100vh - 160px)"
        isLoading={isLoading && !isFirstRender.current}
        width="100%"
        marginTop={20}
      >
        <Box
          ref={parentRef}
          marginTop={20}
          sx={{ height: "calc(100vh - 160px)", overflowY: "scroll" }}
        >
          {!isFirstRender.current ? (
            data[activeTab].length ? (
              <div>
                {data[activeTab].map((item) => {
                  if (!item.statements?.length) return null;
                  return (
                    <Box key={item.date} mb={8}>
                      <DateStyled>
                        {parseTransactionsDate(item.date || "")}
                      </DateStyled>
                      <Box display="flex" flexDirection="column">
                        {item.statements.map((el) => (
                          <OperationItem
                            key={el.statementId}
                            item={el}
                            onOpenModal={openModal}
                          />
                        ))}
                      </Box>
                    </Box>
                  );
                })}

                {isApiLoading && (
                  <Box
                    width="100%"
                    height={15}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Loader size={15} />
                  </Box>
                )}

                <div ref={lastBlockRef} />
              </div>
            ) : (
              <CenteredBox
                alignItems={isKeyboardVisible ? "flex-end" : "center"}
              >
                <EmptyScreenCustom />
              </CenteredBox>
            )
          ) : null}

          {modalStatus.isOpen && (
            <HistoryModal
              isOpen={modalStatus.isOpen}
              onClose={closeModal}
              historyId={modalStatus.historyId}
            />
          )}
        </Box>
      </SkeletonContainer>
    </Box>
  );
};

interface CenteredBoxProps {
  children?: React.ReactNode;
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch" | "baseline";
}

const CenteredBox: React.FC<CenteredBoxProps> = ({
  children,
  alignItems = "center",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "80%",
        alignItems: alignItems,
      }}
    >
      {children}
    </Box>
  );
};
