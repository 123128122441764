import { useEffect, useState } from "react";
import { authWebApi } from "api";
import { authActions } from "store/slices/auth";
import { useDispatch, useSelector } from "store";
import { useIsPWA } from "hooks/useIsPWA";
import { Box } from "@mui/system";
import { Loader } from "components/Loader/Loader";
import { Authorization } from "organisms/Authorization/Authorization";
import { PinEnter } from "organisms/PinCode/PinEnter";

export const SignIn = () => {
  const dispatch = useDispatch();
  const isPWA = useIsPWA();
  const [isPinChecked, setPinChecked] = useState(false);
  const { isPinAllowed, isPinForgotten, isCodeForgotten } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    const checkPin = async () => {
      try {
        if (!isPinForgotten) {
          const {
            data: { pinAllowed },
          } = await authWebApi.checkPinAllowed({
            deviceId:
              (isPWA
                ? localStorage.getItem("PWADeviceId")
                : localStorage.getItem("deviceId")) || "",
          });
          dispatch(authActions.setIsPinAllowed(pinAllowed || false));
        }
      } catch (error) {
        throw Error("[ERROR PIN]");
      } finally {
        setPinChecked(true);
      }
    };

    checkPin();
  }, [dispatch, isPinForgotten, isPWA]);

  useEffect(() => {
    dispatch(authActions.setIsPinAllowed(isPinAllowed));
  }, [dispatch, isPinAllowed]);
  const { isAuthenticated, isConfirmed } = useSelector((state) => state.auth);
  const isAuth = sessionStorage.getItem("isAuthenticated") === "true";
  const login = localStorage.getItem("login") || "";

  const isOk =
    !isPinForgotten &&
    !isAuthenticated &&
    !isConfirmed &&
    !isAuth &&
    isPinAllowed &&
    !!login.length;

  if (!isPinChecked)
    return (
      <Box
        sx={{
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </Box>
    );

  return isOk && !isCodeForgotten ? <PinEnter /> : <Authorization />;
};
