import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { chatActions, getChatMessagesV2, markRead } from "store/slices/chat";
import { useDispatch, useSelector } from "store";
import { useGetChatMessages } from "./hooks";
import { getSortedMessages } from "./utils/getSortedMessages";
import { ChatMessage, ChatMessageTypeEnum } from "../../api/notification";
import { IconArrowDown } from "atoms/IconArrowDown";
import { IconChatError } from "atoms/IconChatError";
import { OperatorRating } from "./components/OperatorRating/OperatorRating";
import { useIsMobile } from "hooks/useIsMobile";
import { IconPhone } from "atoms/IconPhone/IconPhone";
import { IconRefresh } from "atoms/IconRefresh/IconRefresh";
import { IconCircleClose } from "atoms/IconCircleClose/IconCircleClose";
import { ReplyMessage } from "./components/ReplyMessage/ReplyMessage";
import { Overlay } from "components/Overlay/Overlay";
import { ChatMessageMenu } from "./components/ChatMessage/ChatMessageMenu";
import { Loader } from "components/Loader/Loader";
import { MessageItem } from "./components/MessageItem/MessageItem";
import { ErrorNotification } from "./components/ErrorNotification/ErrorNotification";
import { Files } from "./components/Files/Files";
import { InfiniteScroll } from "./components/InfiniteScroll/InfiniteScroll";
import { NotificationUnavailable } from "./components/NotificationUnavailable/NotificationUnavailable";
import { SendMessage } from "./components/SendMessage/SendMessage";
import { ChatBlock } from "./components/ChatBlock/ChatBlock";
import { Button } from "components/Button";
import { sleep } from "utils/sleep";
import { CHAT_PAGE_SIZE } from "constants/chat";

interface ChatWidgetProps {
  isWindow?: boolean;
}

export const ChatWidget: React.FC<ChatWidgetProps> = ({ isWindow = false }) => {
  const lastBlockRef = useRef<HTMLDivElement>(null);
  const rootSentinelRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const chatRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef<number | null>(null);
  const { isMobile } = useIsMobile();

  const isDesktop = useMediaQuery("(min-width:1024px)");

  const [selectedMsg, setSelectedMsg] = useState<ChatMessage | null>(null);
  const [isReply, setIsReply] = useState<boolean>(false);

  const scrollToBottom = () => {
    //@ts-ignore
    lastBlockRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    if (scrollTimeoutRef.current !== null) {
      clearTimeout(scrollTimeoutRef.current);
    }

    if (!isScrolling) {
      setIsScrolling(true);
    }

    scrollTimeoutRef.current = window.setTimeout(() => {
      setIsScrolling(false);
    }, 100);

    const div = rootSentinelRef?.current;

    const cur = div !== null ? div?.scrollHeight - div?.scrollTop : 0;
    if (cur < 1000) {
      setIsScrolled(false);
    } else {
      setIsScrolled(true);
    }
  };

  const {
    messages,
    isLoading,
    messagesArr,
    paginationPage,
    chatMessagesState,
    files,
    unavailableError,
    isSizeError,
    isSizeErrorVisible,
    initSumMessages,
    latestPage,
    isMessageSending,
    chatError,
    showOperatorRating,
    idRatingMessage,
    newMessageCounter,
  } = useSelector((state) => state.chat);

  useGetChatMessages();

  useEffect(() => {
    if (chatMessagesState === "firstMessagesLoaded" && !isLoading) {
      sleep().then(scrollToBottom);
    }
  }, [messages, chatMessagesState, isLoading]);

  useEffect(() => {
    return () => {
      dispatch(chatActions.setChatMessageState("initial"));
      dispatch(chatActions.clearState());
    };
  }, [dispatch]);

  useEffect(() => {
    const unReaded = Object.keys(messages)
      .map((key) => messages[key])
      .flat()
      .filter((msg) => {
        if (!msg) return false;

        if (
          msg.type === ChatMessageTypeEnum.RatingRequest &&
          msg.id !== idRatingMessage
        ) {
          dispatch(chatActions.setShowOperatorRating(true));
          dispatch(chatActions.setIdRatingMessage(msg.id));
          dispatch(chatActions.setOperatorRatingTitle(msg.text));
        }
        if (!msg.read && !msg.user) {
          return msg;
        }
      })
      .map(({ id }) => id);

    if (unReaded.length > 0 && !isScrolled) {
      setTimeout(() => {
        dispatch(markRead(unReaded as [string]));
        dispatch(chatActions.setReadStatus());
      }, 3000);
    }
  }, [messages, dispatch, idRatingMessage, isScrolled]);

  const handleLoadMore = useCallback(() => {
    const scrollBefore = rootSentinelRef.current?.scrollHeight || 0;
    if (!isLoading) {
      dispatch(chatActions.setChatMessageState("scrolling"));
      if (paginationPage && paginationPage >= 1) {
        dispatch(
          getChatMessagesV2({ page: paginationPage - 1, size: CHAT_PAGE_SIZE })
        ).then(() => {
          const scrollHeight = rootSentinelRef.current?.scrollHeight || 0;
          rootSentinelRef.current?.scrollTo(0, scrollHeight - scrollBefore);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isLoading, paginationPage]);

  const handleReplyMessageClick = async (messageId: string) => {
    const initialPage = paginationPage ?? 0;
    let fetchPage = initialPage - 1;
    let attempts = 0;
    const MAX_ATTEMPTS = 50;

    const checkAndScroll = () => {
      const targetElement = rootSentinelRef.current?.querySelector(
        `[data-id="message-box-${messageId}"]`
      );

      if (targetElement) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });

        return true;
      }
      return false;
    };

    // Первая проверка
    if (checkAndScroll()) return;

    // Начинаем подгрузку сообщений
    while (fetchPage >= 0 && attempts < MAX_ATTEMPTS) {
      await dispatch(
        getChatMessagesV2({ page: fetchPage, size: CHAT_PAGE_SIZE })
      ).unwrap();

      await sleep(250);

      if (checkAndScroll()) {
        return;
      }

      fetchPage--;
      attempts++;
    }
  };

  const handleErrorClose = () => {
    dispatch(chatActions.setIsSizeErrorClosed());
    dispatch(chatActions.clearIsFileWithErrorSize());
  };

  const isInitialLoading = isLoading && chatMessagesState === "initial";
  const isSubLoading =
    initSumMessages < 20 && latestPage !== null && latestPage >= 1;

  if (isInitialLoading || isSubLoading) {
    return (
      <Box
        width="100%"
        height="calc(100% - 120px)"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Loader />
      </Box>
    );
  }

  return (
    <Box
      pt={0}
      maxWidth={816}
      width="100%"
      boxSizing="border-box"
      bgcolor={isMobile ? "white" : "transparent"}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      <Box
        ref={chatRef}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {chatError ? (
          <ChatContainer
            messages={messages}
            lastBlockRef={lastBlockRef}
            rootSentinelRef={rootSentinelRef}
            isLoading={isLoading}
            handleLoadMore={handleLoadMore}
            handleScroll={handleScroll}
            isWindow={isWindow}
            selectedMsg={selectedMsg}
            setSelectedMsg={setSelectedMsg}
            setIsReply={setIsReply}
            isReply={isReply}
            isDesktop={isDesktop}
            isScrolled={isScrolling}
            onReplyMessageClick={handleReplyMessageClick}
          />
        ) : (
          <ErrorMessage handleLoadMore={handleLoadMore} />
        )}

        {/* scroll btn */}
        {chatError && isScrolled && (
          <Box sx={{ position: "relative", display: "flex" }}>
            <Box
              onClick={scrollToBottom}
              sx={{
                cursor: "pointer",
                position: "absolute",
                backgroundColor: "transparent",
                bottom: 10,
                right: { xs: "16px", lg: "32px" },
                zIndex: selectedMsg && !isReply ? 0 : 100,
              }}
            >
              <div style={{ position: "relative" }}>
                <IconArrowDown />
                {Number(newMessageCounter) > 0 && (
                  <Box
                    sx={{
                      width: "18px",
                      height: "18px",
                      borderRadius: "50%",
                      background: "#FD9031",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      color: "white",
                      top: "-5px",
                      right: "-5px",
                      fontSize: "13px",
                      fontWeight: 400,
                      lineHeight: "14px",
                    }}
                  >
                    {Number(newMessageCounter)}
                  </Box>
                )}
              </div>
            </Box>
          </Box>
        )}

        {isReply && (
          <Box
            sx={{
              backgroundColor: "#fff",
              width: "100%",
              zIndex: 100,
              boxSizing: "border-box",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 10,
                transform: "translateY(5px)",
                boxSizing: "border-box",
                width: "100%",
              }}
              px={{
                xs: "16px",
                lg: isWindow ? "16px" : "32px",
              }}
              pl={{ xs: "44px", lg: isWindow ? "44px" : "60px" }}
            >
              <ReplyMessage
                isClientMessage={!selectedMsg?.user}
                text={selectedMsg?.text}
                maxWidth="calc(100% - 30px)"
              />
              <Box
                sx={{ display: "flex", cursor: "pointer" }}
                onClick={() => {
                  setIsReply(false);
                  setSelectedMsg(null);
                }}
              >
                <IconCircleClose width={20} />
              </Box>
            </Box>
          </Box>
        )}

        {chatError && (
          <Box
            display="flex"
            flexDirection="column"
            gap={files.length ? 10 : 0}
            sx={{
              background: "#fff",
              position: "relative",
              zIndex: 0,
            }}
            pb={{ xs: isWindow ? "16px" : isMobile ? "40px" : "25px" }}
            pt={isWindow ? 16 : 12}
            px={{
              xs: "16px",
              lg: isWindow ? "16px" : "32px",
            }}
          >
            <Box
              sx={{
                background: "#fff",
                position: "relative",
                zIndex: 1000,
              }}
            >
              <SendMessage
                lastBlockRef={lastBlockRef}
                selectedMsg={selectedMsg}
                setIsReply={setIsReply}
                setSelectedMsg={setSelectedMsg}
              />
            </Box>

            {!isMessageSending && <Files files={files} />}
          </Box>
        )}
      </Box>
      {showOperatorRating && <OperatorRating />}
      {chatError && chatRef && unavailableError && (
        <NotificationUnavailable
          title={unavailableError.title || ""}
          refElement={chatRef.current}
          description={unavailableError.subtitle || ""}
          withInfoIcon={true}
          onClose={() => dispatch(chatActions.clearUnavailableError())}
        />
      )}
      {chatError &&
        chatRef &&
        !unavailableError &&
        Object.values(messages).length <= 0 && (
          <NotificationUnavailable
            title="Начните чат с оператором"
            refElement={chatRef.current}
            description="Задайте ваш вопрос и оператор ответит вам"
          />
        )}
      {chatError && isSizeError && isSizeErrorVisible && (
        <ErrorNotification
          refElement={chatRef.current}
          description="Размер файла не должен превышать 5 Mb"
          onClose={handleErrorClose}
        />
      )}
    </Box>
  );
};

const initialPosition = { top: 0, left: 0, right: 0 };

const ChatContainer = ({
  messages,
  lastBlockRef,
  rootSentinelRef,
  isLoading,
  handleLoadMore,
  handleScroll,
  isWindow,
  selectedMsg,
  setSelectedMsg,
  setIsReply,
  isReply,
  isDesktop,
  isScrolled,
  onReplyMessageClick,
}) => {
  const filteredMessages = (messages: ChatMessage[]) => {
    return messages.filter(
      ({ text, attachments }) => text || attachments?.length
    );
  };

  const [position, setPosition] = useState({ top: 0, left: 0, right: 0 });

  useEffect(() => {
    if (!selectedMsg || isReply || !rootSentinelRef.current) return;

    const targetElement = rootSentinelRef.current.querySelector(
      `[data-id="message-box-${selectedMsg.id}"]`
    );

    if (!targetElement) return;

    const rect = targetElement.getBoundingClientRect();
    const rootSentinelRect = rootSentinelRef.current.getBoundingClientRect();

    // Если нижняя граница сообщения выходит за нижнюю границу контейнера
    if (rect.bottom > rootSentinelRect.bottom) {
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });

      // Пересчитываем координаты после плавного скролла
      setTimeout(() => {
        const updatedRect = targetElement.getBoundingClientRect();
        setPosition({
          top: updatedRect.top,
          left: updatedRect.left,
          right: updatedRect.right,
        });
      }, 200);
    } else {
      setPosition({ top: rect.top, left: rect.left, right: rect.right });
    }
  }, [selectedMsg, isReply, rootSentinelRef]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: "200px",
        position: "relative",
        zIndex: 1,
      }}
    >
      <InfiniteScroll
        lastBlockRef={lastBlockRef}
        rootSentinelRef={rootSentinelRef}
        isLoading={isLoading}
        onLoadMore={handleLoadMore}
        onScroll={handleScroll}
        overflow={selectedMsg && !isReply ? "hidden" : "auto"}
      >
        {getSortedMessages(messages).map(([date, messages]) => (
          <ChatBlock
            key={date}
            date={date}
            messages={filteredMessages(messages)}
            isWindow={isWindow}
            onMsgSelect={setSelectedMsg}
            selectedMsg={selectedMsg}
            isReply={isReply}
            isScrolled={isScrolled}
            onReplyMessageClick={onReplyMessageClick}
          />
        ))}
      </InfiniteScroll>

      {selectedMsg && !isReply && (
        <Overlay
          onClick={() => {
            setSelectedMsg(null);
            setPosition(initialPosition);
          }}
          zIndex={1}
          style={{
            top: -100,
            bottom: -100,
            position: !isDesktop ? "fixed" : "absolute",
          }}
        />
      )}

      {selectedMsg && !isReply && position.top && (
        <Box
          sx={{
            position: "fixed",
            top: position.top,
            left: position.left,
            width: position.right - position.left,
            zIndex: 100,
          }}
        >
          <MessageItem
            message={selectedMsg}
            isWindow={isWindow}
            onMsgSelect={() => {}}
            selectedMsg={selectedMsg}
          />

          <ChatMessageMenu
            message={selectedMsg}
            user={selectedMsg.user}
            onReply={() => {
              setIsReply(true);
              setPosition(initialPosition);
            }}
            maxWidth="100%"
          />
        </Box>
      )}
    </Box>
  );
};

const ErrorMessage = ({ handleLoadMore }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      padding={{
        xs: "0 16px",
        lg: "0 32px",
      }}
    >
      <IconChatError />
      <Typography
        mt={22}
        mb={8}
        sx={{
          color: "var(--main-color-notification-title)",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: 600,
          lineHeight: "24px",
        }}
      >
        Чат поддержки временно
        <br />
        недоступен
      </Typography>
      <Typography
        sx={{
          color: "var(--main-color-notification-title)",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        Уже работаем над этой проблемой
      </Typography>

      <Typography
        variant="text_5"
        color="gray.b500"
        fontWeight={400}
        display="flex"
        alignItems="center"
        sx={{ columnGap: "4px", marginTop: isMobile ? "24px" : "20px" }}
      >
        <IconPhone
          size={16}
          color="var(--main-color-text-secondary-unactive)"
        />{" "}
        Служба поддержки
      </Typography>
      <Link
        variant="text_5"
        color="#739b67"
        fontWeight="500"
        underline="none"
        href="tel:+7 (800) 200 45 67"
      >
        +7 (800) 200 45 67
      </Link>
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          right: isMobile ? 16 : 32,
          left: isMobile ? 16 : 32,
          boxSizing: "border-box",
        }}
      >
        <Button
          variant="primary"
          startIcon={<IconRefresh />}
          onClick={handleLoadMore}
          title="Обновить"
        />
      </Box>
    </Box>
  );
};
