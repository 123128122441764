import { authActions, logout } from "../store/slices/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useResetStore } from "./useResetStore";
import { useIsPWA } from "./useIsPWA";
import { useDispatch } from "store";
import { pushNotificationsHandler } from "utils/notifications";

export const useAuthCommonActions = () => {
  const resetStore = useResetStore();
  const dispatch = useDispatch();
  const nav = useNavigate();
  const isPWA = useIsPWA();

  const handleLogout = useCallback(async () => {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      await pushNotificationsHandler({
        deviceId,
        isOn: false,
        dispatch,
      });
    }

    dispatch(logout(isPWA)).finally(() => {
      dispatch(authActions.setLogout());
      localStorage.removeItem("activeTab");
      localStorage.removeItem("cardScreen");
      localStorage.removeItem("activeModal");
      localStorage.removeItem("profileModalType");
      localStorage.removeItem("notificationActiveTab");

      const deviceIdItem = localStorage.getItem("deviceId");
      const PWAdeviceIdItem = localStorage.getItem("PWADeviceId");

      localStorage.clear();
      if (deviceIdItem !== null) {
        localStorage.setItem("deviceId", deviceIdItem);
      }
      if (PWAdeviceIdItem !== null) {
        localStorage.setItem("PWADeviceId", PWAdeviceIdItem);
      }
      sessionStorage.clear();
      nav("/sign-in", { replace: true });

      resetStore();
    });

    return true;
  }, [dispatch, nav, resetStore, isPWA]);

  return { handleLogout };
};
