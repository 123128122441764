import {
  ChatAttachment,
  ChatMessage,
  ChatMessageDto,
  ChatMessageTypeEnum,
} from "api/notification";

export function convertToChatMessage(dto: ChatMessageDto): ChatMessage {
  return {
    id: dto.id!,
    chatId: dto.chatId,
    timestamp: dto.createdAt!,
    operatorName: dto.user || undefined,
    text: dto.text,
    read: dto.read!,
    attachments: (dto.attachments || []) as ChatAttachment[],
    type: dto.type as ChatMessageTypeEnum,
    replyMessage: dto.replyMessage,
    // operator и user не заполняются из Dto
  };
}
