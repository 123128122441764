import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography } from "@mui/material";
import SuccessIcon from "./assets/SuccessIcon.png";
import { authActions } from "store/slices/auth";
import styles from "./styles.module.css";
import { Button } from "components/Button";
import { useDispatch } from "store";

export const SuccessedScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    navigate("/sign-in");
    dispatch(authActions.setRegisterScreen("initial"));
  };

  return (
    <Grid
      flexGrow="1"
      display="flex"
      alignItems="center"
      container
      justifyContent="center"
      fontFamily="Geologica"
      columnSpacing={{ lg: 28 }}
      textAlign="center"
      columns={{ xs: 4, md: 8, lg: 12 }}
    >
      <Box
        p={{ xs: "80px 0 140px", md: "32px 30.5px" }}
        component="form"
        autoComplete="off"
      >
        <div className={styles.container}>
          <img src={SuccessIcon} className={styles.icon} />
          <Typography
            variant="text_8"
            color="gray.b900"
            fontWeight={600}
            whiteSpace="pre-wrap"
          >
            Аккаунт создан
          </Typography>
          <Typography
            variant="text_4"
            color="gray.b500"
            fontWeight={500}
            mb={24}
            mt={16}
          >
            {`Теперь вы можете продолжить работу\n в личном кабинете`}
          </Typography>
          <Button variant="primary" onClick={handleClick} title="Войти" />
        </div>
      </Box>
    </Grid>
  );
};
