import { useDispatch } from "store";
import { Typography } from "@mui/material";
import { profileActions } from "../../store/slices/profile";

export const AvatarAndName = () => {
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(profileActions.setIsModalOpened(true));
  };

  return (
    <Typography
      mr={8}
      fontWeight={500}
      variant="text_5"
      color="gray.b900"
      onClick={handleClick}
      sx={{ cursor: "pointer" }}
    >
      Профиль
    </Typography>
  );
};
