import React from "react";

export const IconSuccessDesktop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      viewBox="0 0 150 150"
      fill="none"
    >
      <path
        d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
        fill="#F5F5F5"
      />
      <path
        d="M133.5 142C133.5 146.418 129.918 150 125.5 150H23.5C19.0817 150 15.5 146.418 15.5 142V59.0026C15.5 54.5868 19.0786 51.006 23.4944 51.0038C61.5136 50.9851 97.1462 51.0427 125.494 51.0184C129.917 51.0146 133.5 54.5982 133.5 59.0213V142Z"
        fill="white"
      />
      <path
        d="M81.8125 106H67.1875C66.2555 106 65.5 107.343 65.5 109C65.5 110.657 66.2555 112 67.1875 112H81.8125C82.7445 112 83.5 110.657 83.5 109C83.5 107.343 82.7445 106 81.8125 106Z"
        fill="#E9E9E9"
      />
      <path
        d="M96.5 116H52.5C50.8431 116 49.5 117.343 49.5 119C49.5 120.657 50.8431 122 52.5 122H96.5C98.1569 122 99.5 120.657 99.5 119C99.5 117.343 98.1569 116 96.5 116Z"
        fill="#E9E9E9"
      />
      <path
        d="M74.5 94C87.7548 94 98.5 83.2548 98.5 70C98.5 56.7452 87.7548 46 74.5 46C61.2452 46 50.5 56.7452 50.5 70C50.5 83.2548 61.2452 94 74.5 94Z"
        fill="#739B67"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.342 62.1752C85.7976 61.7196 86.5363 61.7196 86.992 62.1752C87.4125 62.5958 87.4449 63.2575 87.089 63.7152L86.992 63.8251L72.1827 78.6344C71.7621 79.0549 71.1004 79.0873 70.6427 78.7314L70.5328 78.6344L62.0087 70.1103C61.5531 69.6547 61.5531 68.916 62.0087 68.4604C62.4293 68.0398 63.091 68.0075 63.5487 68.3633L63.6586 68.4604L71.3573 76.159L85.342 62.1752Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
};
