import React from "react";
import { Box, Icon, Typography, useTheme } from "@mui/material";
import miniCardBG from "images/miniCardBG.png";
import { CARD_STATUS } from "../../../../components/CardsInfo/model";
import { styled } from "@mui/material/styles";
import { CardDto } from "../../../../api/account";
import { createTransferToCard } from "../../../../store/slices/transferSlice";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { setFromTransferCard } from "store/slices/cardsSlice";
import { IconSnow } from "atoms/IconSnow/IconSnow";
import { useDispatch, useSelector } from "store";
import { IconBigKey } from "components/Icons/IconBigKey";

interface ChosenCardProps {
  onClose: () => void;
  setChooseCard: (val: boolean) => void;
  payloadModal: {
    cardId: string | undefined;
    toCardPan: string;
    sum: number;
    reason: string;
  };
}

export const ChosenCard: React.FC<ChosenCardProps> = (props) => {
  const { setChooseCard, payloadModal, onClose } = props;
  const {
    cards: { allCards },
  } = useSelector((state) => state);
  const theme = useTheme();
  const { red } = theme.palette;
  const dispatch = useDispatch();

  const switchPaymentCard = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    card: CardDto
  ) => {
    if (card?.status !== CARD_STATUS.Active) e.preventDefault();
    else {
      if (card) {
        dispatch(setFromTransferCard(card));
        dispatch(
          createTransferToCard({
            // @ts-ignore
            cardId: card?.cardId,
            toCardPan: payloadModal?.toCardPan,
            sum: payloadModal?.sum,
            reason: payloadModal?.reason,
          })
        )
          .unwrap()
          .then((res) => {
            if (res) {
              setChooseCard(false);
            }
          })
          .catch((err) => {
            onClose();
            return err;
          });
      }
    }
  };

  const IconWrapper = styled(Icon)({
    backgroundColor: "white",
    width: "24px",
    height: "24px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 3,
  });
  return (
    <Box display="flex" flexDirection="column" gap={19} mt={32}>
      {allCards?.map((card: CardDto) => (
        <Box
          display="flex"
          flexDirection="row"
          gap={16}
          key={card.cardPan}
          py={8}
          pl={40}
          sx={{
            "&:hover": {
              background: "var(--main-color-bg-widgets)",
              borderRadius: "16px",
              cursor: "pointer",
            },
          }}
          onClick={(e) => {
            switchPaymentCard(e, card);
          }}
        >
          {card?.status === CARD_STATUS.Active && (
            <img
              src={miniCardBG}
              style={{ width: "77px", height: "50px", alignSelf: "center" }}
              alt="карта"
            />
          )}
          {card?.status !== CARD_STATUS.Active && (
            <Box
              width="77px"
              height="50px"
              borderRadius="10px"
              bgcolor={
                card?.status === CARD_STATUS.Temporary
                  ? "#DCEFF8"
                  : "rgba(253, 162, 155, 0.68)"
              }
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <IconWrapper>
                {card?.status === CARD_STATUS.Temporary ? (
                  <IconSnow width={16} height={16} />
                ) : (
                  <IconBigKey
                    width={16}
                    height={16}
                    bgColor="white"
                    color={red.b400}
                  />
                )}
              </IconWrapper>
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            <Typography variant="text_7" color="gray.b900" fontWeight={700}>
              {card.amount ? formatCurrency(card?.amount, true) : null}
            </Typography>
            <Typography
              variant="text_3"
              color="gray.b400"
              fontWeight={400}
              style={{ wordBreak: "break-word" }}
            >
              {card?.description?.substring(0, 12) !== "************"
                ? card?.description
                : "**** **** **** " +
                  card?.cardPan?.substring(card?.cardPan.length - 4)}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};
