import { createAsyncThunk } from "@reduxjs/toolkit";
import { chatApi } from "api";

type ChatMessagePayload = { page?: number; size?: number };

export const getChatMessagesV2 = createAsyncThunk(
  "getChatMessagesV2",
  async (payload: ChatMessagePayload | undefined, { rejectWithValue }) => {
    try {
      const response = await chatApi.messageList(
        "",
        payload?.page,
        payload?.size || 20
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

type SendChatMessage = {
  text?: string;
  attachments?: File[];
  replyMessageId: string;
};

export const sendChatMessageV2 = createAsyncThunk(
  "sendChatMessageV2",
  async (
    { text, attachments, replyMessageId }: SendChatMessage,
    { rejectWithValue }
  ) => {
    try {
      const response = await chatApi.createMessageV2(
        "",
        text?.length ? text : undefined,
        "",
        replyMessageId,
        attachments
      );

      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const loadChatAttachmentV2 = createAsyncThunk(
  "loadChatAttachmentV2",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await chatApi.loadChatAttachment("", id);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const markRead = createAsyncThunk(
  "markRead",
  async (ids: [string], { rejectWithValue }) => {
    try {
      //@ts-ignore
      const response = await chatApi.markRead("", { ids: ids });
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
