export const IconCaret = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.98211 8.43834L9.537 12.3016C9.59431 12.3638 9.66539 12.4138 9.74537 12.448C9.82535 12.4823 9.91234 12.5 10.0004 12.5C10.0884 12.5 10.1754 12.4823 10.2554 12.448C10.3354 12.4138 10.4064 12.3638 10.4637 12.3016L14.0186 8.43834C14.3579 8.06959 14.0766 7.5 13.5553 7.5H6.44446C5.92311 7.5 5.64183 8.06959 5.98211 8.43834Z"
      fill={props.color || "var(--main-color-text-title)"}
      stroke={props.color || "var(--main-color-text-title)"}
    />
  </svg>
);
