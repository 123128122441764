import { ChatMessage } from "api/notification";
import { format, parseISO } from "date-fns";

export function mergeMessages(
  original: ChatMessage[],
  newMessages: ChatMessage[],
  sortOrder: "asc" | "desc" = "desc"
): ChatMessage[] {
  const existingIds = new Set(original.map((msg) => msg.id));

  const mergedMsg = [
    ...original,
    ...newMessages.filter((msg) => !existingIds.has(msg.id)),
  ];

  return mergedMsg.sort((a, b) => {
    const timeA = new Date(a.timestamp).getTime();
    const timeB = new Date(b.timestamp).getTime();
    return sortOrder === "asc" ? timeA - timeB : timeB - timeA;
  });
}

export function groupMessagesByDate(
  messages: ChatMessage[]
): Record<string, ChatMessage[]> {
  return messages.reduce((acc, message) => {
    const date = parseISO(message.timestamp);

    const dateKey = format(date, "dd.MM.yyyy");

    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(message);

    return acc;
  }, {} as Record<string, ChatMessage[]>);
}
