import { TextField, FormHelperText } from "@mui/material";
import { Label } from "molecules/Label/Label";
import { makeStyles } from "@mui/styles";
import { useIsMobile } from "hooks/useIsMobile";

const useStyles = makeStyles(() => ({
  inputRoot: {
    "& .Mui-error .MuiOutlinedInput-notchedOutline ": {
      border: "1px solid var(--error-color-illustration) !Important",
    },
    "& .MuiOutlinedInput-root": {
      color: "var(--main-color-text-title)",
      background: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
      height: (isMobile) => (isMobile ? "56px" : "64px"),
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid var(--brand-color-hover)",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "var(--error-color-illustration) !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: (isMobile) => (isMobile ? "0px 18px 0px 16px" : "0px 20px"),
      fontSize: "16px",
      lineHeight: "20px",
      fontWeight: 500,
      background: "var(--main-color-bg-widgets)",
      borderRadius: "16px",
    },
    "& .MuiOutlinedInput-input::placeholder": {
      color: "black",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0",
      color: "var(--main-color-text-secondary)",
      fontSize: "14px",
      lineHeight: "20px",
    },
    "& .Mui-focused": {
      background: "var(--brand-color-disabled)",
    },
    "& .Mui-focused input": {
      background: "var(--brand-color-disabled)",
    },
  },
  errorRoot: {
    "&.Mui-error": {
      color: "var(--error-color-icon)",
      fontSize: 12,
      lineHeight: "14px",
    },
  },
}));

export const LoginInput = ({
  name = "",
  label,
  value,
  onChange,
  placeholder = "",
  error = "",
  disabled = false,
  noMessage = false,
  isProfile = false,
  ...props
}) => {
  const { isMobile } = useIsMobile();
  const classes = useStyles(isMobile);

  return (
    <>
      <Label
        label={label}
        htmlFor={name}
        style={{
          color: isProfile ? "var(--main-color-text-title)" : undefined,
          fontSize: isProfile ? "16px" : undefined,
        }}
      />
      <TextField
        classes={{ root: classes.inputRoot }}
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        autoComplete="off"
        id={name}
        fullWidth
        disabled={disabled}
        error={Boolean(error)}
        inputProps={{
          maxLength: 16,
        }}
        {...props}
      />
      {!!error && !noMessage && (
        <FormHelperText classes={{ root: classes.errorRoot }} error>
          {error}
        </FormHelperText>
      )}
    </>
  );
};
